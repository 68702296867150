import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  dateToLocale,
  fetchBomLeveled,
  fetchBomLeveledsProtoDetailedJob,
  fetchBomLeveledsRecursive,
  fetchExtCrmPurchaseOrdersProto,
  fetchExtCrmPurchaseOrdersProtoSimple,
  fetchExtInventory,
  fetchHandoverProto,
  fetchHandoversProto,
  fetchItems,
  fetchJobsProtoSimple,
  fetchMRByJobProduct,
  fetchMaterialRequestsFiltered,
  fetchPanelCodesProtoSimple,
  fetchPrePrs,
  fetchWarehouseItemsProto,
  flattenBomLeveled,
  flattenBomLeveledToList,
  getChromaColor,
  getItemFullDescription,
  getItemFullDescriptionProto,
  getPanelCodeCompleteSerialNumberProto,
  getWarehouseItemDescription,
  intlFormat,
  makeDateString,
  searchItems,
} from "../../../helpers";
import {
  CrmPurchaseOrder,
  CrmPurchaseOrders,
  MasterJavaBaseModel,
  PpicHandover,
  PpicHandoverItem,
  PpicHandoverNotifyUser,
  PpicHandovers,
  PpicHandoverType,
  ppicHandoverTypeFromJSON,
  ppicHandoverTypeToJSON,
  PpicHandover_Status,
  PpicJob,
  PpicJobs,
  PpicMaterialRequestSimple,
  PpicMaterialRequestsSimple,
  PpicPanelCode,
  PpicPanelCodes,
  WarehouseItems,
  handoverQAStatusFromJSON,
  HandoverQAStatus,
  PpicHandoverItemPic,
  PpicHandoverMaterialInstallationItem,
  bomItemTypeFromJSON,
  BomItemType,
  PrePr,
  PrePrs,
  PpicBomLeveled,
} from "../../../masterbigsystem";
import {
  BomLeveledRecursive,
  ExtInventory,
  ExtUser,
  MaterialRequest,
} from "../../../models/model";
import { Delete } from "@material-ui/icons";
import { defaultBomLeveledRecursive } from "../../../models/modelinitials";

const HandoverDetailPage = () => {
  const ctx = useContext(AppContext);
  const { id } = useParams() as { id: string };
  const history = useHistory();
  const [panelCodesLoading, setPanelCodesLoading] = useState(false);
  const [mrLeftoverQty, setMrLeftoverQty] = useState(0.0);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [handovers, setHandovers] = useState(
    PpicHandovers.fromPartial({ handovers: [] })
  );
  const saveLoading = useRef(false);
  const [purchaseOrders, setPurchaseOrders] = useState<
    CrmPurchaseOrders | null | undefined
  >(null);
  const [materialRequests, setMaterialRequests] = useState(
    PpicMaterialRequestsSimple.fromPartial({ materialRequests: [] })
  );
  const pos = useRef(CrmPurchaseOrders.fromPartial({}));
  const posLoading = useRef(false);
  const materialInstallationItemDeleteIds = useRef(
    [] as PpicHandoverMaterialInstallationItem[]
  );
  const [ppicHandoverNotifyUserDeleteIds, setPpicHandoverNotifyUserDeleteIds] =
    useState<string[]>([]);
  const [jobs, setJobs] = useState<PpicJobs | null | undefined>(null);
  const [showItemModal, setShowItemModal] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [selectedHandoverItem, setselectedHandoverItem] =
    useState<PpicHandoverItem | null>(null);
  const bomLeveleds = useRef([] as BomLeveledRecursive[]);
  const bomLeveledsLoading = useRef(false);
  const [panelCodes, setPanelCodes] = useState<
    PpicPanelCodes | null | undefined
  >(null);
  const shortage = useRef(PpicBomLeveled.fromPartial({}));
  const [ppicHandover, setPpicHandover] = useState<PpicHandover>(
    PpicHandover.fromPartial({
      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({ uuid: v4() }),
      type: PpicHandoverType.FINISHED_GOODS,
      status: PpicHandover_Status.OUTSTANDING,
      // date: makeDateString(new Date()),
    })
  );
  const [ppicHandoverItemDeleteIds, setPpicHandoverItemDeleteIds] = useState<
    string[]
  >([]);
  const [filters, setFilters] = useState({
    job: null as PpicJob | null,
    panelCode: null as PpicPanelCode | null,
  });
  const [items, setItems] = useState<WarehouseItems>(
    WarehouseItems.fromPartial({})
  );
  const [itemsLoading, setItemsLoading] = useState(false);

  const loadingInventory = useRef(false);
  const inventory = useRef([] as ExtInventory[]);

  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };
  const fetchPOsData = async () => {
    posLoading.current = true;

    render();
    const d = await fetchExtCrmPurchaseOrdersProtoSimple({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
      // render();
    }

    posLoading.current = false;
    render();
    render();
  };

  const fetchExtInventoryData = async () => {
    try {
      loadingInventory.current = true;
      inventory.current = await fetchExtInventory({
        apiKey: ctx?.apiKey ?? "",
      });
    } catch (e) {
      console.log("[fetch inventory error]", e);
    } finally {
      loadingInventory.current = false;
    }
  };

  useEffect(() => {
    if (!ppicHandover.date) {
      setPpicHandover({
        ...ppicHandover,
        date: `${makeDateString(new Date())}T00:00:00Z`,
      });
    }

    handleInit();
  }, []);
  const handleInit = async () => {
    // fetchPrePrsData();
    handleFetchPanelCodesSimple();
    handleFetchJobsProtoSimple();
    handleFetchHandoverProtoData();
    handleFetchItemsData();
    fetchHandoversData();
    fetchMaterialRequestsData();
    fetchExtCrnPurchaseOrdersData();
    fetchExtInventoryData();
    fetchBomLeveledsRecursiveData();
    fetchPOsData();
  };

  const mrsByJobManual = useRef([] as MaterialRequest[]);

  useEffect(() => {
    if (ppicHandover.extJobId && ppicHandover.extJobId !== "0") {
      handleFetchMRsByJob();
    }
  }, [ppicHandover.extJobId]);

  const handleFetchMRsByJob = async () => {
    const d = await fetchMRByJobProduct({
      // extItemId: params.extItemId,
      jobId: ppicHandover.extJobId,
    });

    if (d) {
      mrsByJobManual.current = d;
      render();
    }
  };

  const fetchBomLeveledsRecursiveData = async () => {
    const d = await fetchBomLeveledsRecursive({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      bomLeveleds.current = d;

      render();
    }
  };

  const fetchExtCrnPurchaseOrdersData = async () => {
    setPurchaseOrders(
      await (async () => {
        const d = await fetchExtCrmPurchaseOrdersProtoSimple({
          apiKey: ctx?.apiKey ?? "",
        });

        return d;
      })()
    );
  };

  const fetchHandoversData = async () => {
    setHandovers(
      await (async () => {
        const d = await fetchHandoversProto({ apiKey: ctx?.apiKey ?? "" });

        d.handovers.reverse();

        return d;
      })()
    );
  };
  const fetchMaterialRequestsData = async () => {
    setMaterialRequests(
      await fetchMaterialRequestsFiltered({
        apiKey: ctx?.apiKey ?? "",
        hideItems: true,
      })
    );
  };

  const handleFetchItemsData = async () => {
    setItemsLoading(true);
    setItems(await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" }));
    setItemsLoading(false);
  };

  const handleFetchHandoverProtoData = async () => {
    if (!isNaN(parseInt(id))) {
      setPpicHandover(
        await fetchHandoverProto({ id: id, apiKey: ctx?.apiKey ?? "" })
      );
    }
  };

  const handleFetchPanelCodesSimple = async () => {
    setPanelCodesLoading(true);
    setPanelCodes(
      await fetchPanelCodesProtoSimple({ apiKey: ctx?.apiKey ?? "" })
    );
    setPanelCodesLoading(false);
  };
  const handleFetchJobsProtoSimple = async () => {
    setJobsLoading(true);
    setJobs(
      await fetchJobsProtoSimple({
        apiKey: ctx?.apiKey ?? "",
        withProducts: true,
        withPurchaseOrders: true,
        all: true,
      })
    );

    setJobsLoading(false);
  };

  const foundJob = jobs?.jobs.find(
    (j) => `${j.masterJavaBaseModel?.id}` === `${ppicHandover.extJobId}`
  );

  const foundPanelCodeBom = jobs?.jobs
    .flatMap((j) => j.jobBomLeveleds)
    .find(
      (jbl) =>
        `${jbl.extPanelCodeId}` ===
        `${filters.panelCode?.masterJavaBaseModel?.id}`
    );

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h5>Handover Detail</h5>
          </div>
          <div>
            {saveLoading.current ? (
              <>
                <div className="spinner-border spinner-border-sm"></div>
                <div>Loading..</div>
              </>
            ) : (
              <>
                <div className="d-flex">
                  {" "}
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={async () => {
                      try {
                        if (
                          ppicHandover.items.find(
                            (i) => !i.extPurchaseOrderId
                          ) &&
                          ppicHandoverTypeFromJSON(ppicHandover.type) ===
                            PpicHandoverType.FINISHED_GOODS &&
                          filters.job?.poStatus?.status === "PO"
                        ) {
                          alert(`All PO must be selected (Select job first).`);

                          return;
                        }

                        saveLoading.current = true;
                        render();

                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/handovers-proto-save`,
                          {
                            method: "post",
                            headers: { authorization: ctx?.apiKey ?? "" },
                            body: JSON.stringify(
                              PpicHandover.toJSON(ppicHandover)
                            ),
                          }
                        );

                        // Delete handover items
                        await Promise.all(
                          ppicHandoverItemDeleteIds.map(async (id) => {
                            try {
                              const resp = await fetch(
                                `${process.env.REACT_APP_BASE_URL}/handoveritems/${id}/empty`,
                                {
                                  method: "delete",
                                  headers: { authorization: ctx?.apiKey ?? "" },
                                }
                              );
                            } catch (e) {}
                          })
                        );

                        // Delete handover material installation items
                        await Promise.all(
                          materialInstallationItemDeleteIds.current.map(
                            async (d) => {
                              try {
                                const resp = await fetch(
                                  `${process.env.REACT_APP_BASE_URL}/handover-material-installation-items/${d.masterJavaBaseModel?.id}/empty`,
                                  {
                                    method: "delete",
                                    headers: {
                                      authorization: ctx?.apiKey ?? "",
                                    },
                                  }
                                );
                              } catch (e) {}
                            }
                          )
                        );

                        // Delete handover notify users
                        await Promise.all(
                          ppicHandoverNotifyUserDeleteIds.map(async (id) => {
                            try {
                              const resp = await fetch(
                                `${process.env.REACT_APP_BASE_URL}/handovernotifyusers/${id}/empty`,
                                {
                                  method: "delete",
                                  headers: { authorization: ctx?.apiKey ?? "" },
                                }
                              );
                            } catch (e) {}
                          })
                        );

                        history.push("/handover");
                      } catch (e) {
                      } finally {
                        saveLoading.current = false;
                        render();
                      }
                    }}
                  >
                    Save
                  </button>
                  <div
                    className="btn btn-sm btn-danger"
                    onClick={async () => {
                      try {
                        if (
                          !window.confirm("Delete HO? This cannot be undone.")
                        ) {
                          return;
                        }

                        saveLoading.current = true;
                        render();

                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/handovers-delete/${ppicHandover.masterJavaBaseModel?.id}`,
                          {
                            method: "delete",
                            headers: { authorization: ctx?.apiKey ?? "" },
                          }
                        );
                      } catch (e) {
                      } finally {
                        saveLoading.current = false;
                        render();

                        history.push("/handover");
                      }
                    }}
                  >
                    Delete
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <hr />
        <div className="d-flex">
          <div>
            <div>
              <small>Date</small>
            </div>

            <div>
              {/* {ppicHandover.date} */}
              <input
                type="date"
                defaultValue={
                  ppicHandover.date
                    ? ppicHandover.date.split("T")[0]
                    : undefined
                }
                key={`date`}
                placeholder="Date..."
                className="form-control form-control-sm"
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setPpicHandover({
                      ...ppicHandover,
                      date: `${e.target.value}T00:00:00Z`,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div>
              <small>Status</small>
              <div className="d-flex flex-grow-1">
                <div>
                  <button
                    className={`btn ${
                      ppicHandover.status === PpicHandover_Status.OUTSTANDING
                        ? `btn-secondary`
                        : `btn-outline-secondary`
                    }  btn-sm`}
                    onClick={() => {
                      setPpicHandover({
                        ...ppicHandover,
                        status: PpicHandover_Status.OUTSTANDING,
                      });
                    }}
                  >
                    Outstanding
                  </button>
                </div>
                <div>
                  <button
                    className={`btn ${
                      ppicHandover.status === PpicHandover_Status.APPROVED
                        ? `btn-info`
                        : `btn-outline-info`
                    }  btn-sm`}
                    onClick={() => {
                      setPpicHandover({
                        ...ppicHandover,
                        status: PpicHandover_Status.APPROVED,
                      });
                    }}
                  >
                    Approved
                  </button>
                </div>
                <div>
                  <button
                    className={`btn ${
                      ppicHandover.status === PpicHandover_Status.CANCELLED
                        ? `btn-danger`
                        : `btn-outline-danger`
                    }  btn-sm`}
                    onClick={() => {
                      setPpicHandover({
                        ...ppicHandover,
                        status: PpicHandover_Status.CANCELLED,
                      });
                    }}
                  >
                    Cancelled
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-2">
            Type:
            <button
              onClick={() => {
                setPpicHandover({
                  ...ppicHandover,
                  type: ppicHandoverTypeToJSON(
                    PpicHandoverType.FINISHED_GOODS
                  ) as any,
                });
              }}
              className={`btn btn-sm ${
                (ppicHandover.type &&
                  ppicHandover.type ===
                    ppicHandoverTypeToJSON(ppicHandover.type) &&
                  ppicHandoverTypeFromJSON(ppicHandover.type) ===
                    PpicHandoverType.FINISHED_GOODS) ||
                !ppicHandover.type
                  ? `btn-primary`
                  : `btn-outline-primary`
              }`}
            >
              {ppicHandoverTypeToJSON(PpicHandoverType.FINISHED_GOODS)}
            </button>
            <button
              onClick={() => {
                setPpicHandover({
                  ...ppicHandover,
                  type: ppicHandoverTypeToJSON(
                    PpicHandoverType.MR_LEFTOVERS
                  ) as any,
                });
              }}
              className={`btn btn-sm ${
                ppicHandover.type &&
                ppicHandover.type ===
                  ppicHandoverTypeToJSON(ppicHandover.type) &&
                ppicHandoverTypeFromJSON(ppicHandover.type) ===
                  PpicHandoverType.MR_LEFTOVERS
                  ? `btn-primary`
                  : `btn-outline-primary`
              }`}
            >
              {ppicHandoverTypeToJSON(PpicHandoverType.MR_LEFTOVERS)}
            </button>
            <button
              onClick={() => {
                setPpicHandover({
                  ...ppicHandover,
                  type: ppicHandoverTypeToJSON(
                    PpicHandoverType.MAT_INSTALLATION
                  ) as any,
                });
              }}
              className={`btn btn-sm ${
                ppicHandover.type &&
                ppicHandover.type ===
                  ppicHandoverTypeToJSON(ppicHandover.type) &&
                ppicHandoverTypeFromJSON(ppicHandover.type) ===
                  PpicHandoverType.MAT_INSTALLATION
                  ? `btn-primary`
                  : `btn-outline-primary`
              }`}
            >
              {ppicHandoverTypeToJSON(PpicHandoverType.MAT_INSTALLATION)}
            </button>
          </div>
        </div>

        <div>
          <strong>To notify:</strong>
        </div>

        <div>
          <Select
            options={ctx?.extUsers.map((u) => ({
              label: `${u.username}: ${u.name}`,
              value: u,
            }))}
            onChange={(v) => {
              const val = v as { value: ExtUser | null };

              if (
                !ppicHandover.handoverNotifyUsers.find(
                  (ux) => `${ux.extUserId}` === `${val.value?.id}`
                )
              ) {
                setPpicHandover({
                  ...ppicHandover,
                  handoverNotifyUsers: [
                    ...ppicHandover.handoverNotifyUsers,
                    PpicHandoverNotifyUser.fromPartial({
                      extUserId: val.value?.id ? `${val.value.id}` : undefined,
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                    }),
                  ],
                });
              }
            }}
          />
        </div>
        <div>
          <ol>
            {ppicHandover.handoverNotifyUsers.map((u) => {
              return (
                <>
                  <li>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setPpicHandoverNotifyUserDeleteIds([
                          ...ppicHandoverNotifyUserDeleteIds,
                          u.masterJavaBaseModel?.id ?? "",
                        ]);
                        setPpicHandover({
                          ...ppicHandover,
                          handoverNotifyUsers:
                            ppicHandover.handoverNotifyUsers.filter(
                              (ux) => `${ux.extUserId}` !== `${u.extUserId}`
                            ),
                        });
                      }}
                    >
                      <div>
                        {
                          ctx?.extUsers.find(
                            (ux) => `${ux.id}` === `${u.extUserId}`
                          )?.username
                        }
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ol>
        </div>

        <div>
          <small>Remark</small>
          <textarea
            className="form-control form-control-sm"
            placeholder="Remark..."
            defaultValue={ppicHandover.remark}
            onBlur={(e) => {
              setPpicHandover({ ...ppicHandover, remark: e.target.value });
            }}
          ></textarea>
        </div>

        {/* {ppicHandoverTypeFromJSON(ppicHandover.type) ===
        PpicHandoverType.MR_LEFTOVERS ? ( */}
        <>
          {/* <div>
            For Job
            <Select
              options={jobs?.jobs ?? []}
              placeholder="for job..."
              getOptionLabel={(j) => `${j.name}`}
              value={jobs?.jobs.find(
                (j) =>
                  `${j.masterJavaBaseModel?.id}` === `${ppicHandover.extJobId}`
              )}
              onChange={(v) => {
                const val = v as unknown as PpicJob;
                setPpicHandover({
                  ...ppicHandover,
                  extJobId: val.masterJavaBaseModel?.id,
                });
              }}
            />
          </div> */}
        </>

        {(mrsByJobManual.current.length ?? 0) > 0 &&
        ppicHandover.type ===
          ppicHandoverTypeToJSON(PpicHandoverType.MR_LEFTOVERS) ? (
          <>
            <div
              className="overflow-auto border border-dark"
              style={{ height: "25vh", resize: "vertical" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "MR#",
                    "MR Date",
                    "MFR",
                    "PN",
                    "Name",
                    "Desc",
                    "Qty",
                    "UM",
                    "Price",
                  ].map((h) => (
                    <>
                      <th
                        className="bg-dark text-light p-0 m-0"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  ))}
                </tr>
                {mrsByJobManual.current
                  .flatMap((m) =>
                    m.materialRequestItems?.map((i) => ({ mr: m, item: i }))
                  )
                  .map((d, i) => {
                    const foundItem = items.items.find(
                      (ix) => `${ix.id}` === `${d?.item?.extItemId}`
                    );
                    return (
                      <>
                        <tr>
                          <td className="border border-dark p-0 m-0">
                            {i + 1}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            MR/PPIC/
                            {() => {
                              try {
                                return new Date(
                                  d?.mr.createdAt ?? ""
                                ).getFullYear();
                              } catch (e) {
                                return "";
                              }
                            }}
                            /{d?.mr.id}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {intlFormat({
                              date: d?.mr.neededDate,
                              dateStyle: "medium",
                            })}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.mfr}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partNum}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partName}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partDesc}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {d?.item.qty}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.defaultUm}
                          </td>
                          <td
                            className={`border border-dark p-0 m-0 ${
                              d?.item.snapshotPriceIdr &&
                              d?.item.snapshotPriceIdr !== 0
                                ? `bg-success`
                                : `bg-danger`
                            }`}
                          ></td>
                        </tr>

                        {/* <td className="border border-dark p-0 m-0">{i + 1}</td> */}
                      </>
                    );
                  })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        <div>
          Handover by: ({ppicHandover.handoverByHrId} {ppicHandover.handoverBy})
          <Select
            options={ctx?.extUsers ?? []}
            placeholder="Handover by..."
            getOptionLabel={(u) =>
              `${u.username}: ${u.name}: ${u.departmentName}`
            }
            onChange={(v) => {
              const val = v as unknown as ExtUser;
              setPpicHandover({
                ...ppicHandover,
                handoverBy: `${val.username}: ${val.name}` ?? "",
                handoverByHrId:
                  val.hrId && val.hrId !== 0 ? `${val.hrId}` : undefined,
              });
            }}
          />
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        {/* <div>
          <div className="d-flex mx-2" style={{ cursor: "pointer" }}>
            <div>Is material installation?</div>

            <input
              // className="form-control form-control-sm"
              type="checkbox"
              checked={ppicHandover.isMaterialInstallation ?? false}
              onClick={() => {
                setPpicHandover({
                  ...ppicHandover,
                  isMaterialInstallation: !ppicHandover.isMaterialInstallation,
                });
              }}
            />
          </div>

          {ppicHandover.isMaterialInstallation ? (
            <>
              <Select
                placeholder="BOM..."
                // options={bomLeveleds.current}
                options={bomLeveleds.current.map((b) => ({
                  label: `${b.bomLeveled?.name}`,
                  value: b,
                }))}
                onChange={async (v) => {
                  const val = v as { value: BomLeveledRecursive };

                  ppicHandover.extHandoverBomId = val.value.bomLeveled?.id
                    ? `${val.value.bomLeveled?.id}`
                    : undefined;

                  //  Fetch bom leveled full
                  const b = await fetchBomLeveled({
                    apiKey: ctx?.apiKey ?? "",
                    id: val.value.bomLeveled?.id,
                  });

                  if (b) {
                    const flattenedBomLeveled = flattenBomLeveledToList(b);

                    setPpicHandover({
                      ...ppicHandover,
                      extHandoverBomId: val.value.bomLeveled?.id
                        ? `${val.value.bomLeveled?.id}`
                        : undefined,
                      materialInstallationItems:
                        flattenedBomLeveled
                          .filter(
                            (c) =>
                              c.bom?.bomLeveled?.extItemId &&
                              c.bom.bomLeveled.materialType &&
                              bomItemTypeFromJSON(
                                c.bom.bomLeveled.materialType
                              ) === BomItemType.BOM_MAT_INST
                          )
                          .map((c) => {
                            return PpicHandoverMaterialInstallationItem.fromPartial(
                              {
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                                extItemId: c?.bom?.bomLeveled?.extItemId
                                  ? `${c?.bom?.bomLeveled?.extItemId}`
                                  : undefined,
                              }
                            );
                          }) ?? [],
                    });
                  }

                  render();
                }}
              />
            </>
          ) : (
            <></>
          )}

          {ppicHandover.extHandoverBomId ? (
            <>
              <div
                className="overflow-auto border border-dark"
                style={{ height: "45vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {[
                      "Action",
                      "#",
                      "MFR",
                      "P/N",
                      "Part Name",
                      "Part Desc",
                      "Qty",
                    ].map((h) => (
                      <>
                        <th
                          className="bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      </>
                    ))}
                  </tr>
                  {ppicHandover.materialInstallationItems.map((i, i_) => {
                    const foundItem = items.items.find(
                      (ix) => `${ix.id}` === `${i.extItemId}`
                    );
                    return (
                      <tr>
                        <td className="border border-dark">
                          <button
                            className="btn btn-sm btn-danger px-1 py-0"
                            onClick={() => {
                              materialInstallationItemDeleteIds.current.push(i);

                              setPpicHandover({
                                ...ppicHandover,
                                materialInstallationItems:
                                  ppicHandover.materialInstallationItems.filter(
                                    (ix) =>
                                      ix.masterJavaBaseModel?.uuid !==
                                      i.masterJavaBaseModel?.uuid
                                  ),
                              });

                              render();
                            }}
                          >
                            <Delete />
                          </button>
                        </td>
                        <td className="border border-dark">{i_ + 1}</td>
                        <td className="border border-dark">{foundItem?.mfr}</td>
                        <td className="border border-dark">
                          {foundItem?.partNum}
                        </td>
                        <td className="border border-dark">
                          {foundItem?.partName}
                        </td>

                        <td className="border border-dark">
                          {foundItem?.partDesc}
                        </td>
                        <td className="border border-dark">{i.qty}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </>
          ) : (
            <></>
          )}
        </div> */}
        {/* <div
          className={`${
            ppicHandover.extPurchaseOrderId ? `text-success` : `text-danger`
          }`}
        >
          Selected PO:{" "}
          {(() => {
            const foundPO = pos.current.purchaseOrders.find(
              (p) => `${p.id}` === `${ppicHandover.extPurchaseOrderId}`
            );

            return foundPO
              ? `${foundPO?.purchaseOrderNumber} (${foundPO?.account?.name})`
              : `None`;
          })()}
        </div> */}

        {/* <div>
          <hr className="border border-dark" />
        </div> */}

        {true ? (
          <>
            {jobsLoading ? (
              <>
                <Spinner animation="border" />
              </>
            ) : (
              <>
                {" "}
                <div>
                  <small>Job</small>
                </div>
                <div>
                  <Select
                    placeholder="Job..."
                    options={jobs?.jobs.filter((j) => !j.closingDate)}
                    getOptionLabel={(j) => `${j.name}`}
                    onChange={(v) => {
                      const val = v as unknown as PpicJob;
                      setFilters({ ...filters, job: val });
                      setPpicHandover({
                        ...ppicHandover,
                        extJobId: val.masterJavaBaseModel?.id,
                      });
                    }}
                  />
                </div>
              </>
            )}

            {filters.job ? (
              <>
                {" "}
                <div>
                  <small>Panel Code</small>
                </div>
                <div>
                  <Select
                    placeholder="Product..."
                    options={jobs?.jobs
                      .map((j) => j.panelCodes)
                      ?.flat()
                      .filter(
                        (pc) =>
                          `${pc.jobId}` ===
                          `${filters.job?.masterJavaBaseModel?.id}`
                      )}
                    getOptionLabel={(pc) =>
                      `${pc.type}: ${pc.name}: ${pc.qty ?? 0} unit(s)`
                    }
                    onChange={async (v) => {
                      const val = v as unknown as PpicPanelCode;
                      setFilters({
                        ...filters,
                        panelCode: val,
                      });

                      // If finished goods, show shortage
                      if (
                        ppicHandover.type ===
                        ppicHandoverTypeToJSON(PpicHandoverType.FINISHED_GOODS)
                      ) {
                        const d = await fetchBomLeveledsProtoDetailedJob({
                          includedPanelCodes: [val.masterJavaBaseModel?.id],
                          includePrice: false,
                          jobId: filters.job?.masterJavaBaseModel?.id,
                          types: [
                            BomItemType.BOM_ELEC,
                            BomItemType.BOM_MECH,
                            BomItemType.BOM_MAT_INST,
                          ],
                        });

                        if (d) {
                          shortage.current = d;
                          render();
                        }
                      }

                      if (
                        // ppicHandover.type !==
                        // ppicHandoverTypeToJSON(
                        //   PpicHandoverType.MAT_INSTALLATION
                        // )

                        true
                      ) {
                        render();
                        return;
                      }

                      // setPpicHandover({
                      //   ...ppicHandover,
                      //   materialInstallationItemId: val.extItemId,
                      // });

                      const foundJobBomLeveleds =
                        filters.job?.jobBomLeveleds.filter(
                          (j) =>
                            `${j.extPanelCodeId}` ===
                            `${val.masterJavaBaseModel?.id}`
                        );

                      console.log("job", filters.job);

                      const b = await fetchBomLeveled({
                        apiKey: ctx?.apiKey ?? "",
                        id: foundJobBomLeveleds?.[0]?.bomLeveledId,
                      });

                      if (b !== null) {
                        const flattenedBomLeveled = flattenBomLeveledToList(
                          b ?? { ...defaultBomLeveledRecursive }
                        );

                        console.log(
                          "flattenened bom leveled",
                          flattenedBomLeveled
                        );

                        console.log("mat inst", val);

                        setPpicHandover({
                          ...ppicHandover,
                          // extHandoverBomId: val.value.bomLeveled?.id
                          //   ? `${val.value.bomLeveled?.id}`
                          //   : undefined,
                          materialInstallationItemId:
                            val.extMaterialInstallationItemId,

                          items:
                            flattenedBomLeveled
                              .filter(
                                (c) =>
                                  c.bom?.bomLeveled?.extItemId &&
                                  c.bom.bomLeveled.materialType &&
                                  bomItemTypeFromJSON(
                                    c.bom.bomLeveled.materialType
                                  ) === BomItemType.BOM_MAT_INST &&
                                  // Not root PN
                                  `${c.bom?.bomLeveled?.extItemId}` !==
                                    `${val.extMaterialInstallationItemId}`
                              )
                              .map((c) => {
                                return PpicHandoverItem.fromPartial({
                                  masterJavaBaseModel:
                                    MasterJavaBaseModel.fromPartial({
                                      uuid: v4(),
                                    }),
                                  productId: c?.bom?.bomLeveled?.extItemId
                                    ? `${c?.bom?.bomLeveled?.extItemId}`
                                    : undefined,
                                  //   productId: i.id,
                                  qty: c?.bom?.bomLeveled?.qty ?? 0,
                                  // extItemId: c?.bom?.bomLeveled?.extItemId
                                  //   ? `${c?.bom?.bomLeveled?.extItemId}`
                                  //   : undefined,
                                });
                              }) ?? [],
                        });
                      }

                      render();

                      // alert(
                      //   `job bom levled: ${foundJobBomLeveleds?.map(
                      //     (j) => j.bomLeveledId
                      //   )}`
                      // );
                      // if (
                      //   !ppicHandover.items.find(
                      //     (i) =>
                      //       `${i.panelCodeId}` ===
                      //       `${val.masterJavaBaseModel?.id}`
                      //   )
                      // ) {
                      //   setPpicHandover({
                      //     ...ppicHandover,
                      //     items: [
                      //       ...ppicHandover.items,
                      //       PpicHandoverItem.fromPartial({
                      //         panelCodeId: val.masterJavaBaseModel?.id,
                      //         masterJavaBaseModel:
                      //           MasterJavaBaseModel.fromPartial({
                      //             uuid: v4(),
                      //           }),
                      //       }),
                      //     ],
                      //   });
                      // }
                    }}
                  />
                </div>
                <div>
                  <strong>PO</strong>
                </div>
                {/* {filters.job.jobPurchaseOrders.length} */}
                {/* <div>
                  <Select
                    placeholder="PO..."
                    options={pos.current.purchaseOrders
                      .filter((p) => {
                        return filters.job?.jobPurchaseOrders.find(
                          (px) => `${px.extPurchaseOrderId}` === `${p.id}`
                        );
                      })
                      .map((d) => ({
                        label: `${d.purchaseOrderNumber} (${d.account?.name})`,
                        value: d,
                      }))}
                    getOptionLabel={(p) =>
                      `${p.value.purchaseOrderNumber} (${p.value.account?.name})`
                    }
                    onChange={(v) => {
                      const val = v as { value: CrmPurchaseOrder };

                      ppicHandover.extPurchaseOrderId = val.value.id;
                      render();
                    }}
                  />
                </div> */}
              </>
            ) : (
              <></>
            )}

            {/* {JSON.stringify(filters.job)}
            {JSON.stringify(filters.panelCode)} */}

            {filters.panelCode ? (
              <>
                {foundPanelCodeBom ? (
                  <>BOM : {foundPanelCodeBom.masterJavaBaseModel?.id}</>
                ) : (
                  <>
                    <strong className="text-danger">No BOM found.</strong>
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            {ppicHandover.type ===
            ppicHandoverTypeToJSON(PpicHandoverType.FINISHED_GOODS) ? (
              <>
                {" "}
                {filters.panelCode ? (
                  <>
                    <div>
                      <small>Serial Numbers</small>
                    </div>

                    <div>
                      <small>
                        <strong>Auto SN</strong>
                      </small>
                    </div>
                    {filters.panelCode.serialNumbers.map((sn, i) => {
                      const handoverExistsCond =
                        handovers.handovers
                          .filter((h) => !h.cancellationDate)
                          .flatMap((h) =>
                            h.items.map((i) => ({ handover: h, item: i }))
                          )
                          .find(
                            (h) =>
                              `${h.item.panelCodeSerialNumberId}` ===
                              `${sn.masterJavaBaseModel?.id}`
                          ) ||
                        ppicHandover.items.find(
                          (i) =>
                            `${i.panelCodeSerialNumberId}` ===
                            `${sn.masterJavaBaseModel?.id}`
                        );
                      return (
                        <>
                          <div
                            className={`font-weight-bold ${
                              handoverExistsCond
                                ? `text-success`
                                : `text-danger`
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // alert(
                              //   getPanelCodeCompleteSerialNumberProto(
                              //     filters.panelCode,
                              //     sn
                              //   )
                              // );
                              if (!foundPanelCodeBom) {
                                alert("BOM not found. Please link first.");
                                return;
                              }

                              if (!handoverExistsCond) {
                                setPpicHandover({
                                  ...ppicHandover,
                                  items: [
                                    ...ppicHandover.items,
                                    PpicHandoverItem.fromPartial({
                                      masterJavaBaseModel:
                                        MasterJavaBaseModel.fromPartial({
                                          uuid: v4(),
                                        }),
                                      qty: 1,
                                      panelCodeSerialNumberId:
                                        sn.masterJavaBaseModel?.id,
                                    }),
                                  ],
                                });
                              }
                            }}
                          >
                            {i + 1}#{" "}
                            {getPanelCodeCompleteSerialNumberProto(
                              filters.panelCode,
                              sn
                            )}
                          </div>
                        </>
                      );
                    })}

                    <div>
                      <small>
                        <strong>Manual SN</strong>
                      </small>
                    </div>
                    {filters.panelCode.serialNumberManuals.map((sn, i) => {
                      const handoverExistsCond =
                        handovers.handovers
                          .filter((h) => !h.cancellationDate)
                          .flatMap((h) =>
                            h.items.map((i) => ({ handover: h, item: i }))
                          )
                          .find(
                            (h) =>
                              `${h.item.panelCodeSerialNumberManualId}` ===
                              `${sn.masterJavaBaseModel?.id}`
                          ) ||
                        ppicHandover.items.find(
                          (i) =>
                            `${i.panelCodeSerialNumberManualId}` ===
                            `${sn.masterJavaBaseModel?.id}`
                        );
                      return (
                        <>
                          <div
                            className={`font-weight-bold ${
                              handoverExistsCond
                                ? `text-success`
                                : `text-danger`
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // alert(
                              //   getPanelCodeCompleteSerialNumberProto(
                              //     filters.panelCode,
                              //     sn
                              //   )
                              // );
                              if (!foundPanelCodeBom) {
                                alert("BOM not found. Please link first.");
                                return;
                              }

                              if (!handoverExistsCond) {
                                setPpicHandover({
                                  ...ppicHandover,
                                  items: [
                                    ...ppicHandover.items,
                                    PpicHandoverItem.fromPartial({
                                      masterJavaBaseModel:
                                        MasterJavaBaseModel.fromPartial({
                                          uuid: v4(),
                                        }),
                                      qty: 1,
                                      panelCodeSerialNumberManualId:
                                        sn.masterJavaBaseModel?.id,
                                    }),
                                  ],
                                });
                              }
                            }}
                          >
                            {i + 1}# {sn.serialNumber}
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                <hr />
                {filters.job ? (
                  <>
                    <div>
                      <small>
                        <strong>Autofill PO</strong>
                      </small>
                      <ol>
                        {filters.job.jobPurchaseOrders.map((p) => {
                          const foundPO = purchaseOrders?.purchaseOrders.find(
                            (px) => `${px.id}` === `${p.extPurchaseOrderId}`
                          );
                          return (
                            <>
                              <li>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((i) => ({
                                        ...i,
                                        extPurchaseOrderId:
                                          p.extPurchaseOrderId,
                                      })),
                                    });
                                  }}
                                >
                                  <div>
                                    {foundPO?.purchaseOrderNumber} (
                                    {foundPO?.account?.name})
                                  </div>
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ol>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {ppicHandoverTypeFromJSON(ppicHandover.type) ===
                  PpicHandoverType.FINISHED_GOODS && filters.panelCode ? (
                  <>
                    <small>
                      <strong>MR Shortage</strong>
                    </small>
                    <div>
                      <div
                        className="my-2 border border-dark overflow-auto"
                        style={{ resize: "vertical", height: "20vh" }}
                      >
                        <table
                          className="table table-sm"
                          style={{ borderCollapse: "separate" }}
                        >
                          <tr>
                            {[
                              "#",
                              "MFR",
                              "PN",
                              "Part Name",
                              "Part Desc",
                              "In MR",
                              "Qty",
                              "UM",
                            ].map((h) => {
                              return (
                                <>
                                  <th
                                    className="bg-dark text-light"
                                    style={{ position: "sticky", top: 0 }}
                                  >
                                    {h}
                                  </th>
                                </>
                              );
                            })}
                          </tr>
                          {shortage.current.children
                            .filter((i) => (i.inMr ?? 0) < (i.qty ?? 0))
                            .map((i, i_) => {
                              const foundItem = items.items.find(
                                (ix) => `${ix.id}` === `${i.extItemId}`
                              );
                              return (
                                <>
                                  <tr>
                                    <td className="border border-dark">
                                      {i_ + 1}
                                    </td>
                                    <td className="border border-dark">
                                      {foundItem?.mfr}
                                    </td>
                                    <td className="border border-dark">
                                      {foundItem?.partNum}
                                    </td>
                                    <td className="border border-dark">
                                      {foundItem?.partName}
                                    </td>
                                    <td className="border border-dark">
                                      {foundItem?.partDesc}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: getChromaColor(
                                          (i.inMr ?? 0) / (i.qty ?? 1)
                                        ),
                                      }}
                                      className="border border-dark"
                                    >
                                      {i.inMr}
                                    </td>
                                    <td className="border border-dark">
                                      {i.qty}
                                    </td>
                                    <td className="border border-dark">
                                      {foundItem?.defaultUm}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <small>
                  <strong>
                    HO Items{" "}
                    {filters.job?.poStatus?.status
                      ? `(Job PO status: ${filters.job?.poStatus?.status})`
                      : ``}
                  </strong>
                </small>
                <div
                  className="overflow-auto border border-dark shadow shadow-md"
                  style={{ height: "65vh", resize: "vertical" }}
                >
                  <table
                    className="table table-sm"
                    style={{ borderCollapse: "separate" }}
                  >
                    {[
                      "Delete",
                      "#",
                      "Job",
                      "Panel Code",
                      "PN",
                      "SN",
                      "PO",
                      "PIC",
                      "Prod Remark",
                      "QA Recv",
                      "QA Send",
                      "QA By",
                      "QA Remark",
                      "QA Status",
                      "WH Recv",
                      "WH Send",
                      "WH By",
                      "WH Remark",
                      "WH Status",
                    ].map((h) => (
                      <>
                        <th
                          className="bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      </>
                    ))}
                    {ppicHandover.items.map((h, i) => {
                      const job = jobs?.jobs.find((j) =>
                        j.panelCodes.find(
                          (pc) =>
                            pc.serialNumbers.find(
                              (sn) =>
                                `${sn.masterJavaBaseModel?.id}` ===
                                `${h.panelCodeSerialNumberId}`
                            ) ||
                            pc.serialNumberManuals.find(
                              (sn) =>
                                `${sn.masterJavaBaseModel?.id}` ===
                                `${h.panelCodeSerialNumberManualId}`
                            )
                        )
                      );

                      const pc = jobs?.jobs
                        .map((j) => j.panelCodes)
                        .flat()
                        .find(
                          (pc) =>
                            pc.serialNumbers.find(
                              (sn) =>
                                `${sn.masterJavaBaseModel?.id}` ===
                                `${h.panelCodeSerialNumberId}`
                            ) ||
                            pc.serialNumberManuals.find(
                              (sn) =>
                                `${sn.masterJavaBaseModel?.id}` ===
                                `${h.panelCodeSerialNumberManualId}`
                            )
                        );

                      const sn = jobs?.jobs
                        .map((j) => j.panelCodes)
                        .flat()
                        .map((pc) => pc.serialNumbers)
                        .flat()
                        .find(
                          (sn) =>
                            `${sn.masterJavaBaseModel?.id}` ===
                            `${h.panelCodeSerialNumberId}`
                        );

                      const snManual = jobs?.jobs
                        .map((j) => j.panelCodes)
                        .flat()
                        .map((pc) => pc.serialNumberManuals)
                        .flat()
                        .find(
                          (sn) =>
                            `${sn.masterJavaBaseModel?.id}` ===
                            `${h.panelCodeSerialNumberManualId}`
                        );

                      return (
                        <>
                          <tr>
                            <td className="border border-dark">
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  setPpicHandoverItemDeleteIds([
                                    ...ppicHandoverItemDeleteIds,
                                    h.masterJavaBaseModel?.id ?? "",
                                  ]);
                                  setPpicHandover({
                                    ...ppicHandover,
                                    items: ppicHandover.items.filter(
                                      (_, ix) => ix !== i
                                    ),
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </td>
                            <td className="border border-dark">{i + 1}</td>
                            <td className="border border-dark">
                              {job?.name ?? "Not found"}
                            </td>
                            <td className="border border-dark">
                              {pc?.type}: {pc?.name}: {pc?.qty}
                            </td>
                            {(() => {
                              const fItem = items.items.find(
                                (i) => `${i.id}` === `${pc?.extItemId}`
                              );

                              return (
                                <>
                                  <td
                                    className={`border border-dark font-weight-bold text-light ${
                                      fItem ? `bg-success` : `bg-danger`
                                    }`}
                                  >
                                    {fItem ? (
                                      <>
                                        {pc?.extItemId &&
                                        pc?.extItemId !== "" &&
                                        pc?.extItemId !== "0" ? (
                                          <>
                                            {getItemFullDescriptionProto(fItem)}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <>Item not found: {pc?.extItemId}</>
                                    )}
                                  </td>
                                </>
                              );
                            })()}

                            <td className="border border-dark">
                              {(() => {
                                if (
                                  h.panelCodeSerialNumberId &&
                                  h.panelCodeSerialNumberId !== "0" &&
                                  h.panelCodeSerialNumberId !== ""
                                ) {
                                  return `Auto: ${getPanelCodeCompleteSerialNumberProto(
                                    pc,
                                    sn
                                  )}`;
                                } else if (
                                  h.panelCodeSerialNumberManualId &&
                                  h.panelCodeSerialNumberManualId !== "0" &&
                                  h.panelCodeSerialNumberManualId !== ""
                                ) {
                                  return `Manual: ${
                                    snManual?.serialNumber ?? ""
                                  }`;
                                } else {
                                  return `SN Invalid`;
                                }
                              })()}
                            </td>
                            <td
                              className={`border border-dark ${
                                h.extPurchaseOrderId
                                  ? `bg-success`
                                  : `bg-warning`
                              }`}
                            >
                              <div style={{ width: 200 }}>
                                <Select
                                  placeholder="PO..."
                                  options={pos.current.purchaseOrders
                                    .filter((p) => {
                                      return filters.job?.jobPurchaseOrders.find(
                                        (px) =>
                                          `${px.extPurchaseOrderId}` ===
                                          `${p.id}`
                                      );
                                    })
                                    .map((d) => ({
                                      label: `${d.purchaseOrderNumber} (${d.account?.name})`,
                                      value: d,
                                    }))}
                                  getOptionLabel={(p) =>
                                    `${p.value.purchaseOrderNumber} (${p.value.account?.name})`
                                  }
                                  onChange={(v) => {
                                    const val = v as {
                                      value: CrmPurchaseOrder;
                                    };

                                    h.extPurchaseOrderId = val.value.id;
                                    render();
                                  }}
                                />
                              </div>
                              <div className="text-light">
                                {(() => {
                                  const foundPO =
                                    pos.current.purchaseOrders.find(
                                      (p) =>
                                        `${p.id}` === `${h.extPurchaseOrderId}`
                                    );

                                  return `${foundPO?.purchaseOrderNumber} (${foundPO?.account?.name})`;
                                })()}
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <Select
                                  placeholder="User..."
                                  options={ctx?.extUsers.map((u) => ({
                                    label: `${u.username}: ${u.departmentName}`,
                                    value: u,
                                  }))}
                                  onChange={(v) => {
                                    const val = v as { value: ExtUser };

                                    const newPic =
                                      PpicHandoverItemPic.fromPartial({
                                        masterJavaBaseModel:
                                          MasterJavaBaseModel.fromPartial({
                                            uuid: v4(),
                                          }),
                                        extUserId: val.value.id
                                          ? `${val.value.id}`
                                          : undefined,
                                      });

                                    const foundUser = ppicHandover.items
                                      .find((hx, ix) => ix === i)
                                      ?.pics.find(
                                        (p) =>
                                          `${p.extUserId}` === `${val.value.id}`
                                      );

                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((hx, ix) =>
                                        ix === i
                                          ? {
                                              ...hx,
                                              pics: !foundUser
                                                ? hx.pics
                                                  ? [...hx.pics, newPic]
                                                  : [newPic]
                                                : hx.pics,
                                            }
                                          : hx
                                      ),
                                    });
                                  }}
                                />
                                <div>
                                  {/* {JSON.stringify(h.pics)} */}
                                  <ol>
                                    {h.pics.map((p) => {
                                      return (
                                        <>
                                          <li>
                                            <div>
                                              {
                                                ctx?.extUsers.find(
                                                  (u) =>
                                                    `${u.id}` ===
                                                    `${p.extUserId}`
                                                )?.username
                                              }
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ol>
                                </div>
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 150 }}>
                                <textarea
                                  key={`ta-prod-remark-${i}`}
                                  className="form-control form-control-sm"
                                  defaultValue={h.productionRemark}
                                  onBlur={(e) => {
                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((hx, ix) =>
                                        ix === i
                                          ? {
                                              ...hx,
                                              productionRemark: e.target.value,
                                            }
                                          : hx
                                      ),
                                    });
                                  }}
                                  placeholder="Remark..."
                                />
                              </div>
                            </td>

                            <td className="border border-dark">
                              <input
                                className="form-control"
                                type="datetime-local"
                                defaultValue={
                                  h.qaUserCheckedTimestamp
                                    ? dateToLocale(
                                        new Date(
                                          h.qaUserCheckedTimestamp ?? new Date()
                                        )
                                      )
                                    : undefined
                                }
                                onBlur={(e) => {
                                  if (e.target.value === "") {
                                    return;
                                  }
                                  console.log(e.target.value);

                                  setPpicHandover({
                                    ...ppicHandover,
                                    items: ppicHandover.items.map((hx, ix) =>
                                      ix === i
                                        ? {
                                            ...hx,
                                            qaUserCheckedTimestamp: new Date(
                                              e.target.value
                                            ).toISOString(),
                                          }
                                        : hx
                                    ),
                                  });
                                }}
                              />
                              {/* {h.qaUserCheckedTimestamp}
                      {dateToLocale(
                        new Date(h.qaUserCheckedTimestamp ?? new Date())
                      )} */}
                            </td>
                            <td className="border border-dark">
                              <input
                                className="form-control"
                                type="datetime-local"
                                defaultValue={
                                  h.qaSendTimestamp
                                    ? dateToLocale(
                                        new Date(
                                          h.qaSendTimestamp ?? new Date()
                                        )
                                      )
                                    : undefined
                                }
                                onBlur={(e) => {
                                  if (e.target.value === "") {
                                    return;
                                  }
                                  console.log(e.target.value);

                                  setPpicHandover({
                                    ...ppicHandover,
                                    items: ppicHandover.items.map((hx, ix) =>
                                      ix === i
                                        ? {
                                            ...hx,
                                            qaSendTimestamp: new Date(
                                              e.target.value
                                            ).toISOString(),
                                          }
                                        : hx
                                    ),
                                  });
                                }}
                              />
                            </td>
                            <td className="border border-dark">
                              {" "}
                              <div style={{ width: 150 }}>
                                <Select
                                  placeholder="User..."
                                  options={ctx?.extUsers.map((u) => ({
                                    label: `${u.username}`,
                                    value: u,
                                  }))}
                                  value={ctx?.extUsers
                                    .map((u) => ({
                                      label: `${u.username}`,
                                      value: u,
                                    }))
                                    .find(
                                      (u) => `${u.value.id}` === `${h.qaUserId}`
                                    )}
                                  onChange={(v) => {
                                    const val = v as { value: ExtUser };

                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((hx, ix) =>
                                        ix === i
                                          ? {
                                              ...hx,
                                              qaUserId: val.value.id
                                                ? `${val.value.id}`
                                                : undefined,
                                            }
                                          : hx
                                      ),
                                    });
                                  }}
                                />
                              </div>
                            </td>

                            <td className="border border-dark">
                              <div style={{ width: 150 }}>
                                <textarea
                                  key={`ta-qa-remark-${i}`}
                                  className="form-control form-control-sm"
                                  defaultValue={h.qaRemark}
                                  onBlur={(e) => {
                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((hx, ix) =>
                                        ix === i
                                          ? {
                                              ...hx,
                                              qaRemark: e.target.value,
                                            }
                                          : hx
                                      ),
                                    });
                                  }}
                                  placeholder="Remark..."
                                />
                              </div>
                            </td>

                            <td className="border border-dark p-0 m-0">
                              <div>
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      setPpicHandover({
                                        ...ppicHandover,
                                        items: ppicHandover.items.map(
                                          (hx, ix) =>
                                            ix === i
                                              ? {
                                                  ...hx,
                                                  qaStatus:
                                                    HandoverQAStatus.QA_PASSED,
                                                }
                                              : hx
                                        ),
                                      });
                                    }}
                                    className={`px-1 py-0 btn btn-sm 4 ${
                                      handoverQAStatusFromJSON(h.qaStatus) ===
                                      HandoverQAStatus.QA_PASSED
                                        ? `btn-success`
                                        : `btn-outline-success`
                                    }`}
                                  >
                                    PASS
                                  </button>
                                  <button
                                    onClick={() => {
                                      setPpicHandover({
                                        ...ppicHandover,
                                        items: ppicHandover.items.map(
                                          (hx, ix) =>
                                            ix === i
                                              ? {
                                                  ...hx,
                                                  qaStatus:
                                                    HandoverQAStatus.QA_NOT_PASSED,
                                                }
                                              : hx
                                        ),
                                      });
                                    }}
                                    className={`px-1 py-0 btn btn-sm 4 ${
                                      handoverQAStatusFromJSON(h.qaStatus) ===
                                      HandoverQAStatus.QA_NOT_PASSED
                                        ? `btn-danger`
                                        : `btn-outline-danger`
                                    }`}
                                  >
                                    NOPASS
                                  </button>
                                </div>
                              </div>
                            </td>

                            <td className="border border-dark">
                              {" "}
                              <input
                                className="form-control"
                                type="datetime-local"
                                defaultValue={
                                  h.whUserCheckedTimestamp
                                    ? dateToLocale(
                                        new Date(
                                          h.whUserCheckedTimestamp ?? new Date()
                                        )
                                      )
                                    : undefined
                                }
                                onBlur={(e) => {
                                  if (e.target.value === "") {
                                    return;
                                  }
                                  console.log(e.target.value);

                                  setPpicHandover({
                                    ...ppicHandover,
                                    items: ppicHandover.items.map((hx, ix) =>
                                      ix === i
                                        ? {
                                            ...hx,
                                            whUserCheckedTimestamp: new Date(
                                              e.target.value
                                            ).toISOString(),
                                          }
                                        : hx
                                    ),
                                  });
                                }}
                              />
                            </td>
                            <td className="border border-dark">
                              {" "}
                              <input
                                className="form-control"
                                type="datetime-local"
                                defaultValue={
                                  h.whSendTimestamp
                                    ? dateToLocale(
                                        new Date(
                                          h.whSendTimestamp ?? new Date()
                                        )
                                      )
                                    : undefined
                                }
                                onBlur={(e) => {
                                  if (e.target.value === "") {
                                    return;
                                  }
                                  console.log(e.target.value);

                                  setPpicHandover({
                                    ...ppicHandover,
                                    items: ppicHandover.items.map((hx, ix) =>
                                      ix === i
                                        ? {
                                            ...hx,
                                            whSendTimestamp: new Date(
                                              e.target.value
                                            ).toISOString(),
                                          }
                                        : hx
                                    ),
                                  });
                                }}
                              />
                            </td>
                            <td className="border border-dark">
                              {" "}
                              <div style={{ width: 150 }}>
                                <Select
                                  placeholder="User..."
                                  options={ctx?.extUsers.map((u) => ({
                                    label: `${u.username}`,
                                    value: u,
                                  }))}
                                  value={ctx?.extUsers
                                    .map((u) => ({
                                      label: `${u.username}`,
                                      value: u,
                                    }))
                                    .find(
                                      (u) => `${u.value.id}` === `${h.whUserId}`
                                    )}
                                  onChange={(v) => {
                                    const val = v as { value: ExtUser };

                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((hx, ix) =>
                                        ix === i
                                          ? {
                                              ...hx,
                                              whUserId: val.value.id
                                                ? `${val.value.id}`
                                                : undefined,
                                            }
                                          : hx
                                      ),
                                    });
                                  }}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 150 }}>
                                <textarea
                                  key={`ta-wh-remark-${i}`}
                                  className="form-control form-control-sm"
                                  defaultValue={h.whRemark}
                                  onBlur={(e) => {
                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((hx, ix) =>
                                        ix === i
                                          ? {
                                              ...hx,
                                              whRemark: e.target.value,
                                            }
                                          : hx
                                      ),
                                    });
                                  }}
                                  placeholder="Remark..."
                                />
                              </div>
                            </td>
                            <td className="border border-dark p-0 m-0">
                              <div className="d-flex">
                                <button
                                  onClick={() => {
                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((hx, ix) =>
                                        ix === i
                                          ? {
                                              ...hx,
                                              whStatus:
                                                HandoverQAStatus.QA_PASSED,
                                            }
                                          : hx
                                      ),
                                    });
                                  }}
                                  className={`px-1 py-0 btn btn-sm 4 ${
                                    handoverQAStatusFromJSON(h.whStatus) ===
                                    HandoverQAStatus.QA_PASSED
                                      ? `btn-success`
                                      : `btn-outline-success`
                                  }`}
                                >
                                  PASS
                                </button>
                                <button
                                  onClick={() => {
                                    setPpicHandover({
                                      ...ppicHandover,
                                      items: ppicHandover.items.map((hx, ix) =>
                                        ix === i
                                          ? {
                                              ...hx,
                                              whStatus:
                                                HandoverQAStatus.QA_NOT_PASSED,
                                            }
                                          : hx
                                      ),
                                    });
                                  }}
                                  className={`px-1 py-0 btn btn-sm 4 ${
                                    handoverQAStatusFromJSON(h.whStatus) ===
                                    HandoverQAStatus.QA_NOT_PASSED
                                      ? `btn-danger`
                                      : `btn-outline-danger`
                                  }`}
                                >
                                  NOPASS
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {ppicHandover.type ===
          ppicHandoverTypeToJSON(PpicHandoverType.MAT_INSTALLATION) &&
        ppicHandover.materialInstallationItemId ? (
          <>
            <div>
              <hr className="border border-dark" />
            </div>
            <div>
              Item:{" "}
              {(() => {
                const foundItem = items.items.find(
                  (i) =>
                    `${i.id}` === `${ppicHandover.materialInstallationItemId}`
                );

                return getWarehouseItemDescription(foundItem);
              })()}
            </div>
            <div>
              <hr className="border border-dark" />
            </div>
          </>
        ) : (
          <></>
        )}

        {ppicHandover.type ===
          ppicHandoverTypeToJSON(PpicHandoverType.MR_LEFTOVERS) ||
        ppicHandover.type ===
          ppicHandoverTypeToJSON(PpicHandoverType.MAT_INSTALLATION) ? (
          <>
            {ppicHandover.type ===
              ppicHandoverTypeToJSON(PpicHandoverType.MR_LEFTOVERS) ||
            ppicHandover.type ===
              ppicHandoverTypeToJSON(PpicHandoverType.MAT_INSTALLATION) ? (
              <>
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setPpicHandover({
                        ...ppicHandover,
                        items: ppicHandover.items
                          ? [
                              ...ppicHandover.items,
                              PpicHandoverItem.fromPartial({
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                              }),
                            ]
                          : ppicHandover.items,
                      });
                    }}
                  >
                    Add Item
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              className="border border-dark shadow shadow-md overflow-auto"
              style={{ height: "65vh", resize: "vertical" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "Delete",
                    "#",
                    // "Item",
                    "MFR",
                    "PN",
                    "Part Name",
                    "Part Desc",
                    "Qty",
                    // "Product",
                    // "MR",
                    // "Job",
                    "WH",
                    "PO",
                    "QC Status",
                  ].map((h) => (
                    <>
                      <th className="bg-dark text-light">{h}</th>
                    </>
                  ))}
                </tr>
                {ppicHandover.items.map((i, i_) => {
                  const foundItem = items.items.find(
                    (ix) => `${ix.id}` === `${i.productId}`
                  );

                  const foundWhs = inventory.current.filter(
                    (ix) =>
                      i.productId && `${ix.productId}` === `${i.productId}`
                  );

                  const itemDesc = foundItem
                    ? getWarehouseItemDescription(foundItem)
                    : "Invalid";
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                              setPpicHandoverItemDeleteIds([
                                ...ppicHandoverItemDeleteIds,
                                i.masterJavaBaseModel?.id ?? "",
                              ]);
                              setPpicHandover({
                                ...ppicHandover,
                                items: ppicHandover.items.filter(
                                  (_, ix) => ix !== i_
                                ),
                              });
                            }}
                          >
                            Delete
                          </button>
                        </td>

                        <td className="border border-dark">{i_ + 1}</td>
                        <td className="border border-dark">
                          {/* {JSON.stringify(i.productId)}
                          {JSON.stringify(items.items.length)}
                          {JSON.stringify(foundItem)} */}
                          {i.productId ? (
                            <>
                              {itemsLoading ? (
                                <>
                                  <Spinner animation="border" />
                                </>
                              ) : (
                                <>
                                  <div>
                                    <div>{foundItem?.mfr}</div>
                                    <div></div>{" "}
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        setShowItemModal(true);
                                        setselectedHandoverItem(i);
                                      }}
                                    >
                                      Edit{" "}
                                    </button>{" "}
                                  </div>
                                </>
                              )}{" "}
                            </>
                          ) : (
                            <>
                              No item selected{" "}
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  setShowItemModal(true);
                                  setselectedHandoverItem(i);
                                }}
                              >
                                Select{" "}
                              </button>{" "}
                            </>
                          )}
                        </td>
                        <td className="border border-dark">
                          {foundItem?.partNum}
                        </td>
                        <td className="border border-dark">
                          {foundItem?.partName}
                        </td>
                        <td className="border border-dark">
                          {foundItem?.partDesc}
                        </td>

                        <td className="border border-dark">
                          <div className="d-flex">
                            <div>
                              <input
                                className="form-control form-control-sm"
                                placeholder="Qty.."
                                style={{ width: 75 }}
                                key={`handitem-${i_}`}
                                defaultValue={i.qty}
                                onBlur={(e) => {
                                  setPpicHandover({
                                    ...ppicHandover,
                                    items: ppicHandover.items
                                      ? ppicHandover.items.map((ix, ix_) =>
                                          ix && ix_ === i_
                                            ? {
                                                ...ix,
                                                qty: isNaN(
                                                  parseFloat(e.target.value)
                                                )
                                                  ? ix.qty
                                                  : parseFloat(e.target.value),
                                              }
                                            : ix
                                        )
                                      : ppicHandover.items,
                                  });
                                }}
                              />
                            </div>
                            <div> {i.qty}</div>
                          </div>
                        </td>
                        <td className="border border-dark">
                          <div>
                            <ol>
                              {foundWhs.map((w) => {
                                return (
                                  <>
                                    <li>{`${w.warehouse?.warehouse?.name}.${w.warehouse?.whName}`}</li>
                                  </>
                                );
                              })}
                            </ol>
                          </div>
                        </td>

                        <td className="border border-dark">
                          {/* <Select
                            value={purchaseOrders?.purchaseOrders.find(
                              (o) => `${o.id}` === `${i.poId}`
                            )}
                            options={purchaseOrders?.purchaseOrders}
                            getOptionLabel={(o) => `${o.purchaseOrderNumber}`}
                            placeholder="Select PO..."
                            onChange={(v) => {
                              const val = v as CrmPurchaseOrder;

                              setPpicHandover({
                                ...ppicHandover,
                                items: ppicHandover.items
                                  ? ppicHandover.items.map((ix, ix_) =>
                                      ix && ix_ === i_
                                        ? {
                                            ...ix,
                                            poId: val?.id,
                                          }
                                        : ix
                                    )
                                  : ppicHandover.items,
                              });
                            }}
                          />
                          {
                            purchaseOrders?.purchaseOrders.find(
                              (o) => `${o.id}` === `${i.poId}`
                            )?.purchaseOrderNumber
                          } */}

                          {foundJob?.jobPurchaseOrders.map((p) => {
                            const foundPO = purchaseOrders?.purchaseOrders.find(
                              (o) => `${o.id}` === `${p.extPurchaseOrderId}`
                            );

                            return `${foundPO?.purchaseOrderNumber ?? ""} (${
                              foundPO?.account?.name ?? ""
                            })`;
                          })}
                        </td>

                        <td className="border border-dark p-0 m-0">
                          <div className="d-flex">
                            <button
                              onClick={() => {
                                setPpicHandover({
                                  ...ppicHandover,
                                  items: ppicHandover.items.map((hx, ix) =>
                                    ix === i_
                                      ? {
                                          ...hx,
                                          qaStatus: HandoverQAStatus.QA_PASSED,
                                        }
                                      : hx
                                  ),
                                });
                              }}
                              className={`px-1 py-0 btn btn-sm 4 ${
                                handoverQAStatusFromJSON(i.qaStatus) ===
                                HandoverQAStatus.QA_PASSED
                                  ? `btn-success`
                                  : `btn-outline-success`
                              }`}
                            >
                              PASS
                            </button>
                            <button
                              onClick={() => {
                                setPpicHandover({
                                  ...ppicHandover,
                                  items: ppicHandover.items.map((hx, ix) =>
                                    ix === i_
                                      ? {
                                          ...hx,
                                          qaStatus:
                                            HandoverQAStatus.QA_NOT_PASSED,
                                        }
                                      : hx
                                  ),
                                });
                              }}
                              className={`px-1 py-0 btn btn-sm 4 ${
                                handoverQAStatusFromJSON(i.qaStatus) ===
                                HandoverQAStatus.QA_NOT_PASSED
                                  ? `btn-danger`
                                  : `btn-outline-danger`
                              }`}
                            >
                              NOPASS
                            </button>
                          </div>

                          <div className="my-2 d-flex">
                            <button
                              onClick={() => {
                                if (!window.confirm("Pass all?")) {
                                  return;
                                }
                                setPpicHandover({
                                  ...ppicHandover,
                                  items: ppicHandover.items.map((hx, ix) => ({
                                    ...hx,
                                    qaStatus: HandoverQAStatus.QA_PASSED,
                                  })),
                                });
                              }}
                              className={`px-1 py-0 btn btn-sm 4 btn-success`}
                            >
                              PASS ALL
                            </button>
                            <button
                              onClick={() => {
                                if (!window.confirm("Nopass all?")) {
                                  return;
                                }

                                setPpicHandover({
                                  ...ppicHandover,
                                  items: ppicHandover.items.map((hx, ix) => ({
                                    ...hx,
                                    qaStatus: HandoverQAStatus.QA_NOT_PASSED,
                                  })),
                                });
                              }}
                              className={`px-1 py-0 btn btn-sm 4 btn-danger`}
                            >
                              NOPASS ALL
                            </button>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Modal
        size="xl"
        show={showItemModal}
        onHide={() => {
          setShowItemModal(false);
        }}
      >
        <ModalHeader>Select Item</ModalHeader>
        <ModalBody>
          <div>
            <input
              className="form-control form-control-sm"
              placeholder="Search by PN, name, desc..."
              onBlur={(e) => {
                setSearchItem(e.target.value);
              }}
            />
          </div>
          <div
            className="overflow-auto border border-dark shadow shadow-md"
            style={{ height: "65vh" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {[
                  "#",
                  "Qty",
                  "Select",
                  "MFR",
                  "PN",
                  "Name",
                  "Desc",
                  "WH",
                  "In stock",
                  "Free stock",
                ].map((h) => (
                  <>
                    <th className="bg-dark text-light">{h}</th>
                  </>
                ))}
              </tr>
              {searchItems(items, searchItem).map((i, i_) => {
                const foundWhs = inventory.current.filter(
                  (ix) => i.id && `${ix.productId}` === `${i.id}`
                );
                const inStock = foundWhs.reduce(
                  (acc, w) => acc + (w.qty ?? 0),
                  0
                );

                const freeStock = foundWhs.reduce(
                  (acc, w) => acc + (w.balanceRsv ?? 0),
                  0
                );

                return (
                  <>
                    <tr>
                      <td className="border border-dark">{i_ + 1}</td>
                      <td className="border border-dark">
                        <input
                          className="form-control form-control-sm"
                          placeholder="Qty.."
                          style={{ width: 75 }}
                          onBlur={(e) => {
                            setMrLeftoverQty(
                              isNaN(parseFloat(e.target.value))
                                ? mrLeftoverQty
                                : parseFloat(e.target.value)
                            );
                          }}
                        />
                      </td>

                      <td className="border border-dark">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            setShowItemModal(false);
                            setPpicHandover({
                              ...ppicHandover,
                              items: ppicHandover.items
                                ? ppicHandover.items.map((ix, ix_) =>
                                    ix.masterJavaBaseModel?.uuid ===
                                    selectedHandoverItem?.masterJavaBaseModel
                                      ?.uuid
                                      ? {
                                          ...ix,
                                          productId: i.id,
                                          qty: mrLeftoverQty,
                                        }
                                      : ix
                                  )
                                : ppicHandover.items,
                            });
                          }}
                        >
                          Select Item
                        </button>
                      </td>
                      <td className="border border-dark">{i.mfr}</td>

                      <td className="border border-dark">{i.partNum}</td>
                      <td className="border border-dark">{i.partName}</td>
                      <td className="border border-dark">{i.partDesc}</td>
                      <td className="border border-dark">
                        <div>
                          <ol>
                            {foundWhs.map((w) => {
                              return (
                                <>
                                  <li>{`${w.warehouse?.warehouse?.name}.${w.warehouse?.whName}`}</li>
                                </>
                              );
                            })}
                          </ol>
                        </div>
                      </td>
                      <td className="border border-dark">{inStock}</td>
                      <td className="border border-dark">{freeStock}</td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default HandoverDetailPage;
