import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import {
  calculateProjectProgress,
  compareDateStringUtcColor,
  deptKpiPastAliases,
  ExtDepartmentLastPlan,
  extractAttendanceKpiMonthData,
  extractProtoMeetingTaskTargetDate,
  fetchAttendanceData,
  fetchAttendanceKpiData,
  fetchAttendanceNotes,
  fetchAttendanceNoteTypes,
  fetchBackendCrmSales,
  fetchCustomersProto,
  fetchDepartmentTemplatesProto,
  fetchExtAuthserverEmployeeShifts,
  fetchExtAuthserverEmployeeShiftTemplates,
  fetchExtCrmPurchaseOrdersProto,
  fetchIntegrationModulesProto,
  fetchJobInfoProto,
  fetchJobsDataByPoItemIds,
  fetchJobsDataProtoCompose,
  fetchJobsDeptProgress,
  fetchJobsProtoSimple,
  fetchKpiApiProto,
  fetchKpiDeptRes,
  fetchLocationRecordsDateRange,
  fetchMachinePrograms,
  fetchMachines,
  fetchPanelCodeDepartmentTemplateItemsProto,
  fetchPanelCodeDepartmentTemplatePresetsProto,
  fetchPpicAssistances,
  fetchProblemCatalogs,
  fetchProjectMasterTemplatePresetsProto,
  fetchProjectMasterTemplatesProto,
  fetchProjectSimpleProto,
  fetchRcemDepartmentsProto,
  fetchSimplifiedProcessTypesProto,
  fetchTaskListTemplates,
  fetchWarehouseItemsProto,
  fetchWorkOrdersProto,
  fetchWorkOrdersProtoMongo,
  filterMeetingTasks,
  filterWorkOrderByDate,
  filterWorkOrderByDateRange,
  findKpiUser,
  getChromaColor,
  getProblemCatalogFullName,
  getProjectDeadlineChromaColorScale,
  getWorkOrderTimeMinutes,
  haversine,
  kpiDeptFutureAliases,
  kpiFutureDeptCategoryAliases,
  kpiPastDeptCategoryAliases,
  makeDateString,
  MeetingTaskDetailById,
  OSRMObject,
  padNumber,
  parseJwt,
  parseNominatimAddress,
  pertaliteFuelBillConfig,
  remoteControlPayloadToPreset,
  remoteControlPresetToPayload,
  secondsToHms,
  skipDateHop,
  TaskListInfo,
  timeDiff,
} from "../../helpers";
import { Chart } from "react-google-charts";

import * as PahoMQTT from "paho-mqtt";
import { RemoteControlPageTopic } from "./RemoteControlPage/RemoteControlPage";
import { ExtUser, Job, Machine, PanelCode } from "../../models/model";
import {
  AuthserverShiftTemplates,
  authserverUser_AuthorizationLevelFromJSON,
  authserverUser_AuthorizationLevelToNumber,
  AuthserverUserShifts,
  CrmPurchaseOrders,
  KpiDeptCache,
  KpiDeptCacheCategoryEnum,
  kpiDeptCacheCategoryEnumFromJSON,
  KpiDeptCacheCategoryRangeEnum,
  kpiDeptCacheCategoryRangeEnumFromJSON,
  KpiDeptCacheCategoryTimeEnum,
  kpiDeptCacheCategoryTimeEnumFromJSON,
  KpiDeptCacheTime,
  MeetingKpi,
  MeetingLastPlanDate,
  MeetingLastPlanDates,
  MeetingMasterTemplatePresets,
  MeetingMeetingTaskProblemCatalogs,
  MeetingProblemCatalog,
  MeetingProblemCatalogs,
  MeetingProblemType,
  meetingProblemTypeFromJSON,
  meetingProblemTypeToJSON,
  MeetingProject,
  MeetingProjectMasterTemplates,
  MeetingProjects,
  MeetingTaskList,
  MeetingTaskListsView,
  MeetingTaskListView,
  MeetingTaskProblemCatalogStatus,
  meetingTaskProblemCatalogStatusFromJSON,
  PpicDepartmentTemplates,
  PpicIndividualEntities,
  PpicIntegrationModules,
  PpicJobInfoList,
  PpicJobOverviews,
  PpicJobs,
  PpicMachinePrograms,
  PpicManpowerAssistances,
  PpicPanelCode,
  PpicPanelCodeDepartmentTemplateItems,
  PpicPanelCodeDepartmentTemplatePresets,
  PpicRcemDepartments,
  PpicRemoteControlPayload,
  PpicRemoteControlPreset,
  PpicSimplifiedProcessTypes,
  WarehouseItems,
  WorkOrderType,
  workOrderTypeFromJSON,
  workOrderTypeToJSON,
} from "../../masterbigsystem";
import { Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import chroma from "chroma-js";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useInterval } from "react-interval-hook";
import {
  Add,
  Check,
  CheckCircle,
  Close,
  Pause,
  PrintOutlined,
  Refresh,
  RefreshOutlined,
  Remove,
  RemoveCircle,
} from "@material-ui/icons";
import {
  FeatureGroup,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import { LatLngTuple, Map } from "leaflet";
import ProductMonitoringMap from "./ProductMonitoringMap";
import { Async } from "react-async";
import {
  AttendanceNote,
  AttendanceNoteType,
  LocationRecord,
  UserFirstCheckIn,
} from "../../models/meeting";
import {
  AliasDetail,
  aliases,
  KpiRange,
  kpiRanges,
  kpiRangesMonth,
  kpiRangesNegative,
  kpiRangesNegativeMonth,
  kpiRangesPositive,
  kpiRangesPositiveMonth,
} from "../RcemKpiPage/RcemKpiPage";
import ColorHash from "color-hash";
import { Bar } from "react-chartjs-2";
import { updateSourceFile } from "typescript";

export interface DeptWoFilterType {
  weeks: number | null;
  expand: boolean;
  workOrderType?: WorkOrderType;
  deptId?: string | undefined;
  extUserId?: string | undefined;
  jobId?: string | undefined;
  skipDate?: number | null | undefined;
  picOnly?: boolean | null;
  jdpPresetId?: string | undefined;
  extDeptVersusId?: string | undefined;
  extProblemCatalogId?: string | undefined;
  woGraph?: boolean | undefined;
  crmDivisionType?: string | undefined;
  crmUserIds?: string | undefined;
}

export const data = [
  ["WO", "Work Hour"],
  ["Sen", 7.8],
  ["Sel", 5],
  ["Rab", 6.1],
  ["Kam", 3],
  ["Jum", 9],
  ["Sab", 6],
];

export const options = {
  chart: {
    title: "Dept Performance",
    subtitle: "By Work Order",
  },
};
const skipDateInterval = 11;

type LayoutModeType = "Regular" | "QuadScreen" | "QuadScreenV2";

const ProductMonitoringPage = () => {
  const ctx = useContext(AppContext);

  const [, forceRender] = useState(false);

  const render = () => {
    forceRender((n) => !n);
  };

  const attendanceNoteDetailFilter = useRef(
    null as { deptId?: any; start?: any; end?: any } | null
  );
  const shifts = useRef(AuthserverUserShifts.fromPartial({}));
  const shiftTemplates = useRef(AuthserverShiftTemplates.fromPartial({}));
  const [bypass, setBypass] = useState(false);
  const fetchShiftData = async () => {
    const d = await fetchExtAuthserverEmployeeShifts({
      apiKey: ctx?.apiKey ?? "",
    });

    shifts.current = d;
    render();
  };
  const fetchShiftTemplatesData = async () => {
    const d = await fetchExtAuthserverEmployeeShiftTemplates({
      apiKey: ctx?.apiKey ?? "",
    });
    shiftTemplates.current = d;
    render();
  };

  const weeklyFromDate = useRef(null as string | null);
  const weeklyToDate = useRef(null as string | null);

  const [mpAssistances, setMpAssistances] = useState(
    PpicManpowerAssistances.fromPartial({})
  );
  const [deptV2Loadings, setDeptV2Loadings] = useState<
    { loading: boolean; deptId: string | undefined }[]
  >([]);
  const [extDepartmentLastPlans, setExtDepartmentLastPlans] = useState<
    { lastPlan: MeetingLastPlanDate; extDepartmentId: any }[]
  >([]);
  const [locationExpandedUserIds, setLocationExpandedUserIds] = useState<
    string[]
  >([]);
  // const
  const mapRef = useRef<Map | null>(null);

  const [problemCatalogs, setProblemCatalogs] = useState(
    MeetingProblemCatalogs.fromPartial({ catalogs: [] })
  );
  const [startingDate, setStartingDate] = useState(new Date());
  const [
    panelCodeDepartmentTemplateItemsProto,
    setPanelCodeDepartmentTemplateItemsProto,
  ] = useState(
    PpicPanelCodeDepartmentTemplateItems.fromPartial({
      departmentTemplateItems: [],
    })
  );
  const [jobOverview, setJobOverview] = useState(
    PpicJobOverviews.fromPartial({ jobs: [] })
  );
  const [customers, setCustomers] = useState<PpicIndividualEntities>(
    PpicIndividualEntities.fromPartial({ entities: [] })
  );
  // const map = useMap();
  const [osrmData, setOsrmData] = useState<OSRMObject | null | undefined>(null);
  const [osrmDataActual, setOsrmDataActual] = useState<
    OSRMObject | null | undefined
  >(null);

  const [kpiDeptData, setKpiDeptData] = useState<KpiDeptCache[]>([]);

  const [quadScreenVersion, setQuadScreenVersion] = useState<"V1" | "V2">("V1");
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [elapsedHourReference, setElapsedHourReference] = useState(7);
  const [selectedExtUser, setSelectedExtUser] = useState<
    ExtUser | null | undefined
  >(null);
  const [checkIns, setCheckIns] = useState<
    { date: string; checkIn: UserFirstCheckIn[] }[]
  >([]);
  const [attendanceNoteTypes, setAttendanceNoteTypes] = useState<
    AttendanceNoteType[]
  >([]);
  const [attendanceNotes, setAttendanceNotes] = useState<AttendanceNote[]>([]);
  const [jobInfo, setJobInfo] = useState<PpicJobInfoList>(
    PpicJobInfoList.fromPartial({ info: [] })
  );
  const [deptProblemCatalogs, setDeptProblemCatalogs] = useState<
    {
      extProblemCatalogId: any;
      deptProblemCatalogs: MeetingMeetingTaskProblemCatalogs;
    }[]
  >([]);
  const [jobDepartmentTemplates, setJobDepartmentTemplates] = useState<Job[]>(
    []
  );
  const [snapInterval, setSnapInterval] = useState(true);
  const [snapIntervalRefetch, setSnapIntervalRefetch] = useState(true);

  const [locationRecords, setLocationRecords] = useState<LocationRecord[]>([]);

  const [jobsProto, setJobsProto] = useState<PpicJobs | null | undefined>(null);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [processTypes, setProcessTypes] =
    useState<PpicSimplifiedProcessTypes | null>(null);
  const [rcemDepartments, setRcemDepartments] =
    useState<PpicRcemDepartments | null>(null);
  const [integrationModules, setIntegrationModules] =
    useState<PpicIntegrationModules | null>(null);
  const [machinePrograms, setMachinePrograms] = useState<
    PpicMachinePrograms | null | undefined
  >(null);
  const [departmentTemplates, setDepartmentTemplates] =
    useState<PpicDepartmentTemplates | null>(null);
  const [mqttConnected, setMqttConnected] = useState(false);
  const [ppicRemoteControlPayloadData, setPpicRemoteControlPayloadData] =
    useState(PpicRemoteControlPayload.fromPartial({}));
  const [ppicRemoteControlPayloadsData, setPpicRemoteControlPayloadsData] =
    useState<PpicRemoteControlPayload[]>([]);
  const [jdpPresets, setJdpPresets] = useState(
    PpicPanelCodeDepartmentTemplatePresets.fromPartial({ presets: [] })
  );

  const [currentPage, setCurrentPage] = useState<RemoteControlPageTopic | null>(
    null
  );
  const [reconnectingCount, setReconnectingCount] = useState(0);
  const [foundPanelCode, setFoundPanelCode] = useState<
    PpicPanelCode | null | undefined
  >(null);
  const [taskDetailsShow, setTaskDetailsShow] = useState(false);
  const [modalTasks, setModalTasks] = useState<MeetingTaskListView[]>([]);

  const [loading, setLoading] = useState(false);
  const [deptWoFilter, setDeptWoFilter] = useState<DeptWoFilterType>({
    weeks: 1 as number | null,
    expand: false,
    workOrderType: WorkOrderType.WO_TYPE_JOB,
    deptId: undefined as string | undefined,
    extUserId: undefined as string | undefined,
    jobId: undefined as string | undefined,
    skipDate: 0 as number | null | undefined,
    picOnly: null as boolean | null,
  });

  const [deptModalLastPlanShowId, setDeptModalLastPlanShowId] = useState<
    string | null
  >(null);
  const [deptModalLastPlanShowData, setDeptModalLastPlanShowData] = useState(
    MeetingLastPlanDates.fromPartial({ dates: [] })
  );

  const [showInstallerWorkOrder, setShowInstallerWorkOrder] = useState(false);
  const [deptWoFilters, setDeptWoFilters] = useState<DeptWoFilterType[]>([]);

  const [userId, setUserId] = useState<number | null>(null);
  const [woJobLoading, setWoJobLoading] = useState(false);

  const [woDetailsPopupDataExpands, setWoDetailsPopupDataExpands] = useState<
    string[]
  >([]);
  const [woJob, setWoJob] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const [showAllLocationUsers, setShowAllLocationUsers] = useState(false);
  const [woJobs, setWoJobs] = useState<(MeetingTaskListsView | null)[]>([]);
  const [selectedWeekRange, setSelectedWeekRange] = useState<{
    from: Date;
    to: Date;
  } | null>({ from: new Date(), to: new Date() });

  const interval = useInterval(() => {
    setSnapIntervalRefetch((i) => !i);
  }, 1000 * 60 * 15);

  const [layoutMode, setLayoutMode] = useState<LayoutModeType>("Regular");
  const [kpiRangeData, setKpiRangeData] = useState<
    { range: KpiRange; kpi: MeetingKpi; loading: boolean }[]
  >(
    kpiRangesMonth.map((r) => ({
      range: r,
      loading: true,
      kpi: MeetingKpi.fromPartial({}),
    }))
  );
  const [deptTemplatePresets, setDeptTemplatePresets] = useState(
    PpicPanelCodeDepartmentTemplatePresets.fromPartial({})
  );
  const [popupAlphaAttendance, setPopupAlphaAttendance] = useState(false);

  const [woJobsOuts, setWoJobsOuts] = useState<
    { range: KpiRange; wos: MeetingTaskListsView }[]
  >([]);
  const [jobsJdp, setJobsJdp] = useState(PpicJobs.fromPartial({ jobs: [] }));
  const [jobsJdpLoading, setJobsJdpLoading] = useState(false);
  const [deptDayWos, setDeptDayWos] = useState<
    {
      deptId: string | null | undefined;
      today: MeetingTaskListsView;
      tomorrow: MeetingTaskListsView;
    }[]
  >([]);

  const projectsSimple = useRef<MeetingProjects | null>(null);
  const jobs = useRef<Job[] | null>(null);
  const deptTemplates = useRef<PpicDepartmentTemplates | null>(null);
  const templates = useRef<MeetingProjectMasterTemplates | null>(null);
  const selectedProject = useRef<MeetingProject | null>(null);
  const pos = useRef<CrmPurchaseOrders | null>(null);
  const items = useRef<WarehouseItems | null>(null);
  const projectTasks = useRef(MeetingTaskListsView.fromPartial({}));
  const selectedProjectWoDialog = useRef<MeetingProject | null>(null);
  const internalExternalIssueDetail = useRef<{
    dept: string | undefined;
    start: string | undefined;
    end: string | undefined;
  } | null>();
  const internalExternalIssueDetailWorkOrders = useRef(
    MeetingTaskListsView.fromPartial({})
  );

  const presets = useRef(MeetingMasterTemplatePresets.fromPartial({}));
  const fetchProjectMasterTemplatePresetsProtoData = async () => {
    const d = await fetchProjectMasterTemplatePresetsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      presets.current = d;
      render();
    }
  };

  const [, refresh] = useState(false);

  const fetchPurchaseOrdersData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
    }
    render();
  };

  const fetchProjectsData = async () => {
    const d = await fetchProjectSimpleProto(ctx?.apiKey ?? "", true);
    if (d) {
      projectsSimple.current = d;
      render();

      fetchJobsDataByPoItemIdsData([
        ...new Set(
          d.projects
            .flatMap((p) => p.products)
            .map((p) => p.extCrmPurchaseOrderItemId as string)
            .filter((i) => i)
            .map((i) => (!isNaN(parseInt(i)) ? parseInt(i) : 0))
        ),
      ]);

      fetchTaskListsByProjectIds(
        d.projects
          .map((p) =>
            isNaN(parseInt(p.masterJavaBaseModel?.id ?? ""))
              ? null
              : parseInt(p.masterJavaBaseModel?.id ?? "")
          )
          .filter((i) => i)
          .map((i) => i as number)
      );
    }
  };
  const fetchJobsDataByPoItemIdsData = async (poItemIds: number[]) => {
    const d = await fetchJobsDataByPoItemIds({
      apiKey: ctx?.apiKey ?? "",
      poItemIds: poItemIds,
    });
    if (d) {
      jobs.current = d;
      render();
    }
  };

  const fetchTaskListsByProjectIds = async (projectIds: number[]) => {
    const d = await fetchWorkOrdersProtoMongo({
      apiKey: ctx?.apiKey ?? "",
      extProjectIds: projectIds,
    });
    if (d) {
      projectTasks.current = d;
      render();
    }
  };
  const fetchItemsData = async () => {
    const d = await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" });
    if (d) {
      items.current = d;
      render();
    }
  };

  const fetchProjectMasterTemplatesProtoData = async () => {
    const d = await fetchProjectMasterTemplatesProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      templates.current = d;
      render();
    }
  };
  const fetchDeparmentTemplatesProtoData = async () => {
    const d = await fetchDepartmentTemplatesProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      deptTemplates.current = d;
      render();
    }
  };

  const fetchProjectMasterTemplateDetailsPageData = () => {
    fetchProjectsData();
    fetchProjectMasterTemplatesProtoData();
    fetchDeparmentTemplatesProtoData();
    fetchPurchaseOrdersData();
    fetchItemsData();
    fetchProjectMasterTemplatePresetsProtoData();
  };

  useEffect(() => {
    if (deptModalLastPlanShowId) {
      (async () => {
        try {
          const resp = await fetch(
            `${process.env.REACT_APP_MEETING_URL}/last-plan?extDepartmentId=${
              deptModalLastPlanShowId ?? ""
            }&multiple=true`
          );

          if (resp.status !== 200) throw await resp.text();
          const j = await resp.json();

          let newPlan = MeetingLastPlanDates.fromPartial(j);
          setDeptModalLastPlanShowData(newPlan);
        } catch (e) {}
      })();
    }
  }, [deptModalLastPlanShowId]);

  const fetchBackendCrmSalesData = async () => {
    const d = await fetchBackendCrmSales({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      salesList.current = d;
      render();
    }
  };

  useEffect(() => {
    // fetchJobs();
    fetchJobOverviewData();
    fetchMachinesData();
    fetchDepartmentTemplatesData();
    fetchRcemDepartmentsData();
    fetchProcessTypesData();
    fetchIntegrationModulesData();
    fetchJobsData();
    fetchMachineProgramsData();
    fetchAttendanceNotesData();
    fetchPpicJobInfoData();
    fetchPanelCodeDeparmentTemplateItemsData();
    fetchCustomersData();
    handleFetchAttendanceNoteTypes();
    handleFetchProblemCatalogs();
    // fetchAttendanceTodayData();
    // handleFetchPresetsData();
    handleFetchPresetsData();
    handleManpowerAssistancesData();
    fetchShiftData();
    fetchShiftTemplatesData();
    fetchBackendCrmSalesData();
  }, []);

  const handleFetchJobsJdp = async () => {
    setJobsJdpLoading(true);

    const d = await fetchJobsProtoSimple({
      withDeptTemplates: true,
      withProducts: true,
      withRemarks: true,
      withSerialNumbers: false,
    });

    if (d) {
      setJobsJdp(d);
    }

    setJobsJdpLoading(false);
  };

  const handleFetchAttendanceNoteTypes = async () => {
    const d = await fetchAttendanceNoteTypes({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setAttendanceNoteTypes(d);
    }
  };

  const handleManpowerAssistancesData = async () => {
    const d = await fetchPpicAssistances({
      apiKey: ctx?.apiKey ?? "",
      date: makeDateString(new Date()),
    });

    if (d) {
      setMpAssistances(d);
    }
  };
  const handleFetchProblemCatalogs = async () => {
    const d = await fetchProblemCatalogs({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      setProblemCatalogs(d);
    }
  };
  const handleFetchPresetsData = async () => {
    setDeptTemplatePresets(
      await fetchPanelCodeDepartmentTemplatePresetsProto({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  const salesList = useRef([] as any[]);

  const divisionFilter = useRef(null as string | null);
  const managerFilterId = useRef(null as string | null);
  const salesFilterIds = useRef([] as string[]);

  // useEffect(() => {
  //   if (layoutMode === "QuadScreen") {
  //     handleKpiRangeData();
  //   }
  // }, [layoutMode]);
  const handleKpiRangeData = async (extDepartmentIds: string[]) => {
    setKpiRangeData((rx) => rx.map((rxx) => ({ ...rxx, loading: true })));

    kpiRangesMonth.forEach(async (r) => {
      const foundKpiRange = kpiRangeData.find((rx) => rx.range === r);
      const foundKpiRangeDetail = aliases.find((a) => a.range === r);

      // if (!foundKpiRange) {
      const dateA = new Date();
      const dateB = new Date(new Date().getTime() + 86400000 * r);

      console.log("date a:", dateA, "date b:", dateB);
      const d = await fetchKpiApiProto({
        apiKey: ctx?.apiKey ?? "",
        from: foundKpiRangeDetail?.startDate
          ? `${foundKpiRangeDetail?.startDate}T00:00:00Z`
          : r >= 0
          ? `${makeDateString(dateA)}T00:00:00Z`
          : `${makeDateString(dateB)}T00:00:00Z`,
        to: foundKpiRangeDetail?.endDate
          ? `${foundKpiRangeDetail?.endDate}T00:00:00Z`
          : r >= 0
          ? `${makeDateString(dateB)}T00:00:00Z`
          : `${makeDateString(dateA)}T00:00:00Z`,
        attendance: false,
        deptIds: extDepartmentIds,
      });

      if (d) {
        setKpiRangeData((dx) =>
          dx.map((dxx) =>
            dxx.range === r ? { ...dxx, kpi: d, loading: false } : dxx
          )
        );
      }
      // }
    });
  };

  useEffect(() => {
    if ((jobsProto?.jobs.length ?? 0) > 0 && currentPage != null) {
      fetchWoFromJob();
    }
  }, [jobsProto, deptWoFilter]);

  useInterval(() => {
    if (!mqttConnected) {
      setReconnectingCount((n) => n + 1);
    }
  }, 5000);

  useEffect(() => {
    if (reconnectingCount > 10) {
      window.location.reload();
    }
  }, [reconnectingCount]);

  const fetchCustomersData = async () => {
    setCustomers(await fetchCustomersProto({ apiKey: ctx?.apiKey ?? "" }));
  };

  const fetchAttendanceNotesData = async () => {
    setAttendanceNotes(
      await fetchAttendanceNotes({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const fetchPanelCodeDeparmentTemplateItemsData = async () => {
    setPanelCodeDepartmentTemplateItemsProto(
      await fetchPanelCodeDepartmentTemplateItemsProto({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  const fetchPpicJobInfoData = async () => {
    setJobInfo(await fetchJobInfoProto({ apiKey: ctx?.apiKey ?? "" }));
  };
  const [debug, setDebug] = useState(false);

  const [woDetailsPopup, setWoDetailsPopup] = useState(false);
  const [woDetailsDeptId, setWoDetailsDeptId] = useState<string | undefined>(
    undefined
  );
  const [woDetailsRange, setWoDetailsRange] = useState<KpiRange | null>(null);

  const [woDetailsPopupData, setWoDetailsPopupData] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const [woDetailsV2Range, setWoDetailsV2Range] =
    useState<KpiDeptCacheCategoryRangeEnum | null>(null);
  const [woDetailsV2TimePastFuture, setWoDetailsV2TimePastFuture] =
    useState<KpiDeptCacheCategoryTimeEnum | null>(null);
  const [woDetailsV2Category, setWoDetailsV2Category] =
    useState<KpiDeptCacheCategoryEnum | null>(null);
  const [customRangeDetail, setCustomRangeDetail] =
    useState<AliasDetail | null>(null);

  const showWoPopup = async (props: {
    deptId: any;
    range: KpiRange;
    v2Range?: KpiDeptCacheCategoryRangeEnum;
    v2TimePastFuture?: KpiDeptCacheCategoryTimeEnum;
    v2Category?: KpiDeptCacheCategoryEnum;

    customRangeDetail?: AliasDetail;
  }) => {
    try {
      setWoDetailsPopup(true);
      setWoDetailsDeptId(props.deptId);
      setWoDetailsRange(props.range);

      console.log(props.v2Range, props.v2TimePastFuture, props.v2Category);

      if (props.v2Range) {
        setWoDetailsV2Range(props.v2Range);
      }

      if (props.v2TimePastFuture) {
        setWoDetailsV2TimePastFuture(props.v2TimePastFuture);
      }

      if (props.v2Category) {
        setWoDetailsV2Category(props.v2Category);
      }

      if (props.customRangeDetail) {
        setCustomRangeDetail(props.customRangeDetail);
      }

      const rangeDetail = props.customRangeDetail
        ? props.customRangeDetail
        : aliases.find((a) => a.range === props.range);

      const dateA = rangeDetail?.startDate
        ? `${rangeDetail.startDate}`
        : makeDateString(new Date());
      const dateB = rangeDetail?.endDate
        ? `${rangeDetail.endDate}`
        : makeDateString(
            new Date(
              new Date(makeDateString(new Date())).getTime() +
                86400000 * props.range
            )
          );
      const d = await fetchWorkOrdersProtoMongo({
        extUserIds: ctx?.extUsers
          .filter((u) => `${u.departmentId}` === `${props.deptId}`)
          .map((u) => u.id),
        from: dateA < dateB ? dateA : dateB,
        to: dateA < dateB ? dateB : dateA,
      });

      setWoDetailsPopupData(d);
    } catch (e) {}
  };

  const CapacityData = (props: {
    r: KpiRange;
    deptId: string | null | undefined;
    type: "Actual" | "Plan";
  }) => {
    const kpiUsers = findKpiUser(kpiRangeData, props.r, props.deptId, ctx);

    const kpiUsersWorkingHours = kpiUsers?.data?.reduce(
      (acc, k) =>
        acc +
        (() => {
          switch (props.type) {
            case "Actual":
              return k.workOrder?.averageDailyWorkingHoursDone ?? 0;
            case "Plan":
              return k.workOrder?.averageDailyWorkingHours ?? 0;
            default:
              return 0;
          }
        })(),
      0
    );
    const data = extractAttendanceKpiMonthData(
      ctx,
      kpiAttendanceRanges,
      props.r,
      props.deptId
    );

    const daysNum = aliases.find((a) => a.range === props.r)?.daysCount
      ? aliases.find((a) => a.range === props.r)?.daysCount ?? 0
      : Math.abs(props.r);

    return (
      <>
        <td
          className="m-0 p-0 border border-dark"
          // style={{
          //   backgroundColor: getChromaColor(
          //     (kpiUsersWorkingHours ?? 0) / (kpiUsers?.data?.length ?? 1) / 8
          //   ),
          // }}
        >
          {!kpiUsers.foundKpi?.loading ? (
            <>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showWoPopup({
                    deptId: props.deptId,
                    range: props.r,
                  });
                }}
              >
                <div>
                  <small>
                    <strong>
                      {(
                        (kpiUsersWorkingHours ?? 0) /
                        (kpiUsers.data?.length ?? 1)
                      )?.toFixed(1)}
                      {/* {(
                        (kpiUsersWorkingHours ?? 0) /
                        (kpiUsers?.data?.length ?? 1)
                      )?.toFixed(1)}{" "}
                      |{" "}
                      <span
                        style={{
                          backgroundColor: getChromaColor(
                            (kpiUsersWorkingHours ?? 0) / data.hrs
                          ),
                        }}
                      >
                        {kpiUsersWorkingHours?.toFixed(0)}
                      </span> */}
                    </strong>
                  </small>
                </div>
                <div>
                  {debug ? (
                    <>
                      WH(
                      {kpiUsersWorkingHours?.toFixed(1)}
                      ):US(
                      {kpiUsers?.data?.length})
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="spinner-border spinner-border-sm"></div>
            </>
          )}
        </td>
      </>
    );
  };

  const OntimeData = (props: {
    r: KpiRange;
    deptId: string | null | undefined;
  }) => {
    const foundKpi = kpiRangeData.find((d) => `${d.range}` === `${props.r}`);

    const kpiUsers = findKpiUser(kpiRangeData, props.r, props.deptId, ctx);

    const kpiUsersOntimeDone = kpiUsers?.data?.reduce(
      (acc, k) => acc + (k.workOrder?.completed ?? 0),
      0
    );
    const data = extractAttendanceKpiMonthData(
      ctx,
      kpiAttendanceRanges,
      props.r,
      props.deptId
    );

    const daysNum = aliases.find((a) => a.range === props.r)?.daysCount
      ? aliases.find((a) => a.range === props.r)?.daysCount ?? 0
      : props.r;

    return (
      <>
        <td className="m-0 p-0 border border-dark">
          {foundKpi?.kpi.deptTotalWosArray ? (
            <>
              <small>
                <strong>
                  {
                    foundKpi?.kpi.deptTotalWosArray.find(
                      (d) => `${d.deptId}` === `${props.deptId}`
                    )?.totalWorkOrdersOverdue
                  }
                </strong>
              </small>
            </>
          ) : (
            <>
              <div className="spinner-border spinner-border-sm"></div>
            </>
          )}{" "}
        </td>
      </>
    );
  };

  const QualityData = (props: {
    r: KpiRange;
    deptId: string | null | undefined;
    type: "Cost" | "Quality";
  }) => {
    const foundKpi = kpiRangeData.find((d) => `${d.range}` === `${props.r}`);

    const kpiUsers = findKpiUser(kpiRangeData, props.r, props.deptId, ctx);

    // const kpiUsersOntimeDone = kpiUsers?.data?.reduce(
    //   (acc, k) => acc + (k.workOrder?.completed ?? 0),
    //   0
    // );
    // const data = extractAttendanceKpiMonthData(
    //   ctx,
    //   kpiAttendanceRanges,
    //   props.r,
    //   props.deptId
    // );

    // const daysNum = aliases.find((a) => a.range === props.r)?.daysCount
    //   ? aliases.find((a) => a.range === props.r)?.daysCount ?? 0
    //   : props.r;

    return (
      <>
        <td className="m-0 p-0 border border-dark">
          {foundKpi?.kpi.deptTotalWosArray ? (
            <>
              <small>
                <strong>
                  {props.type === "Quality" ? (
                    foundKpi?.kpi.deptTotalWosArray.find(
                      (d) => `${d.deptId}` === `${props.deptId}`
                    )?.totalWorkOrdersProblemQuality
                  ) : (
                    <></>
                  )}

                  {props.type === "Cost" ? (
                    foundKpi?.kpi.deptTotalWosArray.find(
                      (d) => `${d.deptId}` === `${props.deptId}`
                    )?.totalWorkOrdersProblemCost
                  ) : (
                    <></>
                  )}
                </strong>
              </small>
            </>
          ) : (
            <>
              <div className="spinner-border spinner-border-sm"></div>
            </>
          )}{" "}
        </td>
      </>
    );
  };

  const fetchDeptV2Kpi = async (props: {
    deptId: string | null | undefined;
  }) => {
    if (deptV2Loadings.find((dx) => dx.deptId === props.deptId)) {
      setDeptV2Loadings((l) =>
        l.map((dx) =>
          dx.deptId === props.deptId ? { ...dx, loading: true } : dx
        )
      );
    } else {
      setDeptV2Loadings((l) => [
        ...l,
        {
          deptId: props.deptId ?? undefined,
          loading: true,
        },
      ]);
    }

    const newDeptData = await fetchKpiDeptRes({
      apiKey: ctx?.apiKey ?? "",
      deptId: props.deptId,
      bypassCache: true,
    });

    if (newDeptData) {
      if (kpiDeptData.find((dxx) => `${dxx.deptId}` === `${props.deptId}`)) {
        setKpiDeptData((kpiDeptData) =>
          kpiDeptData.map((dx) =>
            `${dx.deptId}` === `${props.deptId}` ? newDeptData : dx
          )
        );
      } else {
        setKpiDeptData((d) => [...d, newDeptData]);
      }
    }

    setDeptV2Loadings((l) =>
      l.map((lx) =>
        lx.deptId === props.deptId ? { ...lx, loading: false } : lx
      )
    );
  };

  const QuadPanelV2 = (props: {
    deptId: string | null | undefined;
    deptWoFilterIndex: number;
  }) => {
    const foundKpiDeptCache = kpiDeptData.find(
      (d) => `${d.deptId}` === `${props.deptId}`
    );
    const foundDept = ctx?.extDepartments.find(
      (d) => `${d.id}` === `${props.deptId}`
    );
    const deptUsers = ctx?.extUsers.filter(
      (u) => `${u.departmentId}` === `${props.deptId}`
    );

    const foundDeptWoFilter = deptWoFilters.find(
      (d) => `${d.deptId}` === `${props.deptId}`
    );
    return (
      <>
        {foundDeptWoFilter?.woGraph ? (
          <>
            {" "}
            <div className="w-100 h-100">
              <Chart
                chartType="Bar"
                width="100%"
                height="40vh"
                data={data}
                options={options}
              />
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="d-flex">
              <div className="flex-grow-1">
                <table
                  className="table table-sm"
                  style={{
                    // borderCollapse: "separate",
                    width: "100%",
                  }}
                >
                  <tr className="m-0 p-0">
                    <th className="bg-dark text-light m-0 p-0">
                      <div>
                        <div>
                          <small>
                            <strong>
                              KPI{" "}
                              {foundDept?.name
                                ?.split(" ")
                                .map((s) => s.substring(0, 5))
                                .join(" ")}
                              : {deptUsers?.length} pers
                            </strong>
                          </small>
                        </div>
                        <div className="d-flex">
                          <small>
                            <strong>
                              L.upd:
                              {(() => {
                                try {
                                  if (!foundKpiDeptCache?.lastCreated) {
                                    return "";
                                  }

                                  const d = new Date(
                                    foundKpiDeptCache?.lastCreated
                                  );

                                  return `${Intl.DateTimeFormat("en-US", {
                                    dateStyle: "short",
                                  } as any).format(
                                    d
                                  )},${d.getHours()}:${d.getMinutes()}`;
                                } catch (e) {
                                  return "";
                                }
                              })()}
                            </strong>
                          </small>
                          <div>
                            {deptV2Loadings.find(
                              (d) => d.deptId === props.deptId
                            )?.loading == true ? (
                              <>
                                {" "}
                                <div className="spinner-border spinner-border-sm"></div>
                              </>
                            ) : (
                              <>
                                <button
                                  className="p-0 m-0 btn btn-sm btn-primary"
                                  onClick={async () => {
                                    fetchDeptV2Kpi({ deptId: props.deptId });
                                  }}
                                >
                                  <small>Force rfrsh.</small>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </th>
                    {deptKpiPastAliases.map((a) => {
                      return (
                        <>
                          <th className="bg-dark text-light m-0 p-0 text-center">
                            <small>
                              <strong>{a.alias}</strong>
                            </small>
                          </th>
                        </>
                      );
                    })}
                  </tr>
                  {[
                    ...new Set(
                      attendanceNoteTypes.map((t) => t.uniqIdentifier)
                    ),
                  ]
                    .map((uid) =>
                      attendanceNoteTypes.find((t) => t.uniqIdentifier === uid)
                    )
                    .filter((t) => t)
                    .map((t) => t as AttendanceNoteType)
                    .map((t, i) => {
                      const foundAttendanceNoteType = attendanceNoteTypes.find(
                        (tx) => `${tx.id}` === `${t.id}`
                      );

                      const foundAttendanceNoteKpis =
                        foundKpiDeptCache?.attendanceNotes.filter(
                          (n) =>
                            `${
                              attendanceNoteTypes.find(
                                (tx) =>
                                  `${tx.id}` === `${n.attendanceNoteTypeId}`
                              )?.uniqIdentifier
                            }` === `${t.uniqIdentifier}`
                        );

                      return (
                        <>
                          <tr className="m-0 p-0 ">
                            <td
                              className="m-0 p-0"
                              style={{
                                border: "3px",
                                borderStyle: "solid",
                                borderTop: i === 0 ? `3px solid black` : 0,
                                borderBottom: `1px solid grey`,
                              }}
                            >
                              <small>
                                <strong>
                                  {foundAttendanceNoteType?.alias ??
                                    foundAttendanceNoteType?.name ??
                                    ""}{" "}
                                  {/* {i} */}
                                </strong>
                              </small>
                            </td>
                            {deptKpiPastAliases.map((a) => {
                              const attendanceNoteAcc =
                                foundAttendanceNoteKpis?.reduce(
                                  (acc, k) =>
                                    acc +
                                    (k.types.find(
                                      (t) =>
                                        kpiDeptCacheCategoryRangeEnumFromJSON(
                                          t.range
                                        ) ===
                                        kpiDeptCacheCategoryRangeEnumFromJSON(
                                          a.range
                                        )
                                    )?.value ?? 0),
                                  0
                                ) ?? 0;

                              const range = foundKpiDeptCache?.times
                                .flatMap((t) => t.categories)
                                .flatMap((c) => c.dates)
                                .find(
                                  (d) =>
                                    kpiDeptCacheCategoryRangeEnumFromJSON(
                                      d.range
                                    ) ===
                                    kpiDeptCacheCategoryRangeEnumFromJSON(
                                      a.range
                                    )
                                );

                              return (
                                <>
                                  <td
                                    className="m-0 p-0"
                                    style={{
                                      border: `1px solid grey`,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      attendanceNoteDetailFilter.current = {
                                        deptId: foundDept?.id,
                                        start: range?.finalStart,
                                        end: range?.finalEnd,
                                      };

                                      render();
                                    }}
                                  >
                                    <small>
                                      <strong>
                                        {parseInt(`${attendanceNoteAcc ?? 0}`)}
                                      </strong>
                                    </small>
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  {kpiPastDeptCategoryAliases.map((a) => {
                    const foundCategoryData = kpiDeptData
                      .find((r) => `${r.deptId}` === `${props.deptId}`)
                      ?.times.find(
                        (t) =>
                          t.time &&
                          kpiDeptCacheCategoryTimeEnumFromJSON(t.time) ===
                            KpiDeptCacheCategoryTimeEnum.CAT_TIME_PAST
                      )
                      ?.categories.find(
                        (c) =>
                          c.category &&
                          kpiDeptCacheCategoryEnumFromJSON(c.category) ===
                            a.category
                      )?.dates;

                    const style = {
                      border: `1px solid grey`,
                      borderLeft: `3px solid black`,
                      borderRight: `3px solid black`,
                      // backgroundColor: kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                      // KpiDeptCacheCategoryEnum.CATEG_OVERDUE && foundCategoryData.find,
                      borderBottom:
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_AVAIL_MP ||
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_COST_PROB ||
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_WO_HISTORY ||
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_OVERDUE
                          ? `3px solid black`
                          : `1px solid grey`,
                      borderTop: 0,
                    };

                    return (
                      <>
                        <tr>
                          <th className={`m-0 p-0`} style={style}>
                            <small>
                              <strong>{a.alias}</strong>
                            </small>
                          </th>
                          {foundCategoryData?.map((d) => {
                            const range = kpiDeptCacheCategoryRangeEnumFromJSON(
                              d.range
                            );
                            const categ = kpiDeptCacheCategoryEnumFromJSON(
                              a.category
                            );
                            return (
                              <>
                                <td
                                  className=" m-0 p-0"
                                  style={{
                                    cursor:
                                      a.category ===
                                        KpiDeptCacheCategoryEnum.CATEG_CAP_PLAN ||
                                      a.category ===
                                        KpiDeptCacheCategoryEnum.CATEG_CAP_ACTUAL
                                        ? `pointer`
                                        : undefined,
                                    ...style,
                                    backgroundColor:
                                      d.numDays > 1 &&
                                      categ ===
                                        KpiDeptCacheCategoryEnum.CATEG_READY_TO_ASSIGN
                                        ? `black`
                                        : ``,
                                    borderLeft: `1px solid grey`,
                                    borderRight: `1px solid grey`,
                                  }}
                                  onClick={() => {
                                    if (
                                      a.category ===
                                        KpiDeptCacheCategoryEnum.CATEG_CAP_PLAN ||
                                      a.category ===
                                        KpiDeptCacheCategoryEnum.CATEG_CAP_ACTUAL
                                    ) {
                                      showWoPopup({
                                        deptId: props.deptId,
                                        range: 0,
                                        customRangeDetail: {
                                          range: 0,
                                          alias: " ",
                                          startDate: d.finalStart,
                                          endDate: d.finalEnd,
                                        },
                                        v2Category: a.category,
                                        v2Range: d.range,
                                        v2TimePastFuture:
                                          KpiDeptCacheCategoryTimeEnum.CAT_TIME_PAST,
                                      });
                                    }
                                  }}
                                >
                                  <small>
                                    <strong>
                                      {a.type === "int"
                                        ? parseInt(`${d.value ?? 0}`)
                                        : isNaN(parseFloat(`${d.value}`))
                                        ? 0.0
                                        : parseFloat(`${d.value}`)?.toFixed(1)}
                                    </strong>
                                  </small>
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
              <div className="flex-grow-1">
                <table
                  className="table table-sm"
                  style={{
                    // borderCollapse: "separate",
                    width: "100%",
                  }}
                >
                  <tr className="m-0 p-0">
                    <th className="bg-dark text-light m-0 p-0">
                      <small>
                        <strong>Plan</strong>
                      </small>
                    </th>
                    {kpiDeptFutureAliases.map((a) => {
                      return (
                        <>
                          <th className="bg-dark text-light m-0 p-0 text-center">
                            <small>
                              <strong>{a.alias}</strong>
                            </small>
                          </th>
                        </>
                      );
                    })}
                  </tr>
                  {kpiFutureDeptCategoryAliases.map((a, i) => {
                    const foundCategoryData = kpiDeptData
                      .find((r) => `${r.deptId}` === `${props.deptId}`)
                      ?.times.find(
                        (t) =>
                          t.time &&
                          kpiDeptCacheCategoryTimeEnumFromJSON(t.time) ===
                            KpiDeptCacheCategoryTimeEnum.CAT_TIME_FUTURE
                      )
                      ?.categories.find(
                        (c) =>
                          c.category &&
                          kpiDeptCacheCategoryEnumFromJSON(c.category) ===
                            a.category
                      )?.dates;

                    const style = {
                      border: `1px solid grey`,

                      // borderTop: i === 0 ? `3px solid black` : `1px solid grey`,
                      borderBottom:
                        i === kpiFutureDeptCategoryAliases.length - 1 ||
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_OVERDUE ||
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_COST_PROB ||
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_WO_OUTS ||
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_EXT_ISSUES ||
                        kpiDeptCacheCategoryEnumFromJSON(a.category) ===
                          KpiDeptCacheCategoryEnum.CATEG_JOB_TOMORROW
                          ? `3px solid black`
                          : `1px solid grey`,
                    };

                    return (
                      <>
                        <tr>
                          <th
                            className="font-weight-bold m-0 p-0"
                            style={{
                              ...style,
                              borderRight: `3px solid black`,
                            }}
                          >
                            <small>
                              <strong>{a.alias}</strong>
                            </small>
                          </th>

                          {a.category ===
                            KpiDeptCacheCategoryEnum.CATEG_LAST_PLAN ||
                          a.category ===
                            KpiDeptCacheCategoryEnum.CATEG_JOB_TODAY ||
                          a.category ===
                            KpiDeptCacheCategoryEnum.CATEG_JOB_TOMORROW ? (
                            <>
                              <td
                                className=" p-0 m-0"
                                colSpan={foundCategoryData?.length ?? 0}
                                style={{
                                  ...style,
                                }}
                              >
                                <div
                                  className="overflow-auto"
                                  style={{ width: "15vw" }}
                                >
                                  {a.category ===
                                  KpiDeptCacheCategoryEnum.CATEG_LAST_PLAN ? (
                                    <>
                                      <small>
                                        <strong>
                                          {
                                            foundCategoryData?.find((d) => d)
                                              ?.stringValue
                                          }
                                        </strong>
                                      </small>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {a.category ===
                                  KpiDeptCacheCategoryEnum.CATEG_JOB_TODAY ? (
                                    <>
                                      <small>
                                        <strong>
                                          {foundKpiDeptCache?.jobToday
                                            .filter((j) => j.name)
                                            .map((j) =>
                                              `${j.name}`
                                                .split(" ")
                                                .map((s) => s.substring(0, 4))
                                                .join(" ")
                                            )
                                            .join(", ")}
                                        </strong>
                                      </small>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {a.category ===
                                  KpiDeptCacheCategoryEnum.CATEG_JOB_TOMORROW ? (
                                    <>
                                      <small>
                                        <strong>
                                          {foundKpiDeptCache?.jobTomorrow
                                            .filter((j) => j.name)
                                            .map((j) =>
                                              `${j.name}`
                                                .split(" ")
                                                .map((s) => s.substring(0, 4))
                                                .join(" ")
                                            )
                                            .join(", ")}
                                        </strong>
                                      </small>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            </>
                          ) : a.category ===
                              KpiDeptCacheCategoryEnum.CATEG_INT_ISSUES ||
                            a.category ===
                              KpiDeptCacheCategoryEnum.CATEG_EXT_ISSUES ? (
                            <>
                              {foundCategoryData?.map((d, i) => {
                                return (
                                  <>
                                    {" "}
                                    <td
                                      className=" m-0 p-0"
                                      style={{ ...style, cursor: "pointer" }}
                                      onClick={async () => {
                                        // alert(
                                        //   `Showing I/E Problem ${d.finalStart}-${d.finalEnd}`
                                        // );
                                        const issueDetail = {
                                          dept: deptWoFilter.deptId,
                                          start: d.finalStart,
                                          end: d.finalEnd,
                                        };

                                        internalExternalIssueDetail.current =
                                          issueDetail;

                                        const dx =
                                          await fetchWorkOrdersProtoMongo({
                                            extUserIds: ctx?.extUsers
                                              .filter(
                                                (u) =>
                                                  `${u.departmentId}` ===
                                                  `${deptWoFilter.deptId}`
                                              )
                                              .map((u) => u.id),
                                            from: d.finalStart,
                                            to: d.finalEnd,
                                          });

                                        if (dx) {
                                          internalExternalIssueDetailWorkOrders.current =
                                            dx;
                                        }

                                        render();
                                        // d.finalStart;
                                      }}
                                    >
                                      <small>
                                        <strong>
                                          {a.category ===
                                          KpiDeptCacheCategoryEnum.CATEG_INT_ISSUES
                                            ? `I`
                                            : ``}
                                          {a.category ===
                                          KpiDeptCacheCategoryEnum.CATEG_EXT_ISSUES
                                            ? `E`
                                            : ``}
                                          {i + 1}:
                                          {parseInt(`${d.value ?? "0"}`)}
                                        </strong>
                                      </small>
                                    </td>{" "}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {foundCategoryData?.map((d) => {
                                return (
                                  <>
                                    <td
                                      className=" m-0 p-0"
                                      style={{
                                        cursor:
                                          a.category ===
                                            KpiDeptCacheCategoryEnum.CATEG_CAP_PLAN ||
                                          a.category ===
                                            KpiDeptCacheCategoryEnum.CATEG_CAP_ACTUAL
                                            ? `pointer`
                                            : undefined,
                                        ...style,
                                      }}
                                      onClick={() => {
                                        if (
                                          a.category ===
                                            KpiDeptCacheCategoryEnum.CATEG_CAP_PLAN ||
                                          a.category ===
                                            KpiDeptCacheCategoryEnum.CATEG_CAP_ACTUAL
                                        ) {
                                          showWoPopup({
                                            deptId: props.deptId,
                                            range: 0,
                                            customRangeDetail: {
                                              range: 0,
                                              alias: " ",
                                              startDate: d.finalStart,
                                              endDate: d.finalEnd,
                                            },
                                            v2Category: a.category,
                                            v2Range: d.range,
                                            v2TimePastFuture:
                                              KpiDeptCacheCategoryTimeEnum.CAT_TIME_FUTURE,
                                          });
                                        }
                                      }}
                                    >
                                      <small>
                                        <strong>
                                          {a.type === "int"
                                            ? parseInt(`${d.value ?? 0}`)
                                            : d.value?.toFixed(1)}
                                        </strong>
                                      </small>
                                    </td>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const QuadPanel = (props: {
    deptId: string | null | undefined;
    deptWoFilterIndex: number;
  }) => {
    return (
      <>
        {deptWoFilters[props.deptWoFilterIndex].woGraph ? (
          <>
            <div className="w-100 h-100">
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data}
                options={options}
              />
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="w-100 h-100">
              {!deptWoFilters[props.deptWoFilterIndex]?.workOrderType ? (
                <>
                  {" "}
                  <>
                    <div className="overflow-auto">
                      {debug ? (
                        <>
                          <small>
                            <pre>
                              {JSON.stringify(
                                {
                                  range: 0,
                                  dept: ctx?.extDepartments.find(
                                    (d) => `${d.id}` === `${props.deptId}`
                                  )?.name,
                                  users: kpiAttendanceRanges
                                    .find((r) => r.range === 0)
                                    ?.kpi.users.filter(
                                      (u) =>
                                        `${
                                          ctx?.extUsers.find(
                                            (ux) =>
                                              `${ux.id}` ===
                                              `${u.user?.gormModel?.id}`
                                          )?.departmentId
                                        }` === `${props.deptId}`
                                    )
                                    .map((u) => ({
                                      username: u.user?.username,
                                      attendance: u.attendance?.checkIns,
                                    })),
                                  rangeData: kpiAttendanceRanges.find(
                                    (r) => r.range === 0
                                  ),
                                },
                                null,
                                2
                              )}
                            </pre>
                          </small>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <table
                            className="table table-sm"
                            style={{
                              borderCollapse: "separate",
                              width: "100%",
                            }}
                          >
                            <tr className="m-0 p-0">
                              <th
                                className="border border-dark bg-dark text-light m-0 p-0"
                                style={{ position: "sticky", top: 0 }}
                              >
                                <strong>
                                  {(() => {
                                    const foundDept = ctx?.extDepartments.find(
                                      (d) => `${d.id}` === `${props.deptId}`
                                    );

                                    if (!foundDept) {
                                      return `No dept found: ${props.deptId}`;
                                    }

                                    return `${foundDept.name
                                      ?.split(" ")
                                      .map((s) => s.substring(0, 5))
                                      .join(" ")}:${
                                      ctx?.extUsers.filter(
                                        (u) =>
                                          `${u.departmentId}` ===
                                          `${foundDept.id}`
                                      )?.length
                                    } pers.`;
                                  })()}
                                </strong>
                              </th>
                              {kpiRangesNegativeMonth.map((n) => (
                                <>
                                  <th
                                    className="m-0 p-0 border border-dark bg-dark text-light"
                                    style={{ position: "sticky", top: 0 }}
                                  >
                                    <small>
                                      <strong>
                                        {
                                          aliases.find((a) => a.range === n)
                                            ?.alias
                                        }
                                      </strong>
                                    </small>
                                  </th>
                                </>
                              ))}
                            </tr>
                            {[
                              ...new Set(
                                attendanceNoteTypes.map(
                                  (t) => t.uniqIdentifier ?? 1
                                )
                              ),
                            ]
                              .map((uid) =>
                                attendanceNoteTypes.find(
                                  (t) => t.uniqIdentifier === uid
                                )
                              )
                              .filter((t) => t)
                              .map((t) => t as AttendanceNoteType)
                              .map((t) => {
                                const typesWithUniqId =
                                  attendanceNoteTypes.filter(
                                    (tx) =>
                                      tx.uniqIdentifier === t.uniqIdentifier
                                  );
                                const filteredAttendanceNotes =
                                  attendanceNotes.filter(
                                    (a) =>
                                      typesWithUniqId.find(
                                        (t) => t.id === a.attendanceNoteType?.id
                                      ) &&
                                      (props.deptId
                                        ? `${
                                            ctx?.extUsers.find(
                                              (u) =>
                                                `${u.id}` === `${a.extUserId}`
                                            )?.departmentId
                                          }` === `${props.deptId}`
                                        : false)
                                  );

                                const calculateColorAttendance = (params: {
                                  personnel: number;
                                  days: number;
                                  note: number;
                                }) =>
                                  chroma
                                    .scale(["white", "yellow", "lightgreen"])(
                                      params.note /
                                        (params.personnel * params.days)
                                    )
                                    .hex();

                                const todayNotes =
                                  filteredAttendanceNotes.filter(
                                    (a) =>
                                      a.date?.split("T")?.[0] ===
                                      makeDateString(new Date())
                                  );
                                const yesterdayNotes =
                                  filteredAttendanceNotes.filter(
                                    (a) =>
                                      (a.date?.split("T")?.[0] ?? "") >=
                                      makeDateString(
                                        new Date(
                                          new Date().getTime() - 8640000 * 1
                                        )
                                      )
                                  );

                                const last7dNotes =
                                  filteredAttendanceNotes.filter(
                                    (a) =>
                                      (a.date?.split("T")?.[0] ?? "") >=
                                      makeDateString(
                                        new Date(
                                          new Date().getTime() - 8640000 * 7
                                        )
                                      )
                                  );

                                const last28dNotes =
                                  filteredAttendanceNotes.filter(
                                    (a) =>
                                      (a.date?.split("T")?.[0] ?? "") >=
                                      makeDateString(
                                        new Date(
                                          new Date().getTime() - 8640000 * 28
                                        )
                                      )
                                  );
                                const last84dNotes =
                                  filteredAttendanceNotes.filter(
                                    (a) =>
                                      (a.date?.split("T")?.[0] ?? "") >=
                                      makeDateString(
                                        new Date(
                                          new Date().getTime() - 8640000 * 84
                                        )
                                      )
                                  );

                                const last365dNotes =
                                  filteredAttendanceNotes.filter(
                                    (a) =>
                                      (a.date?.split("T")?.[0] ?? "") >=
                                      makeDateString(
                                        new Date(
                                          new Date().getTime() - 8640000 * 365
                                        )
                                      )
                                  );
                                const deptPersonnel =
                                  ctx?.extUsers.filter(
                                    (u) =>
                                      `${u.departmentId}` === `${props.deptId}`
                                  ).length ?? 0;
                                return (
                                  <>
                                    <tr className="p-0 m-0">
                                      <td className="border border-dark m-0 p-0">
                                        <small>
                                          {t.alias && t.alias !== ""
                                            ? t.alias
                                            : t.name}

                                          {typesWithUniqId.length > 1 ? (
                                            <>({typesWithUniqId.length})</>
                                          ) : (
                                            <></>
                                          )}
                                        </small>
                                      </td>
                                      <td
                                        className="border border-dark m-0 p-0"
                                        style={{
                                          backgroundColor:
                                            calculateColorAttendance({
                                              personnel: deptPersonnel,
                                              days: 1,
                                              note: todayNotes.length,
                                            }),
                                        }}
                                      >
                                        {" "}
                                        <small>
                                          <strong>{todayNotes.length}</strong>
                                        </small>{" "}
                                      </td>
                                      <td
                                        className="border border-dark m-0 p-0"
                                        style={{
                                          backgroundColor:
                                            calculateColorAttendance({
                                              personnel: deptPersonnel,
                                              days: 2,
                                              note: yesterdayNotes.length,
                                            }),
                                        }}
                                      >
                                        <small>
                                          <strong>
                                            {yesterdayNotes.length}
                                          </strong>
                                        </small>{" "}
                                      </td>
                                      <td
                                        className="border border-dark m-0 p-0"
                                        style={{
                                          backgroundColor:
                                            calculateColorAttendance({
                                              personnel: deptPersonnel,
                                              days: 7,
                                              note: last7dNotes.length,
                                            }),
                                        }}
                                      >
                                        <small>
                                          <strong>{last7dNotes.length}</strong>
                                        </small>{" "}
                                      </td>
                                      <td
                                        className="border border-dark m-0 p-0"
                                        style={{
                                          backgroundColor:
                                            calculateColorAttendance({
                                              personnel: deptPersonnel,
                                              days: 28,
                                              note: last28dNotes.length,
                                            }),
                                        }}
                                      >
                                        <small>
                                          <strong>{last28dNotes.length}</strong>
                                        </small>{" "}
                                      </td>
                                      <td
                                        className="border border-dark m-0 p-0"
                                        style={{
                                          backgroundColor:
                                            calculateColorAttendance({
                                              personnel: deptPersonnel,
                                              days: 84,
                                              note: last84dNotes.length,
                                            }),
                                        }}
                                      >
                                        <small>
                                          <strong>{last84dNotes.length}</strong>
                                        </small>{" "}
                                      </td>
                                      {/* <td className="border border-dark m-0 p-0 bg-dark"></td>
                                  <td className="border border-dark m-0 p-0 bg-dark"></td> */}

                                      {/* <td
                      className="border border-dark m-0 p-0"
                      style={{
                        backgroundColor: calculateColorAttendance({
                          personnel: deptPersonnel,
                          days: 365,
                          note: last365dNotes.length,
                        }),
                      }}
                    >
                      <small>
                        <strong>{last365dNotes.length}</strong>
                      </small>{" "}
                    </td> */}
                                    </tr>
                                  </>
                                );
                              })}

                            {/* MP Assist */}
                            <tr className="m-0 p-0">
                              <td
                                className="border border-dark m-0 p-0"
                                // colSpan={kpiRangesPositiveMonth.length}
                              >
                                <small>
                                  <strong>MP Assist</strong>
                                </small>
                              </td>
                              {kpiRangesNegativeMonth.map((r) => {
                                const deptUsers = ctx?.extUsers.filter(
                                  (u) =>
                                    `${u.departmentId}` === `${props.deptId}`
                                );
                                const assistances = mpAssistances.assistances
                                  .filter(
                                    (a) =>
                                      deptUsers?.find(
                                        (u) => `${u.id}` === `${a.extUserId}`
                                      ) ||
                                      `${a.extDeptToId}` === `${props.deptId}`
                                  )
                                  .reduce((acc, a) => {
                                    const deptFrom = deptUsers?.find(
                                      (u) => `${u.id}` === `${a.extUserId}`
                                    );

                                    const deptTo =
                                      `${a.extDeptToId}` === `${props.deptId}`;

                                    if (deptFrom) {
                                      return acc - 1;
                                    } else if (deptTo) {
                                      return acc + 1;
                                    } else {
                                      return acc;
                                    }
                                  }, 0);

                                return (
                                  <td
                                    className={`m-0 p-0 border border-dark ${
                                      r === 0 ? `bg-light` : `bg-dark`
                                    }`}
                                  >
                                    <small>
                                      <strong>{assistances} </strong>
                                    </small>
                                  </td>
                                );
                              })}
                            </tr>

                            {/* Attendance */}

                            {/* Alpha */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0 bg-danger text-light">
                                <small>
                                  <strong>Alpha</strong>
                                </small>
                              </td>
                              {kpiRangesNegativeMonth.map((r) => {
                                const data = extractAttendanceKpiMonthData(
                                  ctx,
                                  kpiAttendanceRanges,
                                  r,
                                  props.deptId
                                );
                                const deptUsers = ctx?.extUsers.filter(
                                  (u) =>
                                    `${u.departmentId}` === `${props.deptId}`
                                );
                                return (
                                  <>
                                    <td
                                      className={`m-0 p-0 border border-dark ${
                                        r > 0 ? `bg-dark` : ``
                                      }`}
                                    >
                                      {data.foundAttendanceRangeKpi && r > 0 ? (
                                        <>
                                          {" "}
                                          <div className="spinner-border spinner-border-sm"></div>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <small>
                                            <strong>
                                              {(
                                                (deptUsers?.length ?? 0) -
                                                (data.attendanceKpi ?? 0) /
                                                  (Math.abs(r) + 1)
                                              )?.toFixed(1)}
                                            </strong>
                                          </small>
                                        </>
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>

                            {/* Available Manpower */}
                            <tr className="m-0 p-0">
                              <td
                                className="border border-dark m-0 p-0"
                                // colSpan={kpiRangesPositiveMonth.length}
                              >
                                <small>
                                  <strong>Avail MP</strong>
                                </small>
                              </td>
                              {kpiRangesNegativeMonth.map((r) => {
                                const data = extractAttendanceKpiMonthData(
                                  ctx,
                                  kpiAttendanceRanges,
                                  r,
                                  props.deptId
                                );
                                return (
                                  <>
                                    <td
                                      className={`m-0 p-0 border border-dark ${
                                        r > 0 ? `bg-dark` : ``
                                      }`}
                                    >
                                      {data.foundAttendanceRangeKpi && r > 0 ? (
                                        <>
                                          {" "}
                                          <div className="spinner-border spinner-border-sm"></div>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <small>
                                            <strong>
                                              {(
                                                (data.attendanceKpi ?? 0) /
                                                (Math.abs(r) + 1)
                                              )?.toFixed(1)}
                                            </strong>
                                          </small>
                                        </>
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>

                            {/* WO History */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0">
                                <small>
                                  <strong>WO History</strong>
                                </small>
                              </td>
                              {kpiRangesNegativeMonth.map((r) => {
                                const foundKpi = kpiRangeData.find(
                                  (k) => `${k.range}` === `${r}`
                                );

                                const kpiUsers = foundKpi?.kpi.users.filter(
                                  (u) =>
                                    `${
                                      ctx?.extUsers.find(
                                        (ux) =>
                                          `${ux.id}` ===
                                          `${u.user?.gormModel?.id}`
                                      )?.departmentId
                                    }` === `${props.deptId}`
                                );

                                const kpiUsersTotalWos =
                                  kpiUsers?.reduce(
                                    (acc, k) =>
                                      acc + (k.workOrder?.totalWorkOrders ?? 0),
                                    0
                                  ) ?? 0;

                                return (
                                  <>
                                    <td className="p-0 m-0 border border-dark">
                                      {!foundKpi?.loading ? (
                                        <>
                                          <small>
                                            <strong>
                                              {/* {kpiUsersTotalWos.toFixed(0)} */}
                                              {r === 0
                                                ? foundKpi?.kpi.deptTotalWosArray.find(
                                                    (a) =>
                                                      `${a.deptId}` ===
                                                      `${props.deptId}`
                                                  )?.totalWorkOrders
                                                : foundKpi?.kpi.deptTotalWosArray.find(
                                                    (a) =>
                                                      `${a.deptId}` ===
                                                      `${props.deptId}`
                                                  )?.totalWorkOrdersDone}
                                            </strong>
                                          </small>
                                        </>
                                      ) : (
                                        <>
                                          <div className="spinner-border spinner-border-sm"></div>
                                        </>
                                      )}
                                      {/* <div className="spinner-border spinner-border-sm"></div> */}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>

                            {/* Capacity actual */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0 bg-dark text-light">
                                <small>
                                  <strong>Cap. actual</strong>
                                </small>
                              </td>
                              {kpiRangesNegativeMonth.map((r) => {
                                return (
                                  <CapacityData
                                    r={r}
                                    deptId={props.deptId}
                                    type="Actual"
                                  />
                                );
                              })}
                            </tr>

                            {/* Capacity Available Manpower */}
                            {/* <tr className="m-0 p-0">
                          <td className="border border-dark m-0 p-0 bg-dark text-light">
                            <small>
                              <strong>Cap Avail MP</strong>
                            </small>
                          </td>
                          {kpiRangesNegativeMonth.map((r) => {
                            const data = extractAttendanceKpiMonthData(
                              ctx,
                              kpiAttendanceRanges,
                              r,
                              props.deptId
                            );

                            return (
                              <>
                                <td
                                  className={`m-0 p-0 border border-dark ${
                                    r > 0 ? `bg-dark` : ``
                                  }`}
                                >
                                  {r > 0 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {" "}
                                      <small>
                                        <strong>{data.hrs.toFixed(1)}</strong>
                                      </small>
                                    </>
                                  )}
                                </td>
                              </>
                            );
                          })}
                        </tr> */}

                            {/* Ontime */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0 bg-dark text-light">
                                <small>
                                  <strong>Overdue</strong>
                                </small>
                              </td>
                              {kpiRangesNegativeMonth.map((r) => {
                                return (
                                  <OntimeData r={r} deptId={props.deptId} />
                                );
                              })}
                            </tr>
                            {/* Quality */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0 bg-dark text-light">
                                <small>
                                  <strong>Quality</strong>
                                </small>
                              </td>
                              {kpiRangesNegativeMonth.map((r) => {
                                return (
                                  <QualityData
                                    r={r}
                                    deptId={props.deptId}
                                    type="Quality"
                                  />
                                );
                              })}
                            </tr>
                            {/* Cost */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0 bg-dark text-light">
                                <small>
                                  <strong>Cost</strong>
                                </small>
                              </td>
                              {kpiRangesNegativeMonth.map((r) => {
                                return (
                                  <QualityData
                                    r={r}
                                    deptId={props.deptId}
                                    type="Cost"
                                  />
                                );
                              })}
                            </tr>
                          </table>
                        </div>
                        <div className="flex-grow-1">
                          <table
                            className="table table-sm"
                            style={{
                              width: "100%",
                              borderCollapse: "separate",
                            }}
                          >
                            <tr className="m-0 p-0">
                              {[
                                `KPI`,
                                ...kpiRangesPositiveMonth.map(
                                  (p) =>
                                    `${
                                      aliases.find((a) => a.range === p)?.alias
                                    }`
                                ),
                              ].map((h) => (
                                <>
                                  <th className="m-0 p-0 bg-dark text-light border border-dark">
                                    <small>
                                      <strong>{h}</strong>
                                    </small>
                                  </th>
                                </>
                              ))}{" "}
                            </tr>
                            {/* Capacity plan */}
                            <tr className="m-0 p-0">
                              <td className="m-0 p-0 bg-dark text-light">
                                <small>
                                  <strong>Cap. Plan</strong>
                                </small>{" "}
                              </td>
                              {kpiRangesPositiveMonth.map((r) => {
                                return (
                                  <CapacityData
                                    r={r}
                                    deptId={props.deptId}
                                    type="Plan"
                                  />
                                );
                              })}
                            </tr>

                            {/* Capacity Available Manpower */}
                            {/* <tr className="m-0 p-0">
                          <td className="border border-dark m-0 p-0 bg-dark text-light">
                            <small>
                              <strong>Cap Avail MP</strong>
                            </small>
                          </td>
                          {kpiRangesPositiveMonth.map((r) => {
                            const data = extractAttendanceKpiMonthData(
                              ctx,
                              kpiAttendanceRanges,
                              r,
                              props.deptId
                            );

                            return (
                              <>
                                <td
                                  className={`m-0 p-0 border border-dark ${
                                    r > 0 ? `bg-dark` : ``
                                  }`}
                                >
                                  {r > 0 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {" "}
                                      <small>
                                        <strong>{data.hrs.toFixed(1)}</strong>
                                      </small>
                                    </>
                                  )}
                                </td>
                              </>
                            );
                          })}
                        </tr> */}

                            {/* Ontime  */}
                            <tr className="m-0 p-0">
                              <td className="m-0 p-0 bg-dark text-light">
                                <small>
                                  <strong>Overdue</strong>
                                </small>
                              </td>
                              {kpiRangesPositiveMonth.map((r) => {
                                return (
                                  <OntimeData r={r} deptId={props.deptId} />
                                );
                              })}
                            </tr>

                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0 bg-dark text-light">
                                <small>
                                  <strong>Quality</strong>
                                </small>
                              </td>
                              {kpiRangesPositiveMonth.map((r) => {
                                return (
                                  <QualityData
                                    r={r}
                                    deptId={props.deptId}
                                    type="Quality"
                                  />
                                );
                              })}
                            </tr>
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0 bg-dark text-light">
                                <small>
                                  <strong>Cost</strong>
                                </small>
                              </td>
                              {kpiRangesPositiveMonth.map((r) => {
                                return (
                                  <QualityData
                                    r={r}
                                    deptId={props.deptId}
                                    type="Cost"
                                  />
                                );
                              })}
                            </tr>

                            {/* Job Outstanding */}
                            <tr className="m-0 p-0">
                              <td
                                className="border border-dark m-0 p-0"
                                // colSpan={kpiRangesPositiveMonth.length}
                              >
                                <small>
                                  <strong>J. Outs</strong>
                                </small>
                              </td>
                              {kpiRangesPositiveMonth.map((r) => {
                                return (
                                  <td className={`m-0 p-0 border border-dark`}>
                                    <small>0</small>
                                  </td>
                                );
                              })}
                            </tr>

                            {/* WO Outstanding */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0">
                                <small>
                                  <strong>WO Out</strong>
                                </small>
                              </td>
                              {kpiRangesPositiveMonth.map((r) => {
                                // If 0 (today), select outstanding LQ
                                const foundKpi = kpiRangeData.find(
                                  (k) => `${k.range}` === `${r === 0 ? -84 : r}`
                                );

                                const kpiUsers = foundKpi?.kpi.users.filter(
                                  (u) =>
                                    `${
                                      ctx?.extUsers.find(
                                        (ux) =>
                                          `${ux.id}` ===
                                          `${u.user?.gormModel?.id}`
                                      )?.departmentId
                                    }` === `${props.deptId}`
                                );

                                const kpiUsersTotalWos =
                                  kpiUsers?.reduce(
                                    (acc, k) =>
                                      acc + (k.workOrder?.totalWorkOrders ?? 0),
                                    0
                                  ) ?? 0;
                                const kpiUsersCompleted =
                                  kpiUsers?.reduce(
                                    (acc, k) =>
                                      acc + (k.workOrder?.completed ?? 0),
                                    0
                                  ) ?? 0;

                                const foundKpiDeptArray =
                                  foundKpi?.kpi.deptTotalWosArray.find(
                                    (a) => `${a.deptId}` === `${props.deptId}`
                                  );
                                return (
                                  <>
                                    <td className="p-0 m-0 border border-dark">
                                      {!foundKpi?.loading ? (
                                        <>
                                          <small>
                                            <strong>
                                              {/* {(
                                            kpiUsersTotalWos - kpiUsersCompleted
                                          ).toFixed(0)} */}
                                              {(foundKpiDeptArray?.totalWorkOrders ??
                                                0) -
                                                (foundKpiDeptArray?.totalWorkOrdersDone ??
                                                  0)}
                                            </strong>
                                          </small>
                                        </>
                                      ) : (
                                        <>
                                          <div className="spinner-border spinner-border-sm"></div>
                                        </>
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>

                            {/* Available Manpower */}
                            {/* <tr className="m-0 p-0">
                          <td
                            className="border border-dark m-0 p-0"
                            // colSpan={kpiRangesPositiveMonth.length}
                          >
                            <small>
                              <strong>Avail MP</strong>
                            </small>
                          </td>
                          {kpiRangesPositiveMonth.map((r) => {
                            const data = extractAttendanceKpiMonthData(
                              ctx,
                              kpiAttendanceRanges,
                              r,
                              props.deptId
                            );
                            return (
                              <>
                                <td
                                  className={`m-0 p-0 border border-dark ${
                                    r > 0 ? `bg-dark` : ``
                                  }`}
                                >
                                  {data.foundAttendanceRangeKpi && r > 0 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {" "}
                                      <small>
                                        <strong>{data.attendanceKpi}</strong>
                                      </small>
                                    </>
                                  )}
                                </td>
                              </>
                            );
                          })}
                        </tr> */}

                            {/* Int issues */}
                            <tr className="m-0 p-0">
                              <td
                                className="border border-dark m-0 p-0 bg-warning"
                                // colSpan={kpiRangesPositiveMonth.length}
                              >
                                <small>
                                  <strong>Int Issues</strong>
                                </small>
                              </td>
                              {kpiRangesPositiveMonth.map((r, i) => {
                                const foundProblemCatalog =
                                  problemCatalogs.catalogs.find(
                                    (c) =>
                                      `${c.extDepartmentId}` ===
                                        `${props.deptId}` &&
                                      `${c.problemIntIdentifier}` ===
                                        `${i + 1}` &&
                                      c.problemType &&
                                      meetingProblemTypeFromJSON(
                                        c.problemType
                                      ) ===
                                        MeetingProblemType.PROBLEM_TYPE_INTERNAL
                                  );

                                return (
                                  <>
                                    <td
                                      className={`border border-dark m-0 p-0 ${
                                        foundProblemCatalog ? `` : `bg-warning`
                                      }`}
                                    >
                                      <small>
                                        <strong>
                                          I{i + 1}-
                                          {kpiRangeData
                                            .find((rx) => rx.range === r)
                                            ?.kpi.problemCatalogs.find(
                                              (c) =>
                                                `${c.identifier}` ===
                                                `${foundProblemCatalog?.masterJavaBaseModel?.id}`
                                            )?.numberOfProblems ?? 0}
                                        </strong>
                                      </small>
                                    </td>
                                  </>
                                );
                              })}
                            </tr>

                            {/* Ext issues */}
                            <tr className="m-0 p-0">
                              <td
                                className="border border-dark m-0 p-0 bg-warning"
                                // colSpan={kpiRangesPositiveMonth.length}
                              >
                                <small>
                                  <strong>Ext Issues</strong>
                                </small>
                              </td>
                              {kpiRangesPositiveMonth.map((r, i) => {
                                const externalValue = 0;

                                const foundProblemCatalog =
                                  problemCatalogs.catalogs.find(
                                    (c) =>
                                      `${c.extDepartmentId}` ===
                                        `${props.deptId}` &&
                                      `${c.problemIntIdentifier}` ===
                                        `${i + 1}` &&
                                      c.problemType &&
                                      meetingProblemTypeFromJSON(
                                        c.problemType
                                      ) ===
                                        MeetingProblemType.PROBLEM_TYPE_EXTERNAL
                                  );

                                return (
                                  <>
                                    <td
                                      className={`border border-dark m-0 p-0 ${
                                        foundProblemCatalog ? `` : `bg-warning`
                                      }`}
                                    >
                                      <small>
                                        <strong>
                                          E{i + 1}-
                                          {kpiRangeData
                                            .find((rx) => rx.range === r)
                                            ?.kpi.problemCatalogs.find(
                                              (c) =>
                                                `${c.identifier}` ===
                                                `${foundProblemCatalog?.masterJavaBaseModel?.id}`
                                            )?.numberOfProblems ?? 0}
                                        </strong>
                                      </small>
                                    </td>
                                  </>
                                );
                              })}
                            </tr>

                            {/* Last planned date */}
                            <tr className="m-0 p-0">
                              <td
                                className="border border-dark m-0 p-0"
                                // colSpan={kpiRangesPositiveMonth.length}
                              >
                                <small>
                                  <strong>L.Plan</strong>
                                </small>
                              </td>
                              <td
                                className="border border-dark p-0 m-0"
                                style={{ cursor: "pointer" }}
                                colSpan={kpiRangesPositiveMonth.length}
                                onClick={() => {
                                  if (props.deptId) {
                                    setDeptModalLastPlanShowId(props.deptId);
                                  }
                                }}
                              >
                                {(() => {
                                  const foundPlan = extDepartmentLastPlans.find(
                                    (p) =>
                                      `${p.extDepartmentId}` ===
                                      `${props.deptId}`
                                  );

                                  if (!foundPlan) {
                                    return (
                                      <>
                                        {" "}
                                        <div className="spinner-border spinner-border-sm"></div>
                                      </>
                                    );
                                  }

                                  return (
                                    <>
                                      <div>
                                        {foundPlan?.lastPlan ? (
                                          <>
                                            <small>
                                              <strong>
                                                {(() => {
                                                  try {
                                                    return Intl.DateTimeFormat(
                                                      navigator.language ??
                                                        "id-ID",
                                                      {
                                                        dateStyle: "long",
                                                      } as any
                                                    ).format(
                                                      new Date(
                                                        foundPlan?.lastPlan
                                                          .date ?? ""
                                                      )
                                                    );
                                                  } catch (e) {
                                                    return "No date.";
                                                  }
                                                })()}
                                              </strong>
                                            </small>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <div className="spinner-border spinner-border-sm"></div>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  );
                                })()}
                                {/* <>
                              depid{" "}
                              {deptWoFilters[props.deptWoFilterIndex]?.deptId}
                            </> */}
                              </td>
                            </tr>

                            {/* Job today */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0">
                                <small>
                                  <strong>J.Today</strong>
                                </small>
                              </td>
                              <td
                                className="border border-dark m-0 p-0"
                                colSpan={kpiRangesPositiveMonth.length}
                                style={{ width: "15vw" }}
                              >
                                <div style={{ width: "15vw" }}>
                                  <small>
                                    {[
                                      ...new Set(
                                        deptDayWos
                                          .find(
                                            (dx) =>
                                              `${dx.deptId}` ===
                                              `${props.deptId}`
                                          )
                                          ?.today.taskLists.map(
                                            (tL) =>
                                              tL.taskList?.extJobId as string
                                          )
                                          .filter((j) => j)
                                      ),
                                    ]
                                      .map(
                                        (id) =>
                                          jobsProto?.jobs
                                            .find(
                                              (j) =>
                                                `${j.masterJavaBaseModel?.id}` ===
                                                `${id}`
                                            )
                                            ?.name?.split(" ")
                                            .map((s) => s.substring(0, 4))
                                            .join(" ") ?? "No name"
                                      )
                                      .join(", ")}
                                  </small>
                                </div>
                              </td>
                            </tr>

                            {/* Job tomorrow */}
                            <tr className="m-0 p-0">
                              <td className="border border-dark m-0 p-0">
                                <small>
                                  <strong>J.Tmrw</strong>
                                </small>
                              </td>
                              <td
                                className="border border-dark m-0 p-0"
                                colSpan={kpiRangesPositiveMonth.length}
                                style={{ width: "15vw" }}
                              >
                                <div style={{ width: "15vw" }}>
                                  <small>
                                    {[
                                      ...new Set(
                                        deptDayWos
                                          .find(
                                            (dx) =>
                                              `${dx.deptId}` ===
                                              `${props.deptId}`
                                          )
                                          ?.tomorrow.taskLists.map(
                                            (tL) =>
                                              tL.taskList?.extJobId as string
                                          )
                                          .filter((j) => j)
                                      ),
                                    ]
                                      .map(
                                        (id) =>
                                          jobsProto?.jobs
                                            .find(
                                              (j) =>
                                                `${j.masterJavaBaseModel?.id}` ===
                                                `${id}`
                                            )
                                            ?.name?.split(" ")
                                            .map((s) => s.substring(0, 4))
                                            .join(" ") ?? "No name"
                                      )
                                      .join(", ")}
                                  </small>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                </>
              ) : (
                (() => {
                  switch (
                    workOrderTypeFromJSON(
                      deptWoFilters[props.deptWoFilterIndex]?.workOrderType
                    )
                  ) {
                    case WorkOrderType.WO_TYPE_SALES_FUNNEL:
                      return (
                        <>
                          <div className="overflow-auto w-100 h-100">
                            <iframe
                              className="w-100 h-100"
                              src={`${process.env.REACT_APP_CRM_FRONTEND_URL}/#/opportunity-funnel-by-stage`}
                            />
                          </div>
                        </>
                      );

                    case WorkOrderType.WO_TYPE_SALES_PLAN:
                      return (
                        <>
                          <div className="overflow-auto w-100 h-100">
                            <iframe
                              className="w-100 h-100"
                              src={`${process.env.REACT_APP_CRM_FRONTEND_URL}/#/sales-plan-vs-actual`}
                            />
                          </div>
                        </>
                      );
                    case WorkOrderType.WO_TYPE_PRE_SALES:
                      return (
                        <>
                          <div className="overflow-auto w-100 h-100">
                            <iframe
                              className="w-100 h-100"
                              src={`${process.env.REACT_APP_CRM_FRONTEND_URL}/#/pre-sales`}
                            />
                          </div>
                        </>
                      );
                    case WorkOrderType.WO_TYPE_SALES_TARGET_VS_ACTUAL:
                      return (
                        <>
                          <div className="overflow-auto w-100 h-100">
                            <iframe
                              className="w-100 h-100"
                              src={`${process.env.REACT_APP_CRM_FRONTEND_URL}/#/target-sales-vs-actual`}
                            />
                          </div>
                        </>
                      );
                    default:
                      return <></>;
                  }
                })()
              )}
            </div>
          </>
        )}
      </>
    );
  };

  // On dept wo filters
  useEffect(() => {
    refetch();
  }, [deptWoFilters]);

  const [kpiAttendanceRanges, setKpiAttendanceRanges] = useState<
    { range: KpiRange; kpi: MeetingKpi }[]
  >([]);

  // Always get attendacne for today
  const fetchAttendanceTodayData = async () => {
    kpiRangesMonth.forEach(async (r) => {
      const attendanceData = await fetchAttendanceKpiData({
        range: r,
      });
      setKpiAttendanceRanges((cs) => [
        ...cs,
        {
          range: r,
          kpi: attendanceData,
        },
      ]);
    });
  };

  const refetch = async () => {
    setWoJobs(deptWoFilters.map((_) => null));

    if (deptWoFilters.length > 0) {
      // load woJobs
      deptWoFilters.forEach(async (deptWoFilter, i) => {
        console.log(`[DEPT WO FILTER] ${i}`, deptWoFilter);

        // Set attendance
        const woDate =
          !deptWoFilter.weeks && !deptWoFilter.expand
            ? new Date(
                new Date().getTime() + 86400000 * (deptWoFilter.skipDate ?? 0)
              )
            : new Date();

        // if (woDate) {
        if (
          !checkIns.find((c) => `${c.date}` === `${makeDateString(woDate)}`)
        ) {
          (async () => {
            const attendanceData = await fetchAttendanceData({
              date: woDate,
            });
            setCheckIns((cs) => [
              ...cs,
              {
                date: makeDateString(woDate),
                checkIn: attendanceData,
              },
            ]);
          })();
        }
        // }

        let fromDate = makeDateString(
          new Date(
            new Date().getTime() + 86400000 * (deptWoFilter.skipDate ?? 0)
          )
        );
        let toDate = deptWoFilter.weeks
          ? makeDateString(
              new Date(
                new Date(
                  new Date().getTime() +
                    86400000 * skipDateInterval * deptWoFilter.weeks
                ).getTime() +
                  86400000 * (deptWoFilter.skipDate ?? 0)
              )
            )
          : makeDateString(
              new Date(
                new Date().getTime() + 86400000 * (deptWoFilter.skipDate ?? 0)
              )
            );

        if (
          deptWoFilter.workOrderType &&
          workOrderTypeToJSON(deptWoFilter.workOrderType) ===
            workOrderTypeToJSON(WorkOrderType.WO_TYPE_GENERIC_WEEKLY)
        ) {
          const date = new Date();
          const dayOfWeek = date.getDay(); // Sunday is 0, Monday is 1, and so on
          const startOfWeek = new Date(date);
          const endOfWeek = new Date(date);

          // Adjust to the first day of the week (Sunday)
          startOfWeek.setDate(date.getDate() - dayOfWeek);

          // Adjust to the last day of the week (Saturday)
          endOfWeek.setDate(date.getDate() + (6 - dayOfWeek));

          fromDate = makeDateString(startOfWeek);
          toDate = makeDateString(endOfWeek);

          weeklyFromDate.current = fromDate;
          weeklyToDate.current = toDate;
          render();
        }

        const fetchedWos = await fetchWorkOrdersProtoMongo({
          // extJobIds:
          //   deptWoFilter.workOrderType &&
          //   workOrderTypeToJSON(deptWoFilter.workOrderType) ===
          //     workOrderTypeToJSON(WorkOrderType.WO_TYPE_JOB)
          //     ? jobsProto?.jobs
          //         .map((j) => `${j.masterJavaBaseModel?.id}`)
          //         .filter((j) => j)
          //     : undefined,
          // add skip date
          from: fromDate,
          // add skip date
          to: toDate,
          extUserIds: deptWoFilter.deptId
            ? ctx?.extUsers
                .filter((u) => `${u.departmentId}` === `${deptWoFilter.deptId}`)
                .map((u) => `${u.id}`)
            : deptWoFilter.extUserId
            ? [deptWoFilter.extUserId]
            : undefined,
          extJobId: deptWoFilter.jobId,
          picOnly: deptWoFilter.picOnly,
        });

        setWoJobs((woJobs) =>
          woJobs.map((j, ix) => (ix === i ? fetchedWos : j))
        );

        // Get OSRM coords only if deptWoFilter single

        if (deptWoFilters.length <= 1) {
          (async () => {
            console.log("get osrm coordinate");
            // Get OSRM coords
            handleGetOsrmCoordsPlan([fetchedWos]);
          })();

          (async () => {
            console.log("get location record");
            // Get location record for date
            handleGetOsrmCoordsActual(fromDate, toDate, deptWoFilter.extUserId);
          })();
        }
      });
    }
  };

  const handleGetOSRMCoords = async (latLongs: string | null | undefined) => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_OSRM_URL}/route/v1/driving/${latLongs}?steps=true`
      );

      if (resp.status !== 200) throw await resp.text();

      const fetchedOsrmData = await resp.json();
      return fetchedOsrmData as OSRMObject | null | undefined;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const handleGetOsrmCoordsPlan = async (
    woJobs: (MeetingTaskListsView | null)[]
  ) => {
    const latLongs = woJobs
      .map((tL) => tL?.taskLists)
      .flat()
      .map((tL) => tL?.taskList?.meetingTasks)
      .flat()
      .map((mt) => mt?.journeys)
      .flat()
      .filter((j) => j?.lat && j.lon)
      .map((j) => `${j?.lon},${j?.lat}`)
      .join(";");

    setOsrmData(await handleGetOSRMCoords(latLongs));
  };

  const weeklyDates =
    weeklyFromDate.current && weeklyToDate.current
      ? [
          ...Array(
            Math.round(
              (new Date(weeklyToDate.current).getTime() -
                new Date(weeklyFromDate.current).getTime()) /
                86400000
            ) + 1
          ),
        ]
          .map((_, i) => i + 1)
          .map(
            (i) =>
              new Date(
                new Date(weeklyFromDate.current ?? "").getFullYear(),
                new Date(weeklyFromDate.current ?? "").getMonth(),
                new Date(weeklyFromDate.current ?? "").getDate() + i
              )
          )
      : [];

  const handleGetOsrmCoordsActual = async (
    fromDate: string,
    toDate: string,
    extUserId: any
  ) => {
    const locationsDateRangeRaw = await fetchLocationRecordsDateRange({
      apiKey: ctx?.apiKey ?? "",
      from: new Date(`${fromDate}T00:00:00`).toISOString(),
      to: new Date(`${toDate}T23:59:59`).toISOString(),
    });

    setLocationRecords(locationsDateRangeRaw);

    const locationsDateRangeUser = locationsDateRangeRaw.filter(
      (l) =>
        l.lat && l.lon && l.extUserId && `${l.extUserId}` === `${extUserId}`
    );

    const locationsDateRange = locationsDateRangeUser
      .map((l) => `${l.lon},${l.lat}`)
      .join(";");

    // setOsrmDataActual(await handleGetOSRMCoords(locationsDateRange));
  };

  // Auto refresh every 5 mins
  useEffect(() => {
    const elapsedSecs = Math.floor(
      (new Date().getTime() - new Date(startingDate).getTime()) / 1000
    );

    const elapsedAutoRefreshSecs = 300;

    console.log(elapsedSecs, elapsedSecs % elapsedAutoRefreshSecs === 0);

    if (
      elapsedSecs % elapsedAutoRefreshSecs === 0 &&
      deptWoFilters.length > 0 &&
      autoRefresh
    ) {
      refetch();
    }
  }, [snapIntervalRefetch]);

  const fetchJobOverviewData = async () => {
    const j = await fetchJobsDataProtoCompose({
      apiKey: ctx?.apiKey ?? "",
      // closed: filters.jobClosed,
      // individualEntityId: filters.customer,
      // category: filters.category,
      // priority: filters.priority,
    });
    setJobOverview(j);
  };

  const fetchWoFromJob = async () => {
    setWoJobLoading(true);
    setWoJob(
      await fetchWorkOrdersProtoMongo({
        // extJobIds:
        //   deptWoFilter.workOrderType &&
        //   workOrderTypeToJSON(deptWoFilter.workOrderType) ===
        //     workOrderTypeToJSON(WorkOrderType.WO_TYPE_JOB)
        //     ? jobsProto?.jobs
        //         .map((j) => `${j.masterJavaBaseModel?.id}`)
        //         .filter((j) => j)
        //     : undefined,
        // add skip date
        from: makeDateString(
          new Date(
            new Date().getTime() + 86400000 * (deptWoFilter.skipDate ?? 0)
          )
        ),
        // add skip date
        to: deptWoFilter.weeks
          ? makeDateString(
              new Date(
                new Date(
                  new Date().getTime() +
                    86400000 * skipDateInterval * deptWoFilter.weeks
                ).getTime() +
                  86400000 * (deptWoFilter.skipDate ?? 0)
              )
            )
          : makeDateString(
              new Date(
                new Date().getTime() + 86400000 * (deptWoFilter.skipDate ?? 0)
              )
            ),
        extUserIds: deptWoFilter.deptId
          ? ctx?.extUsers
              .filter((u) => `${u.departmentId}` === `${deptWoFilter.deptId}`)
              .map((u) => `${u.id}`)
          : deptWoFilter.extUserId
          ? [deptWoFilter.extUserId]
          : undefined,
        extJobId: deptWoFilter.jobId,
        picOnly: deptWoFilter.picOnly,
      })
    );
    setWoJobLoading(false);
  };

  // const fetchJobs = async () => {
  //   setLoading(true);
  //   setJobs(await fetchJobsDeptProgress({ apiKey: ctx?.apiKey ?? "" }));
  //   setLoading(false);
  // };

  const fetchMachinesData = async () => {
    setMachines(await fetchMachines({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchMachineProgramsData = async () => {
    setMachinePrograms(
      await fetchMachinePrograms({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const fetchDepartmentTemplatesData = async () => {
    setDepartmentTemplates(
      await fetchDepartmentTemplatesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchRcemDepartmentsData = async () => {
    setRcemDepartments(
      await fetchRcemDepartmentsProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchProcessTypesData = async () => {
    setProcessTypes(
      await fetchSimplifiedProcessTypesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchIntegrationModulesData = async () => {
    setIntegrationModules(
      await fetchIntegrationModulesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchJobsData = async () => {
    setJobsProto(
      await fetchJobsProtoSimple({
        withProducts: true,
        withRemarks: true,
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  useEffect(() => {
    if (ctx?.apiKey) {
      const jti = parseJwt(ctx.apiKey ?? "")?.jti;

      console.log("User id:", jti);
      console.log("User topic:", `remote/${jti}`);

      // Create a client instance

      const client = new PahoMQTT.Client(
        `${process.env.REACT_APP_FRONTEND_MQTT_WS_URL}/mqtt`,
        `client-${jti}-${new Date().getTime()}`
      );

      // set callback handlers
      client.onConnectionLost = async (responseObject) => {
        // await ((ms) => new Promise((r) => setTimeout(r, ms)))(1000);
        // setReconnectingCount((n) => n + 1);

        if (responseObject.errorCode !== 0) {
          console.log("onConnectionLost:" + responseObject.errorMessage);
          setMqttConnected(false);

          client.connect({
            onSuccess: () => {
              setMqttConnected(true);
              console.log("onConnect");
              console.log("Subscribe", `remote/${jti}`);
              client.subscribe(`remote/${jti}`);
            },
          });
        }
      };
      client.onMessageArrived = (message) => {
        const payload = PpicRemoteControlPayload.fromPartial(
          JSON.parse(message.payloadString)
        );

        console.log("onMessageArrived:" + message.payloadString, payload);

        console.log("quad screen:", payload.quadScreen);
        console.log("quad screen 2:", payload.quadScreenV2);

        // If refresh, reload page
        if (payload.refresh === true) {
          window.location.reload();
        }

        // If JDP, fetch JDP
        if (
          payload.workOrderType &&
          workOrderTypeFromJSON(payload.workOrderType) ===
            WorkOrderType.WO_TYPE_JDP
        ) {
          handleFetchJobsJdp();
        }

        // If project master template link, fetch project info
        if (
          workOrderTypeFromJSON(payload.workOrderType) ===
          WorkOrderType.WO_TYPE_PROJECT_MASTER_TEMPLATE_LINK
        ) {
          fetchProjectMasterTemplateDetailsPageData();
        }

        // Quad screen
        if (payload.quadScreen) {
          fetchAttendanceTodayData();
          setLayoutMode("QuadScreen");

          // Turn off auto refresh for kpi
          setAutoRefresh(false);
          interval.stop();

          if (
            payload.presetRecursive.find(
              (r) =>
                !r.workOrderType ||
                (r.workOrderType &&
                  workOrderTypeFromJSON(r.workOrderType) !==
                    WorkOrderType.WO_TYPE_SALES_FUNNEL &&
                  workOrderTypeFromJSON(r.workOrderType) !==
                    WorkOrderType.WO_TYPE_SALES_PLAN &&
                  workOrderTypeFromJSON(r.workOrderType) !==
                    WorkOrderType.WO_TYPE_SALES_TARGET_VS_ACTUAL &&
                  workOrderTypeFromJSON(r.workOrderType) !==
                    WorkOrderType.WO_TYPE_PRE_SALES)
            )
          ) {
            console.log("Found not");
            handleKpiRangeData(
              payload.presetRecursive
                .map((p) => p.extDepartmentId)
                .filter((p) => p)
                .map((p) => p as string) ?? []
            );
          }

          const newDeptWoFilters = payload.presetRecursive.map((p) => ({
            deptId: p.extDepartmentId,
            weeks: 0,
            expand: true,
            workOrderType: p.workOrderType,
            woGraph: p.woGraph,
            crmDivisionType: p.crmDivisionType,
            crmUserIds: p.crmUserIds,
          }));

          setDeptWoFilters(newDeptWoFilters);

          // Fetch last plans
          newDeptWoFilters.forEach(async (d) => {
            try {
              const resp = await fetch(
                `${
                  process.env.REACT_APP_MEETING_URL
                }/last-plan?extDepartmentId=${d.deptId ?? ""}`
              );

              if (resp.status !== 200) throw await resp.text();
              const j = await resp.json();

              let newPlan = MeetingLastPlanDate.fromPartial(j);

              if (!newPlan.date) {
                newPlan.date = "";
              }

              if (
                extDepartmentLastPlans.find(
                  (px) => `${px.extDepartmentId}` === `${d.deptId}`
                )
              ) {
                setExtDepartmentLastPlans(
                  extDepartmentLastPlans.map((px) =>
                    `${px.extDepartmentId}` === `${d.deptId}`
                      ? { ...px, lastPlan: newPlan }
                      : px
                  )
                );
              } else {
                setExtDepartmentLastPlans((ps) => [
                  ...ps,
                  {
                    extDepartmentId: d.deptId,
                    lastPlan: newPlan,
                  },
                ]);
              }
            } catch (e) {}
          });

          // Fetch jobs
          newDeptWoFilters.forEach(async (d) => {
            try {
              const respToday = await fetchWorkOrdersProtoMongo({
                from: makeDateString(new Date()),
                to: makeDateString(new Date()),
                extUserIds: ctx.extUsers
                  .filter((u) => `${u.departmentId}` === `${d.deptId}`)
                  .map((d) => d.id),
              });

              const respTomorrow = await fetchWorkOrdersProtoMongo({
                from: makeDateString(new Date(new Date().getTime() + 86400000)),
                to: makeDateString(new Date(new Date().getTime() + 86400000)),
                extUserIds: ctx.extUsers
                  .filter((u) => `${u.departmentId}` === `${d.deptId}`)
                  .map((d) => d.id),
              });

              if (deptDayWos.find((dx) => dx.deptId === d.deptId)) {
                setDeptDayWos((dxx) =>
                  dxx.map((dx) =>
                    dx.deptId === d.deptId
                      ? { ...dx, today: respToday, tomorrow: respTomorrow }
                      : dx
                  )
                );
              } else {
                setDeptDayWos((dxx) => [
                  ...dxx,
                  {
                    deptId: d.deptId,
                    today: respToday,
                    tomorrow: respTomorrow,
                  },
                ]);
              }
            } catch (e) {}
          });

          // Fetch job WOs

          return;
        } else if (payload.quadScreenV2) {
          setLayoutMode("QuadScreenV2");

          const newDeptWoFilters = payload.presetRecursive.map(
            (p) =>
              ({
                deptId: p.extDepartmentId,
                weeks: 0,
                expand: true,
                workOrderType: p.workOrderType,
                woGraph: p.woGraph,
                crmDivisionType: p.crmDivisionType,
                crmUserIds: p.crmUserIds,
              } as DeptWoFilterType)
          );
          console.log("new dept wo filters", newDeptWoFilters);

          setDeptWoFilters(newDeptWoFilters);

          // Dept fetch
          newDeptWoFilters.forEach(async (d) => {
            const newDeptData = await fetchKpiDeptRes({
              apiKey: ctx.apiKey ?? "",
              deptId: d.deptId,
              todayRef: makeDateString(new Date()),
            });

            if (newDeptData) {
              if (
                kpiDeptData.find((dxx) => `${dxx.deptId}` === `${d.deptId}`)
              ) {
                setKpiDeptData((kpiDeptData) =>
                  kpiDeptData.map((dx) =>
                    `${dx.deptId}` === `${d.deptId}` ? newDeptData : dx
                  )
                );
              } else {
                setKpiDeptData((d) => [...d, newDeptData]);
              }
            }
          });

          return;
        }

        setLayoutMode("Regular");

        const payloads =
          (payload.presetRecursive?.length ?? 0) > 0
            ? payload.presetRecursive
            : [remoteControlPayloadToPreset(payload)];

        const newDeptWoFiltersData = payloads
          .map((payload) => {
            const curPage = payload.screen
              ? (payload.screen as RemoteControlPageTopic)
              : null;

            setPpicRemoteControlPayloadData(payload);
            setCurrentPage(curPage);

            const additionalInfo = remoteControlPresetToPayload(payload);

            console.log(
              "additional info: ",
              additionalInfo,
              additionalInfo.workOrderType,
              additionalInfo.workOrderType ===
                workOrderTypeToJSON(
                  additionalInfo.workOrderType ?? WorkOrderType.UNRECOGNIZED
                ),
              payload.workOrderType,
              payload.workOrderType ===
                workOrderTypeToJSON(
                  payload.workOrderType ?? WorkOrderType.UNRECOGNIZED
                )
            );

            switch (curPage) {
              case "Today":
                return {
                  ...additionalInfo,
                  weeks: null,
                  expand: false,
                };

              case "Prod 1-2 Week":
                return {
                  ...additionalInfo,
                  weeks: 2,
                  expand: true,
                };

              case "Prod 1-3 Week":
                return {
                  ...additionalInfo,
                  weeks: 3,
                  expand: true,
                };

              case "Prod 1-4 Week":
                return {
                  ...additionalInfo,
                  weeks: 4,
                  expand: true,
                };

              case "Prod 1 Week":
                return {
                  ...additionalInfo,
                  weeks: 1,
                  expand: false,
                };

              case "Prod 2 Week":
                return {
                  ...additionalInfo,
                  weeks: 2,
                  expand: false,
                };

              case "Prod 3 Week":
                return {
                  ...additionalInfo,
                  weeks: 3,
                  expand: false,
                };

              case "Prod 4 Week":
                return {
                  ...additionalInfo,
                  weeks: 4,
                  expand: false,
                };

              default:
                return { weeks: null, expand: false } as DeptWoFilterType;
            }
          })
          .map((d) => {
            // Preprocess dateskip
            if (d.skipDate && d.skipDate !== 0) {
              d.skipDate = skipDateHop(new Date(), d.skipDate).hop;
            }

            return d;
          });

        console.log("new dept wo filters", newDeptWoFiltersData);

        setDeptWoFilters(newDeptWoFiltersData);
        setWoJobs(newDeptWoFiltersData.map((_) => null));

        // Fetch proglem catalogs
        newDeptWoFiltersData.forEach(async (d) => {
          if (
            d.workOrderType ===
            workOrderTypeToJSON(d.workOrderType ?? WorkOrderType.UNRECOGNIZED)
          ) {
            try {
              const resp = await fetch(
                `${
                  process.env.REACT_APP_MEETING_URL
                }/meetingtaskproblemcatalogs?problemCatalogId=${
                  d.extProblemCatalogId ?? ""
                }`
              );

              if (resp.status !== 200) throw await resp.text();

              const data = MeetingMeetingTaskProblemCatalogs.fromPartial(
                await resp.json()
              );

              if (
                deptProblemCatalogs.find(
                  (dx) =>
                    `${dx.extProblemCatalogId}` === `${d.extProblemCatalogId}`
                )
              ) {
                setDeptProblemCatalogs((dpc) =>
                  dpc.map((dx) =>
                    `${dx.extProblemCatalogId}` === `${d.extProblemCatalogId}`
                      ? { ...dx, deptProblemCatalogs: data }
                      : dx
                  )
                );
              } else {
                setDeptProblemCatalogs((dpc) => [
                  ...dpc,
                  {
                    extProblemCatalogId: d.extProblemCatalogId,
                    deptProblemCatalogs: data,
                  },
                ]);
              }
            } catch (e) {
              console.error(e);
            }

            // setDeptProblemCatalogs();
          }
        });

        // fetch job dept templates
        newDeptWoFiltersData
          .filter((f) => f.jobId && f.jobId !== "0" && f.jobId !== "")
          .map(async (f) => {
            if (
              !jobDepartmentTemplates.find((j) => `${j.id}` === `${f.jobId}`)
            ) {
              const jobs = await fetchJobsDeptProgress({
                apiKey: ctx.apiKey ?? "",
                extJobId: f.jobId,
              });

              setJobDepartmentTemplates((t) => [...t, ...jobs]);
            }
          });
      };

      client.connect({
        onSuccess: () => {
          setMqttConnected(true);
          console.log("onConnect");
          console.log("Subscribe", `remote/${jti}`);
          client.subscribe(`remote/${jti}`);
        },
      });
    }
  }, []);

  const weeksInterval = deptWoFilter.weeks
    ? deptWoFilter.expand
      ? [...Array(deptWoFilter.weeks)].map((_, i) => i + 1)
      : [deptWoFilter.weeks]
    : [1];

  const filterDeptTemplate = (
    departmentTemplates: PpicDepartmentTemplates | null
  ) => {
    const lst =
      departmentTemplates?.templates.flatMap((t) =>
        t.items.map((i) => ({
          departmentTemplate: t,
          departmentTemplateItem: i,
        }))
      ) ?? [];

    lst.sort((a, b) =>
      a.departmentTemplate.masterJavaBaseModel?.ordering &&
      b.departmentTemplate.masterJavaBaseModel?.ordering &&
      a.departmentTemplateItem.masterJavaBaseModel?.ordering &&
      b.departmentTemplateItem.masterJavaBaseModel?.ordering
        ? parseInt(a.departmentTemplate.masterJavaBaseModel?.ordering ?? "0") -
            parseInt(
              b.departmentTemplate.masterJavaBaseModel?.ordering ?? "0"
            ) ||
          parseInt(
            a.departmentTemplateItem.masterJavaBaseModel?.ordering ?? "0"
          ) -
            parseInt(
              b.departmentTemplateItem.masterJavaBaseModel?.ordering ?? "0"
            )
        : 0
    );

    return lst;
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex justify-content-between">
          <div>
            <div className="d-flex">
              <h4>
                Remote Monitoring ({parseJwt(ctx?.apiKey ?? "")?.sub}): Elapsed{" "}
                {(() => {
                  const elapsedSecs =
                    (new Date().getTime() -
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate(),
                        elapsedHourReference
                      ).getTime()) /
                    1000;

                  return secondsToHms(elapsedSecs);
                })()}{" "}
                since {elapsedHourReference}:00
              </h4>
              <div className="d-flex">
                Debug?{" "}
                <input
                  type="checkbox"
                  checked={debug}
                  onClick={() => {
                    setDebug(!debug);
                  }}
                />
              </div>
              <div>
                <button
                  className="btn btn-sm btn-primary mx-2"
                  onClick={() => {
                    setElapsedHourReference(elapsedHourReference + 1);
                  }}
                >
                  <Add />{" "}
                </button>
                <button
                  className="btn btn-sm btn-primary mx-2"
                  onClick={() => {
                    setElapsedHourReference(elapsedHourReference - 1);
                  }}
                >
                  <Remove />{" "}
                </button>
              </div>
            </div>
            {/* <div>
              <small>CQRS</small>
            </div> */}
            {loading ? (
              <>
                <Spinner animation="border" />
                Jobs Loading...
              </>
            ) : (
              <></>
            )}
            {/* {woJobLoading ? (
              <>
                <Spinner animation="border" />
                WO Loading...
              </>
            ) : (
              <></>
            )} */}
          </div>

          <div className="d-flex">
            {mqttConnected ? (
              <div className="d-flex">
                <div className="text-success font-weight-bold">
                  MQTT Connected
                </div>

                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setMqttConnected(false);
                    }}
                  >
                    Hard reset
                  </button>
                </div>
              </div>
            ) : (
              <div className="text-danger font-weight-bold">
                MQTT Disconnected. Reconnecting... (count: {reconnectingCount} )
              </div>
            )}

            <div className="mx-1">
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Refresh />
              </button>
            </div>

            <div className="mx-2 d-flex">
              Auto refresh ?{" "}
              <input
                type="checkbox"
                checked={autoRefresh}
                onClick={() => {
                  if (autoRefresh) {
                    interval.stop();
                  }

                  if (!autoRefresh) {
                    interval.start();
                  }

                  setAutoRefresh(!autoRefresh);
                }}
              />
            </div>
          </div>
        </div>

        <hr />

        {/* {JSON.stringify(woJobs.map((j) => j?.taskLists?.length))} */}

        {(() => {
          switch (layoutMode) {
            case "Regular":
              return (
                <>
                  {deptWoFilters.map((deptWoFilter, i) => {
                    const weeksInterval = deptWoFilter.weeks
                      ? deptWoFilter.expand
                        ? [...Array(deptWoFilter.weeks)].map((_, i) => i + 1)
                        : [deptWoFilter.weeks]
                      : [1];

                    return (
                      <>
                        <div
                          className="overflow-auto border border-dark"
                          style={{
                            height: `${
                              100 / (deptWoFilters.length ?? 1) - 5
                            }vh`,
                          }}
                        >
                          {/* <div>Split n #{i}</div> */}
                          <div className="d-flex">
                            {deptWoFilter.extUserId &&
                            deptWoFilter.extUserId !== "0" ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  User:{" "}
                                  {
                                    ctx?.extUsers.find(
                                      (u) =>
                                        `${u.id}` ===
                                        `${deptWoFilter.extUserId}`
                                    )?.username
                                  }
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.deptId &&
                            deptWoFilter.deptId !== "0" ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  Dept:{" "}
                                  {
                                    ctx?.extDepartments.find(
                                      (d) =>
                                        `${d.id}` === `${deptWoFilter.deptId}`
                                    )?.name
                                  }
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.skipDate &&
                            deptWoFilter.skipDate !== 0 ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  Skip: {deptWoFilter.skipDate} (
                                  {Intl.DateTimeFormat("en-US", {
                                    weekday: "long",
                                  } as any).format(
                                    new Date(
                                      new Date().getTime() +
                                        86400000 * deptWoFilter.skipDate
                                    )
                                  )}
                                  )
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.workOrderType ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  WO type:{" "}
                                  {workOrderTypeToJSON(
                                    deptWoFilter.workOrderType
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.extProblemCatalogId ? (
                              <>
                                {(() => {
                                  const foundProblemCatalog =
                                    problemCatalogs.catalogs.find(
                                      (c) =>
                                        `${c.masterJavaBaseModel?.id}` ===
                                        `${deptWoFilter.extProblemCatalogId}`
                                    );

                                  if (!foundProblemCatalog) {
                                    return <></>;
                                  }

                                  return (
                                    <>
                                      <div className="border border-dark flex-grow-1 ">
                                        <div className="d-flex">
                                          <div>
                                            Prob:{" "}
                                            {getProblemCatalogFullName(
                                              ctx?.extDepartments ?? [],
                                              foundProblemCatalog
                                            )}
                                          </div>{" "}
                                          <div className="mx-1">
                                            {foundProblemCatalog?.isQuality ? (
                                              <>
                                                <div className="bg-primary px-2 text-light">
                                                  <small>
                                                    <strong>Quality</strong>
                                                  </small>
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                          <div className="mx-2">
                                            {foundProblemCatalog?.isCost ? (
                                              <>
                                                <div className="bg-success font-weight-bold px-2 text-light">
                                                  <small>
                                                    <strong>Cost</strong>
                                                  </small>
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })()}
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.picOnly !== undefined ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  Mode: {deptWoFilter.picOnly ? "PIC" : "SPV"}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.expand ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  Expand
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.weeks && deptWoFilter.weeks !== 0 ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  Weeks: {deptWoFilter.weeks}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.jobId &&
                            deptWoFilter.jobId !== "0" ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  Job:{" "}
                                  {
                                    jobsProto?.jobs.find(
                                      (j) =>
                                        `${j.masterJavaBaseModel?.id}` ===
                                        `${deptWoFilter.jobId}`
                                    )?.name
                                  }
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {deptWoFilter.jdpPresetId ||
                            (jobsProto?.jobs.find(
                              (j) =>
                                `${j.masterJavaBaseModel?.id}` ===
                                `${deptWoFilter.jobId}`
                            )?.extJdpPresetId &&
                              jobsProto?.jobs.find(
                                (j) =>
                                  `${j.masterJavaBaseModel?.id}` ===
                                  `${deptWoFilter.jobId}`
                              )?.extJdpPresetId !== "0") ? (
                              <>
                                <div className="border border-dark flex-grow-1 font-weight-bold">
                                  Preset:{" "}
                                  {deptWoFilter.jdpPresetId
                                    ? deptTemplatePresets.presets.find(
                                        (p) =>
                                          `${p.masterJavaBaseModel?.id}` ===
                                          `${deptWoFilter.jdpPresetId}`
                                      )?.name
                                    : deptTemplatePresets.presets.find(
                                        (p) =>
                                          `${p.masterJavaBaseModel?.id}` ===
                                          `${
                                            jobsProto?.jobs.find(
                                              (j) =>
                                                `${j.masterJavaBaseModel?.id}` ===
                                                `${deptWoFilter.jobId}`
                                            )?.extJdpPresetId
                                          }`
                                      )?.name}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          {!woJobs?.[i]?.taskLists ? (
                            <>
                              <Spinner animation="border" />
                            </>
                          ) : (
                            <>
                              <div>
                                {(() => {
                                  if (true) {
                                    const weeks = weeksInterval.map((i) => ({
                                      from: new Date(
                                        new Date(
                                          new Date().getTime() +
                                            86400000 *
                                              (skipDateInterval * (i - 1))
                                        ).getTime() +
                                          86400000 *
                                            (deptWoFilter.skipDate ?? 0)
                                      ),
                                      to: deptWoFilter.weeks
                                        ? new Date(
                                            new Date().getTime() +
                                              86400000 *
                                                (skipDateInterval * (i - 1) +
                                                  (skipDateInterval - 1)) +
                                              86400000 *
                                                (deptWoFilter.skipDate ?? 0)
                                          )
                                        : new Date(
                                            new Date().getTime() +
                                              86400000 *
                                                (skipDateInterval * (i - 1)) +
                                              86400000 *
                                                (deptWoFilter.skipDate ?? 0)
                                          ),
                                    }));

                                    const woJob =
                                      woJobs[i] ??
                                      MeetingTaskListsView.fromPartial({
                                        taskLists: [],
                                      });

                                    return (
                                      <>
                                        <div className="d-flex justify-content-between">
                                          {weeksInterval.map((w, i) => {
                                            const wosFilteredByDate =
                                              filterWorkOrderByDateRange(
                                                woJob,
                                                makeDateString(
                                                  weeks?.[i]?.from
                                                ),
                                                makeDateString(weeks?.[i]?.to),
                                                null
                                              ) ?? [];

                                            const wosFilteredByWeeklyGeneric =
                                              weeklyFromDate.current &&
                                              weeklyToDate.current
                                                ? filterWorkOrderByDateRange(
                                                    woJob,
                                                    weeklyFromDate.current,
                                                    weeklyToDate.current,
                                                    null
                                                  ) ?? []
                                                : [];

                                            switch (
                                              workOrderTypeToJSON(
                                                deptWoFilter.workOrderType ??
                                                  WorkOrderType.WO_TYPE_JOB
                                              )
                                            ) {
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_GENERIC_WEEKLY
                                              ):
                                                return (
                                                  <>
                                                    <div className="flex-fill">
                                                      <div>
                                                        Sales Activity Plan
                                                      </div>

                                                      <div className="flex-fill">
                                                        <table
                                                          className="w-100 table table-sm"
                                                          style={{
                                                            borderCollapse:
                                                              "separate",
                                                          }}
                                                        >
                                                          <tr>
                                                            {[
                                                              "#",
                                                              "Name",
                                                              ...weeklyDates.map(
                                                                (d) => {
                                                                  return `${Intl.DateTimeFormat(
                                                                    "id-ID",
                                                                    {
                                                                      dateStyle:
                                                                        "medium",
                                                                    }
                                                                  ).format(
                                                                    new Date(d)
                                                                  )}`;
                                                                }
                                                              ),
                                                            ].map((h) => {
                                                              return (
                                                                <>
                                                                  <th className="bg-dark text-light">
                                                                    {h}
                                                                  </th>
                                                                </>
                                                              );
                                                            })}
                                                          </tr>
                                                          {(() => {
                                                            const u = [
                                                              ...new Set(
                                                                ctx?.extUsers.map(
                                                                  (u) => u.id
                                                                ) ?? []
                                                              ),
                                                            ];

                                                            u.sort((a, b) =>
                                                              (
                                                                ctx?.extUsers.find(
                                                                  (u) =>
                                                                    u.id === a
                                                                )?.username ??
                                                                ""
                                                              )?.localeCompare(
                                                                ctx?.extUsers.find(
                                                                  (u) =>
                                                                    u.id === b
                                                                )?.username ??
                                                                  ""
                                                              )
                                                            );

                                                            return u;
                                                          })()
                                                            .map((uid) => {
                                                              const userWos =
                                                                wosFilteredByWeeklyGeneric.filter(
                                                                  (tL) =>
                                                                    tL.taskList?.meetingTasks.find(
                                                                      (mt) =>
                                                                        mt.meetingTaskInCharges.find(
                                                                          (
                                                                            ic
                                                                          ) =>
                                                                            `${ic.extUserId}` ===
                                                                            `${uid}`
                                                                        )
                                                                    )
                                                                );

                                                              return {
                                                                user: ctx?.extUsers.find(
                                                                  (u) =>
                                                                    `${u.id}` ===
                                                                    `${uid}`
                                                                ),
                                                                wos: userWos,
                                                              };
                                                            })
                                                            .filter((d) => {
                                                              if (
                                                                deptWoFilter.deptId
                                                              ) {
                                                                return ctx?.extUsers
                                                                  .filter(
                                                                    (u) =>
                                                                      `${u.departmentId}` ===
                                                                      `${deptWoFilter.deptId}`
                                                                  )
                                                                  .find(
                                                                    (u) =>
                                                                      `${d.user?.id}` ===
                                                                      `${u.id}`
                                                                  );
                                                              } else if (
                                                                deptWoFilter.extUserId
                                                              ) {
                                                                return (
                                                                  `${d.user?.id}` ===
                                                                  `${deptWoFilter.extUserId}`
                                                                );
                                                              } else {
                                                                return true;
                                                              }
                                                            })
                                                            .map((u, i) => {
                                                              return (
                                                                <>
                                                                  <tr>
                                                                    <td className="border border-dark">
                                                                      {i + 1}
                                                                    </td>
                                                                    <td className="border border-dark">
                                                                      {
                                                                        u?.user
                                                                          ?.name
                                                                      }
                                                                    </td>
                                                                    {weeklyDates.map(
                                                                      (d) => {
                                                                        const wosFilteredByWeekDate =
                                                                          filterWorkOrderByDateRange(
                                                                            MeetingTaskListsView.fromPartial(
                                                                              {
                                                                                taskLists:
                                                                                  u.wos,
                                                                              }
                                                                            ),
                                                                            makeDateString(
                                                                              new Date(
                                                                                d
                                                                              )
                                                                            ),
                                                                            makeDateString(
                                                                              new Date(
                                                                                d
                                                                              )
                                                                            ),
                                                                            u.user
                                                                          );

                                                                        const filteredWoTasksByDate =
                                                                          filterMeetingTasks(
                                                                            wosFilteredByWeekDate
                                                                              ?.map(
                                                                                (
                                                                                  tL
                                                                                ) =>
                                                                                  tL
                                                                                    .taskList
                                                                                    ?.meetingTasks ??
                                                                                  []
                                                                              )
                                                                              .flat(),
                                                                            u
                                                                              .user
                                                                              ?.id,
                                                                            makeDateString(
                                                                              new Date(
                                                                                d
                                                                              )
                                                                            )
                                                                          );

                                                                        // const wosDate =
                                                                        //   filterWorkOrderByDate(
                                                                        //     MeetingTaskListsView.fromPartial(
                                                                        //       {
                                                                        //         taskLists:
                                                                        //           wosFilteredByWeekDate,
                                                                        //       }
                                                                        //     ),
                                                                        //     makeDateString(
                                                                        //       new Date(
                                                                        //         d
                                                                        //       )
                                                                        //     ),
                                                                        //     u.user
                                                                        //   );

                                                                        return (
                                                                          <>
                                                                            <td
                                                                              onClick={() => {
                                                                                setTaskDetailsShow(
                                                                                  true
                                                                                );

                                                                                if (
                                                                                  u.user
                                                                                ) {
                                                                                  setSelectedExtUser(
                                                                                    u.user
                                                                                  );
                                                                                }

                                                                                setBypass(
                                                                                  true
                                                                                );

                                                                                setModalTasks(
                                                                                  wosFilteredByWeekDate ??
                                                                                    []
                                                                                );
                                                                              }}
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                              className="border border-dark"
                                                                            >
                                                                              <div className="d-flex flex-wrap">
                                                                                {filteredWoTasksByDate.map(
                                                                                  (
                                                                                    mt
                                                                                  ) => {
                                                                                    return (
                                                                                      <div
                                                                                        className="mx-1"
                                                                                        style={{
                                                                                          color:
                                                                                            mt?.status ===
                                                                                            "COMPLETED"
                                                                                              ? "green"
                                                                                              : mt?.extPicCompletedDate
                                                                                              ? "orange"
                                                                                              : `red`,
                                                                                        }}
                                                                                      >
                                                                                        ⬤
                                                                                      </div>
                                                                                    );
                                                                                  }
                                                                                )}
                                                                              </div>
                                                                            </td>
                                                                          </>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </tr>
                                                                </>
                                                              );
                                                            })}
                                                        </table>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_GENERIC
                                              ):
                                                return (
                                                  <>
                                                    <div className="flex-fill">
                                                      <table
                                                        className="table table-sm border border-dark"
                                                        style={{
                                                          borderCollapse:
                                                            "separate",
                                                          tableLayout: "fixed",
                                                        }}
                                                      >
                                                        <tr>
                                                          {[
                                                            "#",
                                                            "Name",
                                                            "Alloc",
                                                            "Workload",
                                                            "Check In",
                                                            "Attendance",
                                                            `Progress (
                                              ${Intl.DateTimeFormat("en-US", {
                                                dateStyle: "short",
                                              } as any).format(
                                                weeks?.[i]?.from
                                              )} - ${Intl.DateTimeFormat(
                                                              "en-US",
                                                              {
                                                                dateStyle:
                                                                  "short",
                                                              } as any
                                                            ).format(
                                                              weeks?.[i]?.to
                                                            )}
                                              )`,
                                                            "Last Seen",
                                                            "Last Seen At",
                                                            "Last Task",
                                                            ...(wosFilteredByDate.find(
                                                              (tL) =>
                                                                tL.taskList?.meetingTasks.find(
                                                                  (mt) =>
                                                                    mt.journeys
                                                                      .length >
                                                                    0
                                                                )
                                                            )
                                                              ? ["Site"]
                                                              : []),
                                                          ].map((h) => (
                                                            <th
                                                              className="bg-dark text-light"
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                            >
                                                              {h}
                                                            </th>
                                                          ))}
                                                        </tr>
                                                        {(() => {
                                                          const u = [
                                                            ...new Set(
                                                              ctx?.extUsers.map(
                                                                (u) => u.id
                                                              ) ?? []
                                                            ),
                                                          ];

                                                          u.sort((a, b) =>
                                                            (
                                                              ctx?.extUsers.find(
                                                                (u) =>
                                                                  u.id === a
                                                              )?.username ?? ""
                                                            )?.localeCompare(
                                                              ctx?.extUsers.find(
                                                                (u) =>
                                                                  u.id === b
                                                              )?.username ?? ""
                                                            )
                                                          );

                                                          return u;
                                                        })()
                                                          .map((uid) => {
                                                            const userWos =
                                                              wosFilteredByDate.filter(
                                                                (tL) =>
                                                                  tL.taskList?.meetingTasks.find(
                                                                    (mt) =>
                                                                      mt.meetingTaskInCharges.find(
                                                                        (ic) =>
                                                                          `${ic.extUserId}` ===
                                                                          `${uid}`
                                                                      )
                                                                  )
                                                              );

                                                            return {
                                                              user: ctx?.extUsers.find(
                                                                (u) =>
                                                                  `${u.id}` ===
                                                                  `${uid}`
                                                              ),
                                                              wos: userWos,
                                                            };
                                                          })
                                                          // .filter((d) => d.wos.length > 0)
                                                          // Filter people
                                                          .filter((d) => {
                                                            if (
                                                              deptWoFilter.deptId
                                                            ) {
                                                              return ctx?.extUsers
                                                                .filter(
                                                                  (u) =>
                                                                    `${u.departmentId}` ===
                                                                    `${deptWoFilter.deptId}`
                                                                )
                                                                .find(
                                                                  (u) =>
                                                                    `${d.user?.id}` ===
                                                                    `${u.id}`
                                                                );
                                                            } else if (
                                                              deptWoFilter.extUserId
                                                            ) {
                                                              return (
                                                                `${d.user?.id}` ===
                                                                `${deptWoFilter.extUserId}`
                                                              );
                                                            } else {
                                                              return true;
                                                            }
                                                          })
                                                          .map((d, i_) => {
                                                            const filteredWoTasksByDate =
                                                              filterMeetingTasks(
                                                                d.wos
                                                                  .map(
                                                                    (tL) =>
                                                                      tL
                                                                        .taskList
                                                                        ?.meetingTasks ??
                                                                      []
                                                                  )
                                                                  .flat(),
                                                                d.user?.id,
                                                                weeks?.[i]
                                                                  ?.from &&
                                                                  weeks?.[i]
                                                                    ?.to &&
                                                                  makeDateString(
                                                                    weeks?.[i]
                                                                      .from
                                                                  ) ===
                                                                    makeDateString(
                                                                      weeks?.[i]
                                                                        .to
                                                                    )
                                                                  ? makeDateString(
                                                                      weeks?.[i]
                                                                        .from
                                                                    )
                                                                  : null
                                                              );

                                                            const tasksComplete =
                                                              filteredWoTasksByDate.filter(
                                                                (mt) =>
                                                                  mt?.status ===
                                                                  "COMPLETED"
                                                              );
                                                            const wosPercentage =
                                                              ((tasksComplete.length ??
                                                                0) /
                                                                (filteredWoTasksByDate.length ??
                                                                  1)) *
                                                              100;
                                                            const wosDisplayTime =
                                                              d.wos
                                                                ?.map((tL) => {
                                                                  const t =
                                                                    getWorkOrderTimeMinutes(
                                                                      tL.taskList,
                                                                      rcemDepartments,
                                                                      integrationModules,
                                                                      machinePrograms,
                                                                      departmentTemplates,
                                                                      panelCodeDepartmentTemplateItemsProto,
                                                                      d.user
                                                                        ?.id,

                                                                      makeDateString(
                                                                        new Date(
                                                                          weeks?.[
                                                                            i
                                                                          ].from
                                                                        )
                                                                      )
                                                                    );

                                                                  console.log(
                                                                    "task list:",
                                                                    tL
                                                                  );
                                                                  console.log(
                                                                    `wo time ${tL.taskList?.masterJavaBaseModel?.id}`,
                                                                    t
                                                                  );
                                                                  console.log();

                                                                  return t;
                                                                })
                                                                .reduce(
                                                                  (acc, time) =>
                                                                    acc +
                                                                    (time.totalHours ??
                                                                      0),
                                                                  0.0
                                                                ) ?? 0.0;

                                                            return (
                                                              <>
                                                                <tr>
                                                                  <td className="border border-dark">
                                                                    {i_ + 1}
                                                                  </td>
                                                                  <td
                                                                    className="border border-dark"
                                                                    style={{
                                                                      wordWrap:
                                                                        "break-word",
                                                                    }}
                                                                  >
                                                                    {d.user?.username
                                                                      ?.split(
                                                                        ""
                                                                      )
                                                                      .map(
                                                                        (
                                                                          s,
                                                                          i
                                                                        ) =>
                                                                          i ===
                                                                          0
                                                                            ? s.toUpperCase()
                                                                            : s
                                                                      )
                                                                      .join("")}
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    {d.user
                                                                      ?.allocation
                                                                      ? d.user
                                                                          ?.allocation
                                                                      : "-"}
                                                                  </td>
                                                                  <td
                                                                    className="border border-dark font-weight-bold"
                                                                    style={{
                                                                      backgroundColor:
                                                                        Number(
                                                                          wosDisplayTime?.toFixed(
                                                                            1
                                                                          )
                                                                        ) > 8
                                                                          ? // overload
                                                                            snapInterval
                                                                            ? `lightblue`
                                                                            : `white`
                                                                          : // normal
                                                                          weeks?.[
                                                                              i
                                                                            ]
                                                                              .from &&
                                                                            weeks?.[
                                                                              i
                                                                            ]
                                                                              .to &&
                                                                            makeDateString(
                                                                              new Date(
                                                                                weeks?.[
                                                                                  i
                                                                                ].from
                                                                              )
                                                                            ) ===
                                                                              makeDateString(
                                                                                new Date(
                                                                                  weeks?.[
                                                                                    i
                                                                                  ].to
                                                                                )
                                                                              )
                                                                          ? chroma
                                                                              .scale(
                                                                                [
                                                                                  "lightsalmon",
                                                                                  "yellow",
                                                                                  "lightgreen",
                                                                                ]
                                                                              )(
                                                                                wosDisplayTime /
                                                                                  8
                                                                              )
                                                                              .hex()
                                                                          : "",
                                                                    }}
                                                                  >
                                                                    {weeks?.[i]
                                                                      .from &&
                                                                    weeks?.[i]
                                                                      .to &&
                                                                    makeDateString(
                                                                      new Date(
                                                                        weeks?.[
                                                                          i
                                                                        ].from
                                                                      )
                                                                    ) ===
                                                                      makeDateString(
                                                                        new Date(
                                                                          weeks?.[
                                                                            i
                                                                          ].to
                                                                        )
                                                                      )
                                                                      ? `${wosDisplayTime?.toFixed(
                                                                          1
                                                                        )}h`
                                                                      : ""}
                                                                  </td>

                                                                  <td
                                                                    className="border border-dark font-weight-bold"
                                                                    style={{
                                                                      overflowWrap:
                                                                        "break-word",
                                                                    }}
                                                                  >
                                                                    <div className="d-flex flex-wrap">
                                                                      {/* {
                                                                  filteredWoTasksByDate
                                                                    .map(
                                                                      (tL) =>
                                                                        tL?.taskList
                                                                          ?.meetingTasks ??
                                                                        []
                                                                    )
                                                                    .flat().length
                                                                } */}
                                                                      {filteredWoTasksByDate.map(
                                                                        (
                                                                          mt
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <div
                                                                                className="mx-1"
                                                                                style={{
                                                                                  color:
                                                                                    mt?.status ===
                                                                                    "COMPLETED"
                                                                                      ? "green"
                                                                                      : mt?.extPicCompletedDate
                                                                                      ? "orange"
                                                                                      : `red`,
                                                                                }}
                                                                              >
                                                                                ⬤
                                                                              </div>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </div>
                                                                  </td>

                                                                  {weeks?.[i]
                                                                    .from &&
                                                                  weeks?.[i]
                                                                    .to &&
                                                                  makeDateString(
                                                                    new Date(
                                                                      weeks?.[
                                                                        i
                                                                      ].from
                                                                    )
                                                                  ) ===
                                                                    makeDateString(
                                                                      new Date(
                                                                        weeks?.[
                                                                          i
                                                                        ].to
                                                                      )
                                                                    ) ? (
                                                                    (() => {
                                                                      const foundAttendance =
                                                                        checkIns
                                                                          .find(
                                                                            (
                                                                              c
                                                                            ) =>
                                                                              `${c.date}` ===
                                                                              `${makeDateString(
                                                                                new Date(
                                                                                  weeks?.[
                                                                                    i
                                                                                  ].from
                                                                                )
                                                                              )}`
                                                                          )
                                                                          ?.checkIn.find(
                                                                            (
                                                                              c
                                                                            ) =>
                                                                              `${c.user?.id}` ===
                                                                              `${d.user?.id}`
                                                                          );

                                                                      const foundShift =
                                                                        shifts.current.shifts.find(
                                                                          (s) =>
                                                                            s.date ===
                                                                              makeDateString(
                                                                                new Date()
                                                                              ) &&
                                                                            `${s.userId}` ===
                                                                              `${d.user?.id}`
                                                                        );
                                                                      const foundShiftTemplate =
                                                                        shiftTemplates.current.templates.find(
                                                                          (t) =>
                                                                            `${t.masterJavaBaseModel?.id}` ===
                                                                            `${foundShift?.shiftTemplateId}`
                                                                        );

                                                                      const foundAttendanceNote =
                                                                        attendanceNotes.find(
                                                                          (n) =>
                                                                            n.date &&
                                                                            `${n.extUserId}` ===
                                                                              `${d.user?.id}` &&
                                                                            makeDateString(
                                                                              new Date(
                                                                                n.date
                                                                              )
                                                                            ) ===
                                                                              makeDateString(
                                                                                weeks?.[
                                                                                  i
                                                                                ]
                                                                                  .from
                                                                              )
                                                                        );

                                                                      return foundShift ? (
                                                                        <>
                                                                          <td
                                                                            className={`border border-dark font-weight-bold ${
                                                                              foundShift.shift
                                                                                ? `bg-info text-light`
                                                                                : `bg-danger text-light`
                                                                            }`}
                                                                          >
                                                                            <div>
                                                                              <div>
                                                                                <small>
                                                                                  <strong>
                                                                                    {foundShift.shift ? (
                                                                                      <>
                                                                                        Shift:{" "}
                                                                                        {
                                                                                          foundShiftTemplate?.fromHour
                                                                                        }

                                                                                        :
                                                                                        {
                                                                                          foundShiftTemplate?.fromMins
                                                                                        }

                                                                                        -
                                                                                        {
                                                                                          foundShiftTemplate?.toHour
                                                                                        }

                                                                                        :
                                                                                        {
                                                                                          foundShiftTemplate?.toMins
                                                                                        }
                                                                                      </>
                                                                                    ) : (
                                                                                      <>
                                                                                        Day
                                                                                        off
                                                                                      </>
                                                                                    )}
                                                                                  </strong>
                                                                                </small>
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </>
                                                                      ) : foundAttendanceNote ? (
                                                                        <>
                                                                          <td className="border border-dark font-weight-bold bg-warning">
                                                                            <div>
                                                                              <div>
                                                                                <small>
                                                                                  <strong>
                                                                                    {
                                                                                      foundAttendanceNote.note
                                                                                    }

                                                                                    :{" "}
                                                                                    {foundAttendanceNote.createdAt
                                                                                      ? (() => {
                                                                                          try {
                                                                                            const d =
                                                                                              new Date(
                                                                                                foundAttendanceNote.createdAt
                                                                                              );

                                                                                            return `${d.getHours()}:${d.getMinutes()}`;
                                                                                          } catch (e) {
                                                                                            return "";
                                                                                          }
                                                                                        })()
                                                                                      : ""}
                                                                                  </strong>
                                                                                </small>
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </>
                                                                      ) : foundAttendance &&
                                                                        foundAttendance.firstLocation ? (
                                                                        <>
                                                                          <td className="border border-dark bg-success font-weight-bold text-light">
                                                                            {foundAttendance
                                                                              .firstLocation
                                                                              ?.createdAt
                                                                              ? (() => {
                                                                                  try {
                                                                                    const d =
                                                                                      new Date(
                                                                                        foundAttendance.firstLocation.createdAt
                                                                                      );

                                                                                    return `${d.getHours()}:${d.getMinutes()}`;
                                                                                  } catch (e) {
                                                                                    return "";
                                                                                  }
                                                                                })()
                                                                              : ""}
                                                                          </td>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <td className="border border-dark font-weight-bold bg-danger"></td>
                                                                        </>
                                                                      );
                                                                    })()
                                                                  ) : (
                                                                    <>
                                                                      {" "}
                                                                      <td className="border border-dark font-weight-bold"></td>{" "}
                                                                    </>
                                                                  )}

                                                                  <td
                                                                    className="border border-dark font-weight-bold"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      backgroundColor:
                                                                        chroma
                                                                          .scale(
                                                                            [
                                                                              "lightsalmon",
                                                                              "yellow",
                                                                              "lightgreen",
                                                                            ]
                                                                          )(
                                                                            tasksComplete.length /
                                                                              filteredWoTasksByDate.length
                                                                          )
                                                                          .hex(),
                                                                    }}
                                                                    onClick={() => {
                                                                      console.log(
                                                                        "selected week range",
                                                                        weeks?.[
                                                                          i
                                                                        ],
                                                                        i
                                                                      );
                                                                      setSelectedWeekRange(
                                                                        weeks?.[
                                                                          i
                                                                        ]
                                                                      );
                                                                      setTaskDetailsShow(
                                                                        true
                                                                      );

                                                                      if (
                                                                        d.user
                                                                      ) {
                                                                        setSelectedExtUser(
                                                                          d.user
                                                                        );
                                                                      }

                                                                      setModalTasks(
                                                                        woJob.taskLists.filter(
                                                                          (
                                                                            tL
                                                                          ) =>
                                                                            tL.taskList?.meetingTasks.find(
                                                                              (
                                                                                mt
                                                                              ) =>
                                                                                mt.meetingTaskInCharges.find(
                                                                                  (
                                                                                    mtic
                                                                                  ) =>
                                                                                    `${mtic.extUserId}` ===
                                                                                    `${d.user?.id}`
                                                                                )
                                                                            )
                                                                        )
                                                                      );
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      <div>
                                                                        {
                                                                          tasksComplete.length
                                                                        }
                                                                        /
                                                                        {filteredWoTasksByDate.length ??
                                                                          1}{" "}
                                                                        (
                                                                        {wosPercentage?.toFixed(
                                                                          0
                                                                        )}
                                                                        %)
                                                                      </div>
                                                                      <div>
                                                                        <ol>
                                                                          {filteredWoTasksByDate
                                                                            .flatMap(
                                                                              (
                                                                                mt
                                                                              ) =>
                                                                                mt.meetingTaskComments
                                                                            )
                                                                            .filter(
                                                                              (
                                                                                c
                                                                              ) =>
                                                                                !c.readBySpvTimestamp
                                                                            )
                                                                            .map(
                                                                              (
                                                                                c
                                                                              ) => (
                                                                                <>
                                                                                  <li>
                                                                                    <div className="bg-light mt-1 rounded rounded-md">
                                                                                      <div>
                                                                                        <small>
                                                                                          <strong>
                                                                                            {ctx?.extUsers.find(
                                                                                              (
                                                                                                u
                                                                                              ) =>
                                                                                                `${u.id}` ===
                                                                                                `${c.extUserId}`
                                                                                            )
                                                                                              ?.username ??
                                                                                              "Admin"}{" "}
                                                                                            on{" "}
                                                                                            {(() => {
                                                                                              try {
                                                                                                return Intl.DateTimeFormat(
                                                                                                  "en-US",
                                                                                                  {
                                                                                                    dateStyle:
                                                                                                      "short",
                                                                                                    timeStyle:
                                                                                                      "short",
                                                                                                    hourCycle:
                                                                                                      "h23",
                                                                                                  } as any
                                                                                                ).format(
                                                                                                  new Date(
                                                                                                    c
                                                                                                      .masterJavaBaseModel
                                                                                                      ?.createdAt ??
                                                                                                      ""
                                                                                                  )
                                                                                                );
                                                                                              } catch (e) {
                                                                                                return "";
                                                                                              }
                                                                                            })()}
                                                                                          </strong>

                                                                                          :
                                                                                          {
                                                                                            c.comment
                                                                                          }
                                                                                        </small>
                                                                                      </div>
                                                                                    </div>
                                                                                  </li>
                                                                                </>
                                                                              )
                                                                            )}
                                                                        </ol>
                                                                      </div>
                                                                    </div>
                                                                  </td>

                                                                  <td className="border border-dark">
                                                                    {(() => {
                                                                      const foundAttendance =
                                                                        checkIns
                                                                          .find(
                                                                            (
                                                                              c
                                                                            ) =>
                                                                              `${c.date}` ===
                                                                              `${makeDateString(
                                                                                new Date(
                                                                                  weeks?.[
                                                                                    i
                                                                                  ].from
                                                                                )
                                                                              )}`
                                                                          )
                                                                          ?.checkIn.find(
                                                                            (
                                                                              c
                                                                            ) =>
                                                                              `${c.user?.id}` ===
                                                                              `${d.user?.id}`
                                                                          );

                                                                      return foundAttendance
                                                                        ?.lastLocation
                                                                        ?.createdAt ? (
                                                                        <>
                                                                          <div>
                                                                            {timeDiff(
                                                                              {
                                                                                future:
                                                                                  new Date().toISOString(),
                                                                                past:
                                                                                  foundAttendance
                                                                                    .lastLocation
                                                                                    .createdAt ??
                                                                                  "",
                                                                              }
                                                                            )}{" "}
                                                                            ago
                                                                          </div>
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      );
                                                                    })()}
                                                                  </td>

                                                                  <td
                                                                    className="border border-dark"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      if (
                                                                        !locationExpandedUserIds.find(
                                                                          (
                                                                            id
                                                                          ) =>
                                                                            `${id}` ===
                                                                            `${d.user?.id}`
                                                                        )
                                                                      ) {
                                                                        setLocationExpandedUserIds(
                                                                          [
                                                                            ...locationExpandedUserIds,
                                                                            `${d.user?.id}`,
                                                                          ]
                                                                        );

                                                                        return;
                                                                      }

                                                                      setLocationExpandedUserIds(
                                                                        locationExpandedUserIds.filter(
                                                                          (
                                                                            id
                                                                          ) =>
                                                                            `${id}` !==
                                                                            `${d.user?.id}`
                                                                        )
                                                                      );
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      {(() => {
                                                                        const foundAttendance =
                                                                          checkIns
                                                                            .find(
                                                                              (
                                                                                c
                                                                              ) =>
                                                                                `${c.date}` ===
                                                                                `${makeDateString(
                                                                                  new Date(
                                                                                    weeks?.[
                                                                                      i
                                                                                    ].from
                                                                                  )
                                                                                )}`
                                                                            )
                                                                            ?.checkIn.find(
                                                                              (
                                                                                c
                                                                              ) =>
                                                                                `${c.user?.id}` ===
                                                                                `${d.user?.id}`
                                                                            );

                                                                        return foundAttendance
                                                                          ?.lastLocation
                                                                          ?.lat &&
                                                                          foundAttendance
                                                                            .lastLocation
                                                                            .lon ? (
                                                                          <>
                                                                            <Async
                                                                              promiseFn={async () => {
                                                                                try {
                                                                                  const resp =
                                                                                    await fetch(
                                                                                      `${process.env.REACT_APP_NOMINATIM_URL}/reverse.php?lat=${foundAttendance.lastLocation?.lat}&lon=${foundAttendance.lastLocation?.lon}&format=jsonv2`
                                                                                    );

                                                                                  if (
                                                                                    resp.status !==
                                                                                    200
                                                                                  ) {
                                                                                    throw await resp.text();
                                                                                  }

                                                                                  return await resp.json();
                                                                                } catch (e) {
                                                                                  return e;
                                                                                }
                                                                              }}
                                                                            >
                                                                              <Async.Pending>
                                                                                Loading...
                                                                              </Async.Pending>
                                                                              <Async.Rejected></Async.Rejected>
                                                                              <Async.Fulfilled>
                                                                                {(
                                                                                  dx: any
                                                                                ) => {
                                                                                  return (
                                                                                    <div className="overflow-auto">
                                                                                      <small
                                                                                        style={
                                                                                          {
                                                                                            // whiteSpace:
                                                                                            //   "nowrap",
                                                                                          }
                                                                                        }
                                                                                      >
                                                                                        {/* {
                                                                                    d
                                                                                      .user
                                                                                      ?.id
                                                                                  } */}
                                                                                        {/* {locationExpandedUserIds.find(
                                                                                    (
                                                                                      id
                                                                                    ) =>
                                                                                      `${id}` ===
                                                                                      `${d.user?.id}`
                                                                                  )} */}
                                                                                        {locationExpandedUserIds.find(
                                                                                          (
                                                                                            id
                                                                                          ) =>
                                                                                            `${id}` ===
                                                                                            `${d.user?.id}`
                                                                                        )
                                                                                          ? dx?.display_name
                                                                                          : parseNominatimAddress(
                                                                                              dx
                                                                                            )}
                                                                                      </small>
                                                                                    </div>
                                                                                  );
                                                                                }}
                                                                              </Async.Fulfilled>
                                                                            </Async>
                                                                          </>
                                                                        ) : (
                                                                          <></>
                                                                        );
                                                                      })()}
                                                                    </div>
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    <Async
                                                                      promiseFn={async () => {
                                                                        try {
                                                                          const resp =
                                                                            await fetch(
                                                                              `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-mongo-last-planned/${d.user?.id}`
                                                                            );

                                                                          if (
                                                                            resp.status !==
                                                                            200
                                                                          ) {
                                                                            throw await resp.text();
                                                                          }

                                                                          return await resp.json();
                                                                        } catch (e) {
                                                                          return e;
                                                                        }
                                                                      }}
                                                                    >
                                                                      <Async.Pending>
                                                                        Loading...
                                                                      </Async.Pending>
                                                                      <Async.Rejected></Async.Rejected>
                                                                      <Async.Fulfilled>
                                                                        {(
                                                                          dx: any
                                                                        ) => {
                                                                          const tL =
                                                                            MeetingTaskList.fromPartial(
                                                                              dx
                                                                            );
                                                                          return (
                                                                            <div className="overflow-auto">
                                                                              <small>
                                                                                {
                                                                                  tL.taskStart
                                                                                }

                                                                                :
                                                                                #
                                                                                {
                                                                                  tL
                                                                                    .masterJavaBaseModel
                                                                                    ?.id
                                                                                }
                                                                              </small>
                                                                            </div>
                                                                          );
                                                                        }}
                                                                      </Async.Fulfilled>
                                                                    </Async>
                                                                  </td>
                                                                  {filteredWoTasksByDate.find(
                                                                    (mt) =>
                                                                      mt
                                                                        .journeys
                                                                        .length >
                                                                      0
                                                                  ) ? (
                                                                    <>
                                                                      <td
                                                                        className="border border-dark"
                                                                        style={{
                                                                          whiteSpace:
                                                                            "nowrap",
                                                                        }}
                                                                      >
                                                                        <div>
                                                                          <ol>
                                                                            {filteredWoTasksByDate
                                                                              .flatMap(
                                                                                (
                                                                                  mt
                                                                                ) =>
                                                                                  mt.journeys
                                                                              )
                                                                              .map(
                                                                                (
                                                                                  j
                                                                                ) => (
                                                                                  <>
                                                                                    <li>
                                                                                      <div
                                                                                        style={{
                                                                                          maxWidth: 100,
                                                                                          overflowX:
                                                                                            "scroll",
                                                                                          overflowY:
                                                                                            "hidden",
                                                                                        }}
                                                                                      >
                                                                                        <div>
                                                                                          <small>
                                                                                            <strong>
                                                                                              {
                                                                                                j.siteName
                                                                                                // ?.split(
                                                                                                //   " "
                                                                                                // )
                                                                                                // .map(
                                                                                                //   (
                                                                                                //     s
                                                                                                //   ) =>
                                                                                                //     s.substring(
                                                                                                //       0,
                                                                                                //       6
                                                                                                //     )
                                                                                                // )
                                                                                                // .join(
                                                                                                //   " "
                                                                                                // )
                                                                                              }
                                                                                            </strong>
                                                                                          </small>
                                                                                        </div>
                                                                                      </div>
                                                                                    </li>
                                                                                  </>
                                                                                )
                                                                              )}
                                                                          </ol>
                                                                        </div>
                                                                      </td>
                                                                    </>
                                                                  ) : (
                                                                    <></>
                                                                  )}
                                                                </tr>
                                                              </>
                                                            );
                                                          })}
                                                      </table>
                                                    </div>
                                                  </>
                                                );
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_PROBLEM_DETAILS
                                              ):
                                                return (
                                                  <>
                                                    <div>
                                                      <ol>
                                                        {(() => {
                                                          const probs = [
                                                            ...(deptProblemCatalogs.find(
                                                              (c) =>
                                                                `${c.extProblemCatalogId}` ===
                                                                `${deptWoFilter.extProblemCatalogId}`
                                                            )
                                                              ?.deptProblemCatalogs
                                                              .meetingTaskProblemCatalogs ??
                                                              []),
                                                          ];

                                                          probs?.reverse();

                                                          return probs ?? [];
                                                        })()
                                                          .filter(
                                                            (c) =>
                                                              meetingTaskProblemCatalogStatusFromJSON(
                                                                c.status
                                                              ) !==
                                                              MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_CLOSED
                                                          )
                                                          .map((c) => {
                                                            const foundProblemCatalog =
                                                              problemCatalogs.catalogs.find(
                                                                (cx) =>
                                                                  `${cx.masterJavaBaseModel?.id}` ===
                                                                  `${c.problemCatalogId}`
                                                              );
                                                            return (
                                                              <>
                                                                <MeetingTaskDetailById
                                                                  apiKey={
                                                                    ctx?.apiKey ??
                                                                    ""
                                                                  }
                                                                  id={
                                                                    c.meetingTaskId
                                                                  }
                                                                  users={
                                                                    ctx?.extUsers ??
                                                                    []
                                                                  }
                                                                  jobsProto={
                                                                    jobsProto
                                                                  }
                                                                  problemCatalog={
                                                                    foundProblemCatalog
                                                                  }
                                                                  meetingTaskProblemCatalog={
                                                                    c
                                                                  }
                                                                />
                                                              </>
                                                            );
                                                          })}
                                                      </ol>
                                                    </div>
                                                  </>
                                                );

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_GLOBAL_ATTENDANCE
                                              ):
                                                const notes =
                                                  attendanceNotes.filter(
                                                    (n) =>
                                                      new Date(
                                                        `${n.date}`
                                                      ).getTime() >=
                                                        new Date(
                                                          `${makeDateString(
                                                            new Date()
                                                          )}T00:00:00`
                                                        ).getTime() &&
                                                      new Date(
                                                        `${n.date}`
                                                      ).getTime() <=
                                                        new Date(
                                                          `${makeDateString(
                                                            new Date()
                                                          )}T23:59:59`
                                                        ).getTime()
                                                  );
                                                return (
                                                  <>
                                                    <div className="w-100">
                                                      <div className="d-flex justify-content-between flex-wrap w-100">
                                                        {[
                                                          ...new Set(
                                                            attendanceNoteTypes.map(
                                                              (t) =>
                                                                t.uniqIdentifier
                                                            )
                                                          ),
                                                        ]
                                                          .map((i) =>
                                                            attendanceNoteTypes.find(
                                                              (a) =>
                                                                `${a.uniqIdentifier}` ===
                                                                `${i}`
                                                            )
                                                          )
                                                          .map((t) => {
                                                            return (
                                                              <>
                                                                <div className="m-1 px-1 border border-dark rounded rounded-md font-weight-bold">
                                                                  {t?.alias
                                                                    ? t.alias
                                                                    : t?.name}
                                                                  :{" "}
                                                                  {
                                                                    notes.filter(
                                                                      (n) =>
                                                                        `${n.attendanceNoteType?.uniqIdentifier}` ===
                                                                        `${t?.uniqIdentifier}`
                                                                    ).length
                                                                  }
                                                                </div>
                                                              </>
                                                            );
                                                          })}
                                                        <div className="m-1 px-1 border border-dark rounded rounded-md font-weight-bold">
                                                          Assistance: 0
                                                        </div>
                                                        <div className="m-1 px-1 border border-dark rounded rounded-md font-weight-bold">
                                                          Total MP (GSPE):{" "}
                                                          {
                                                            ctx?.extUsers.filter(
                                                              (u) => !u.isSubcon
                                                            ).length
                                                          }
                                                        </div>
                                                        <div className="m-1 px-1 border border-dark rounded rounded-md font-weight-bold">
                                                          Total MP (Subcon):{" "}
                                                          {
                                                            ctx?.extUsers.filter(
                                                              (u) => u.isSubcon
                                                            ).length
                                                          }
                                                        </div>
                                                        <div className="m-1 px-1 border border-dark rounded rounded-md font-weight-bold">
                                                          Alpha (GSPE):{" "}
                                                          {
                                                            ctx?.extUsers.filter(
                                                              (u) =>
                                                                !u.excludeInGlobalAttendance &&
                                                                !u.isSubcon &&
                                                                !(
                                                                  checkIns
                                                                    .find(
                                                                      (c) =>
                                                                        c.date ===
                                                                        makeDateString(
                                                                          new Date()
                                                                        )
                                                                    )
                                                                    ?.checkIn.find(
                                                                      (c) =>
                                                                        `${c.user?.id}` ===
                                                                        `${u.id}`
                                                                    )
                                                                    ?.firstLocation ||
                                                                  notes.find(
                                                                    (n) =>
                                                                      `${n.extUserId}` ===
                                                                      `${u.id}`
                                                                  )
                                                                ) &&
                                                                !shifts.current.shifts.find(
                                                                  (s) =>
                                                                    s.date ===
                                                                      makeDateString(
                                                                        new Date()
                                                                      ) &&
                                                                    `${s.userId}` ===
                                                                      `${u.id}`
                                                                )
                                                            ).length
                                                          }
                                                        </div>
                                                        <div className="m-1 px-1 border border-dark rounded rounded-md font-weight-bold">
                                                          Alpha (Subcon):{" "}
                                                          {
                                                            ctx?.extUsers.filter(
                                                              (u) =>
                                                                !u.excludeInGlobalAttendance &&
                                                                u.isSubcon &&
                                                                !(
                                                                  checkIns
                                                                    .find(
                                                                      (c) =>
                                                                        c.date ===
                                                                        makeDateString(
                                                                          new Date()
                                                                        )
                                                                    )
                                                                    ?.checkIn.find(
                                                                      (c) =>
                                                                        `${c.user?.id}` ===
                                                                        `${u.id}`
                                                                    )
                                                                    ?.firstLocation ||
                                                                  notes.find(
                                                                    (n) =>
                                                                      `${n.extUserId}` ===
                                                                      `${u.id}`
                                                                  )
                                                                )
                                                            ).length
                                                          }
                                                        </div>
                                                      </div>
                                                      <div className="m-1 px-1 border border-dark rounded rounded-md ">
                                                        {(() => {
                                                          const alphaUsers =
                                                            ctx?.extUsers.filter(
                                                              (u) =>
                                                                // Does not have custom shift
                                                                !shifts.current.shifts.find(
                                                                  (s) =>
                                                                    s.date ===
                                                                      makeDateString(
                                                                        new Date()
                                                                      ) &&
                                                                    `${s.userId}` ===
                                                                      `${u.id}`
                                                                ) &&
                                                                !u.excludeInGlobalAttendance &&
                                                                checkIns.length >
                                                                  0 &&
                                                                !(
                                                                  // check in found

                                                                  (
                                                                    checkIns
                                                                      .find(
                                                                        (c) =>
                                                                          `${
                                                                            c.date ===
                                                                            makeDateString(
                                                                              new Date()
                                                                            )
                                                                          }`
                                                                      )
                                                                      ?.checkIn.find(
                                                                        (c) =>
                                                                          `${c.user?.id}` ===
                                                                          `${u.id}`
                                                                      )
                                                                      ?.firstLocation ||
                                                                    // attendance note found
                                                                    notes.find(
                                                                      (n) =>
                                                                        `${n.extUserId}` ===
                                                                        `${u.id}`
                                                                    )
                                                                  )
                                                                )
                                                            );
                                                          const alphaGspe =
                                                            alphaUsers?.filter(
                                                              (u) => !u.isSubcon
                                                            );
                                                          const alphaSubcon =
                                                            alphaUsers?.filter(
                                                              (u) => u.isSubcon
                                                            );

                                                          return (
                                                            <>
                                                              {popupAlphaAttendance ? (
                                                                <>
                                                                  <div
                                                                    onClick={() => {
                                                                      setPopupAlphaAttendance(
                                                                        !popupAlphaAttendance
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    <div className="font-weight-bold text-danger border border-dark rounded rounded-md mx-1">
                                                                      GSPE:{" "}
                                                                      {alphaGspe
                                                                        ?.map(
                                                                          (u) =>
                                                                            u.username
                                                                        )
                                                                        .join(
                                                                          ", "
                                                                        )}
                                                                    </div>
                                                                    <div className="font-weight-bold text-danger border border-dark rounded rounded-md mx-1">
                                                                      Subcon:{" "}
                                                                      {alphaSubcon
                                                                        ?.map(
                                                                          (u) =>
                                                                            u.username
                                                                        )
                                                                        .join(
                                                                          ", "
                                                                        )}
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <button
                                                                    className="btn btn-sm btn-primary"
                                                                    onClick={() => {
                                                                      setPopupAlphaAttendance(
                                                                        !popupAlphaAttendance
                                                                      );
                                                                    }}
                                                                  >
                                                                    <small>
                                                                      Toggle
                                                                      Alpha
                                                                      Popup
                                                                    </small>
                                                                  </button>
                                                                </>
                                                              )}
                                                            </>
                                                          );
                                                        })()}
                                                      </div>
                                                      <div>
                                                        Custom shift:{" "}
                                                        {
                                                          shifts.current.shifts.filter(
                                                            (s) =>
                                                              `${s.date}` ===
                                                              `${makeDateString(
                                                                new Date()
                                                              )}`
                                                          ).length
                                                        }
                                                      </div>
                                                      <div className="d-flex flex-wrap">
                                                        {shifts.current.shifts
                                                          .filter(
                                                            (s) =>
                                                              `${s.date}` ===
                                                              `${makeDateString(
                                                                new Date()
                                                              )}`
                                                          )
                                                          .map((s) => {
                                                            const foundShiftTemplate =
                                                              shiftTemplates.current.templates.find(
                                                                (t) =>
                                                                  t
                                                                    .masterJavaBaseModel
                                                                    ?.id ===
                                                                  s.shiftTemplateId
                                                              );

                                                            return (
                                                              <>
                                                                <div className="border border-dark px-1">
                                                                  {
                                                                    ctx?.extUsers.find(
                                                                      (u) =>
                                                                        `${u.id}` ===
                                                                        `${s.userId}`
                                                                    )?.username
                                                                  }{" "}
                                                                  ={" "}
                                                                  {s.shift ? (
                                                                    <>
                                                                      <strong>
                                                                        {
                                                                          foundShiftTemplate?.fromHour
                                                                        }
                                                                        :
                                                                        {
                                                                          foundShiftTemplate?.fromMins
                                                                        }
                                                                        -
                                                                        {
                                                                          foundShiftTemplate?.toHour
                                                                        }
                                                                        :
                                                                        {
                                                                          foundShiftTemplate?.toMins
                                                                        }
                                                                      </strong>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <span className="text-danger">
                                                                        <strong>
                                                                          Off
                                                                          duty
                                                                        </strong>
                                                                      </span>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </>
                                                            );
                                                          })}
                                                      </div>

                                                      <hr className="border border-dark" />
                                                      <table
                                                        className="table table-sm"
                                                        style={{
                                                          borderCollapse:
                                                            "separate",
                                                        }}
                                                      >
                                                        <tr>
                                                          {[
                                                            `# (Total ${notes.length})`,
                                                            "Type",
                                                            "Time",
                                                            "PIC",
                                                            "Approved by",
                                                            "Created",
                                                            "Explanation",
                                                          ].map((n) => {
                                                            return (
                                                              <>
                                                                <th className="bg-dark text-light">
                                                                  {n}
                                                                </th>
                                                              </>
                                                            );
                                                          })}
                                                        </tr>

                                                        {notes.map((n, i) => {
                                                          return (
                                                            <>
                                                              <tr className=" p-0 m-0">
                                                                <td className="border border-dark p-0 m-0">
                                                                  {i + 1}
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {
                                                                    n
                                                                      .attendanceNoteType
                                                                      ?.name
                                                                  }
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {(() => {
                                                                    try {
                                                                      const d =
                                                                        new Date(
                                                                          n.date ??
                                                                            ""
                                                                        );

                                                                      return `${d.getHours()}:${d.getMinutes()}`;
                                                                    } catch (e) {
                                                                      return "Date invalid";
                                                                    }
                                                                  })()}
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {
                                                                    ctx?.extUsers.find(
                                                                      (u) =>
                                                                        `${u.id}` ===
                                                                        `${n.extUserId}`
                                                                    )?.username
                                                                  }
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {
                                                                    ctx?.extUsers.find(
                                                                      (u) =>
                                                                        `${u.id}` ===
                                                                        `${n.extHrApprovedById}`
                                                                    )?.username
                                                                  }
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {(() => {
                                                                    try {
                                                                      const d =
                                                                        new Date(
                                                                          n.createdAt ??
                                                                            ""
                                                                        );

                                                                      return `${Intl.DateTimeFormat(
                                                                        navigator.language ??
                                                                          "en-US",
                                                                        {
                                                                          dateStyle:
                                                                            "medium",
                                                                        } as any
                                                                      ).format(
                                                                        d
                                                                      )} ${d.getHours()}:${d.getMinutes()}`;
                                                                    } catch (e) {
                                                                      return "Date invalid";
                                                                    }
                                                                  })()}
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {n.note}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          );
                                                        })}
                                                      </table>
                                                    </div>
                                                  </>
                                                );
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_JOB
                                              ):
                                                return (
                                                  <>
                                                    <>
                                                      <div className="flex-fill">
                                                        <table
                                                          className="table table-sm border border-dark"
                                                          style={{
                                                            borderCollapse:
                                                              "separate",
                                                            tableLayout:
                                                              "fixed",
                                                          }}
                                                        >
                                                          <tr>
                                                            <th
                                                              className={`${`bg-dark text-light`}`}
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                            >
                                                              <small>
                                                                <strong>
                                                                  Job week {w}
                                                                </strong>
                                                              </small>
                                                            </th>
                                                            <th
                                                              className={`${`bg-dark text-light`}`}
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                            >
                                                              <strong>
                                                                Product week {w}{" "}
                                                                (
                                                                {Intl.DateTimeFormat(
                                                                  "en-US",
                                                                  {
                                                                    dateStyle:
                                                                      "short",
                                                                  } as any
                                                                ).format(
                                                                  weeks?.[i]
                                                                    ?.from
                                                                )}{" "}
                                                                -{" "}
                                                                {Intl.DateTimeFormat(
                                                                  "en-US",
                                                                  {
                                                                    dateStyle:
                                                                      "short",
                                                                  } as any
                                                                ).format(
                                                                  weeks?.[i]?.to
                                                                )}
                                                                )
                                                              </strong>
                                                            </th>
                                                            <th
                                                              className={`${`bg-dark text-light`}`}
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                            >
                                                              <small>
                                                                Tasks
                                                              </small>
                                                            </th>
                                                            <th
                                                              className={`${`bg-dark text-light`}`}
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                            >
                                                              <small>
                                                                Deadline
                                                              </small>
                                                            </th>
                                                            <th
                                                              className={`bg-dark text-light`}
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                            >
                                                              {" "}
                                                              <small>
                                                                Man days /8
                                                              </small>
                                                            </th>
                                                            <th
                                                              className={`bg-dark text-light`}
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                            >
                                                              {" "}
                                                              <small>
                                                                WO assigned
                                                              </small>
                                                            </th>
                                                            <th
                                                              className={`bg-dark text-light`}
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                            >
                                                              {" "}
                                                              <small>
                                                                WO Done
                                                              </small>
                                                            </th>
                                                          </tr>
                                                          <>
                                                            <>
                                                              {(() => {
                                                                return (() => {
                                                                  const extPanelCodeIds =
                                                                    [
                                                                      ...new Set(
                                                                        woJob.taskLists.map(
                                                                          (
                                                                            tL
                                                                          ) =>
                                                                            tL
                                                                              .taskList
                                                                              ?.extPanelCodeId ??
                                                                            ""
                                                                        )
                                                                      ),
                                                                    ];

                                                                  extPanelCodeIds.sort(
                                                                    (a, b) => {
                                                                      const jobA =
                                                                        jobsProto?.jobs.find(
                                                                          (j) =>
                                                                            j.panelCodes.find(
                                                                              (
                                                                                pc
                                                                              ) =>
                                                                                `${pc.masterJavaBaseModel?.id}` ===
                                                                                `${a}`
                                                                            )
                                                                        )
                                                                          ?.name ??
                                                                        "";

                                                                      const jobB =
                                                                        jobsProto?.jobs.find(
                                                                          (j) =>
                                                                            j.panelCodes.find(
                                                                              (
                                                                                pc
                                                                              ) =>
                                                                                `${pc.masterJavaBaseModel?.id}` ===
                                                                                `${b}`
                                                                            )
                                                                        )
                                                                          ?.name ??
                                                                        "";

                                                                      const panelCodeA =
                                                                        jobsProto?.jobs
                                                                          .map(
                                                                            (
                                                                              j
                                                                            ) =>
                                                                              j.panelCodes ??
                                                                              []
                                                                          )
                                                                          .flat()
                                                                          .find(
                                                                            (
                                                                              pc
                                                                            ) =>
                                                                              `${pc.masterJavaBaseModel?.id}` ===
                                                                              `${a}`
                                                                          )
                                                                          ?.name ??
                                                                        "";
                                                                      const panelCodeB =
                                                                        jobsProto?.jobs
                                                                          .map(
                                                                            (
                                                                              j
                                                                            ) =>
                                                                              j.panelCodes ??
                                                                              []
                                                                          )
                                                                          .flat()
                                                                          .find(
                                                                            (
                                                                              pc
                                                                            ) =>
                                                                              `${pc.masterJavaBaseModel?.id}` ===
                                                                              `${b}`
                                                                          )
                                                                          ?.name ??
                                                                        "";

                                                                      return (
                                                                        jobA.localeCompare(
                                                                          jobB
                                                                        ) ||
                                                                        panelCodeA.localeCompare(
                                                                          panelCodeB
                                                                        )
                                                                      );
                                                                    }
                                                                  );

                                                                  return extPanelCodeIds;
                                                                })().map(
                                                                  (pci) => {
                                                                    const foundPci =
                                                                      jobsProto?.jobs
                                                                        .map(
                                                                          (j) =>
                                                                            j.panelCodes
                                                                        )
                                                                        .flat()
                                                                        .find(
                                                                          (
                                                                            pc
                                                                          ) =>
                                                                            `${pc?.masterJavaBaseModel?.id}` ===
                                                                            `${pci}`
                                                                        );

                                                                    const foundJob =
                                                                      jobsProto?.jobs.find(
                                                                        (j) =>
                                                                          j.panelCodes?.find(
                                                                            (
                                                                              pc
                                                                            ) =>
                                                                              `${pc.masterJavaBaseModel?.id}` ===
                                                                              `${pci}`
                                                                          )
                                                                      );

                                                                    const wos: MeetingTaskListView[] =
                                                                      wosFilteredByDate.filter(
                                                                        (tL) =>
                                                                          tL
                                                                            .taskList
                                                                            ?.extPanelCodeSerialNumberId &&
                                                                          tL
                                                                            .taskList
                                                                            ?.extPanelCodeSerialNumberId !==
                                                                            "" &&
                                                                          tL
                                                                            .taskList
                                                                            ?.extPanelCodeSerialNumberId !==
                                                                            "0"
                                                                            ? foundPci?.serialNumbers?.find(
                                                                                (
                                                                                  sn
                                                                                ) =>
                                                                                  `${sn.masterJavaBaseModel?.id}` ===
                                                                                  `${tL.taskList?.extPanelCodeSerialNumberId}`
                                                                              )
                                                                            : `${tL.taskList?.extPanelCodeId}` ===
                                                                              `${pci}`
                                                                      );

                                                                    const filteredWoTasksByDate =
                                                                      wos
                                                                        .map(
                                                                          (
                                                                            tL
                                                                          ) =>
                                                                            tL
                                                                              .taskList
                                                                              ?.meetingTasks
                                                                        )
                                                                        .flat()
                                                                        .filter(
                                                                          (
                                                                            mt
                                                                          ) =>
                                                                            // If single Date, filter date
                                                                            weeks?.[
                                                                              i
                                                                            ]
                                                                              ?.from &&
                                                                            weeks?.[
                                                                              i
                                                                            ]
                                                                              ?.to &&
                                                                            makeDateString(
                                                                              weeks?.[
                                                                                i
                                                                              ]
                                                                                .from
                                                                            ) ===
                                                                              makeDateString(
                                                                                weeks?.[
                                                                                  i
                                                                                ]
                                                                                  .to
                                                                              )
                                                                              ? makeDateString(
                                                                                  weeks?.[
                                                                                    i
                                                                                  ]
                                                                                    .from
                                                                                ) <=
                                                                                  (extractProtoMeetingTaskTargetDate(
                                                                                    mt
                                                                                  )
                                                                                    ?.date ??
                                                                                    "") &&
                                                                                makeDateString(
                                                                                  weeks?.[
                                                                                    i
                                                                                  ]
                                                                                    .to
                                                                                ) >=
                                                                                  (mt?.start ??
                                                                                    "")
                                                                              : true
                                                                        );

                                                                    const tasksComplete =
                                                                      filteredWoTasksByDate.filter(
                                                                        (mt) =>
                                                                          mt?.status ===
                                                                          "COMPLETED"
                                                                      );

                                                                    const tasksCompletePic =
                                                                      filteredWoTasksByDate.filter(
                                                                        (mt) =>
                                                                          mt?.extPicCompletedDate
                                                                      );

                                                                    const wosPercentage =
                                                                      ((tasksComplete.length ??
                                                                        0) /
                                                                        (wos
                                                                          .map(
                                                                            (
                                                                              tL
                                                                            ) =>
                                                                              tL
                                                                                .taskList
                                                                                ?.meetingTasks
                                                                          )
                                                                          .flat()
                                                                          .length ??
                                                                          1)) *
                                                                      100;

                                                                    const chromaColor =
                                                                      chroma
                                                                        .scale([
                                                                          "lightsalmon",
                                                                          "yellow",
                                                                          "lightgreen",
                                                                        ])(
                                                                          tasksComplete.length /
                                                                            filteredWoTasksByDate.length
                                                                        )
                                                                        .hex();
                                                                    const chromaColorPic =
                                                                      chroma
                                                                        .scale([
                                                                          "lightsalmon",
                                                                          "yellow",
                                                                          "lightgreen",
                                                                        ])(
                                                                          tasksCompletePic.length /
                                                                            filteredWoTasksByDate.length
                                                                        )
                                                                        .hex();

                                                                    const foundPanelCodeInfo =
                                                                      jobInfo.info
                                                                        .map(
                                                                          (j) =>
                                                                            j
                                                                              .job
                                                                              ?.panelCodes
                                                                        )
                                                                        .flat()
                                                                        .find(
                                                                          (c) =>
                                                                            `${c?.masterJavaBaseModel?.id}` ===
                                                                            `${pci}`
                                                                        );
                                                                    const totalPanelCodeTimeMins =
                                                                      (foundPanelCodeInfo?.programTreeTime ??
                                                                        0) +
                                                                      (foundPanelCodeInfo?.productTreeTime ??
                                                                        0) +
                                                                      (foundPanelCodeInfo?.deptTemplateTime ??
                                                                        0);

                                                                    return (wos?.length ??
                                                                      0) > 0 ? (
                                                                      <>
                                                                        <tr>
                                                                          <td
                                                                            className="border border-dark"
                                                                            style={{
                                                                              overflowWrap:
                                                                                "break-word",
                                                                            }}
                                                                          >
                                                                            <div>
                                                                              {
                                                                                foundJob?.name
                                                                              }
                                                                            </div>
                                                                            <div>
                                                                              {(foundPci
                                                                                ?.remarks
                                                                                .length ??
                                                                                0) >
                                                                              0 ? (
                                                                                <>
                                                                                  {" "}
                                                                                  <ol>
                                                                                    {foundPci?.remarks
                                                                                      .filter(
                                                                                        (
                                                                                          r
                                                                                        ) =>
                                                                                          !r.done
                                                                                      )
                                                                                      .map(
                                                                                        (
                                                                                          r
                                                                                        ) => {
                                                                                          return (
                                                                                            <>
                                                                                              <li>
                                                                                                <div>
                                                                                                  <small>
                                                                                                    {
                                                                                                      r.remark
                                                                                                    }
                                                                                                  </small>
                                                                                                </div>
                                                                                              </li>
                                                                                            </>
                                                                                          );
                                                                                        }
                                                                                      )}
                                                                                  </ol>
                                                                                </>
                                                                              ) : (
                                                                                <>

                                                                                </>
                                                                              )}
                                                                            </div>
                                                                          </td>
                                                                          <td
                                                                            className="border border-dark"
                                                                            style={{
                                                                              overflowWrap:
                                                                                "break-word",
                                                                            }}
                                                                          >
                                                                            {
                                                                              foundPci?.type
                                                                            }
                                                                            :{" "}
                                                                            {
                                                                              foundPci?.name
                                                                            }
                                                                            :{" "}
                                                                            {
                                                                              foundPci?.qty
                                                                            }{" "}
                                                                            unit(s)
                                                                            <small>
                                                                              {[
                                                                                ...new Set(
                                                                                  wos
                                                                                    .map(
                                                                                      (
                                                                                        tL
                                                                                      ) =>
                                                                                        tL.taskList?.meetingTasks
                                                                                          .map(
                                                                                            (
                                                                                              mt
                                                                                            ) =>
                                                                                              mt.meetingTaskInCharges.map(
                                                                                                (
                                                                                                  mtic
                                                                                                ) =>
                                                                                                  mtic.extUserId
                                                                                              )
                                                                                          )
                                                                                          .flat()
                                                                                    )
                                                                                    .flat()
                                                                                ),
                                                                              ]
                                                                                .map(
                                                                                  (
                                                                                    id
                                                                                  ) => {
                                                                                    const fUser =
                                                                                      ctx?.extUsers.find(
                                                                                        (
                                                                                          u
                                                                                        ) =>
                                                                                          `${u.id}` ===
                                                                                          `${id}`
                                                                                      );
                                                                                    return `${fUser?.username}`;
                                                                                  }
                                                                                )

                                                                                .join(
                                                                                  ", "
                                                                                )}{" "}
                                                                              [workdate{" "}
                                                                              {foundPanelCodeInfo?.firstPanelCodeWorkOrderDate ??
                                                                                "not found"}
                                                                              ]
                                                                            </small>
                                                                          </td>
                                                                          <td
                                                                            className="border border-dark font-weight-bold"
                                                                            style={{
                                                                              backgroundColor:
                                                                                chromaColor,
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                              console.log(
                                                                                "Selected week range:",
                                                                                weeks?.[
                                                                                  i
                                                                                ]
                                                                              );
                                                                              setSelectedWeekRange(
                                                                                weeks?.[
                                                                                  i
                                                                                ]
                                                                              );
                                                                              setTaskDetailsShow(
                                                                                true
                                                                              );

                                                                              if (
                                                                                foundPci
                                                                              ) {
                                                                                setModalTasks(
                                                                                  woJob.taskLists.filter(
                                                                                    (
                                                                                      tL
                                                                                    ) =>
                                                                                      `${tL.taskList?.extPanelCodeId}` ===
                                                                                      `${foundPci.masterJavaBaseModel?.id}`
                                                                                  )
                                                                                );
                                                                              }
                                                                            }}
                                                                          >
                                                                            <div className="d-flex justify-content-between">
                                                                              <div>
                                                                                {
                                                                                  tasksComplete.length
                                                                                }

                                                                                /
                                                                                {
                                                                                  filteredWoTasksByDate.length
                                                                                }{" "}
                                                                                (
                                                                                {wosPercentage?.toFixed(
                                                                                  0
                                                                                )}
                                                                                %)
                                                                              </div>
                                                                              <div>
                                                                                <small className="border border-dark px-1 shadow shadow-md text-dark rounded rounded-md">
                                                                                  <strong
                                                                                    style={{
                                                                                      backgroundColor:
                                                                                        chromaColorPic,
                                                                                    }}
                                                                                  >
                                                                                    PIC:{" "}
                                                                                    {
                                                                                      tasksCompletePic.length
                                                                                    }
                                                                                  </strong>
                                                                                </small>
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                          <td
                                                                            className="border border-dark"
                                                                            style={{
                                                                              backgroundColor:
                                                                                foundPci?.deadline
                                                                                  ? (() => {
                                                                                      const productDeadlineTimestamp =
                                                                                        new Date(
                                                                                          foundPci.deadline
                                                                                        ).getTime();

                                                                                      const dateNowTimestamp =
                                                                                        new Date(
                                                                                          makeDateString(
                                                                                            new Date()
                                                                                          )
                                                                                        ).getTime();

                                                                                      if (
                                                                                        productDeadlineTimestamp <=
                                                                                        dateNowTimestamp
                                                                                      ) {
                                                                                        if (
                                                                                          snapInterval
                                                                                        ) {
                                                                                          return `red`;
                                                                                        } else {
                                                                                          return "";
                                                                                        }
                                                                                      } else {
                                                                                        return "";
                                                                                      }
                                                                                    })()
                                                                                  : undefined,
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              {foundPci?.deadline
                                                                                ? Intl.DateTimeFormat(
                                                                                    navigator.language ??
                                                                                      "en-US",
                                                                                    {
                                                                                      dateStyle:
                                                                                        "long",
                                                                                    }
                                                                                  ).format(
                                                                                    new Date(
                                                                                      foundPci.deadline
                                                                                    )
                                                                                  )
                                                                                : ""}
                                                                            </strong>
                                                                          </td>
                                                                          <td className="border border-dark">
                                                                            {(
                                                                              totalPanelCodeTimeMins /
                                                                              60 /
                                                                              8
                                                                            )?.toFixed(
                                                                              1
                                                                            )}{" "}
                                                                            d (
                                                                            {(
                                                                              totalPanelCodeTimeMins /
                                                                              60
                                                                            )?.toFixed(
                                                                              1
                                                                            )}{" "}
                                                                            h)
                                                                          </td>
                                                                          <td className="border border-dark">
                                                                            {(
                                                                              (foundPanelCodeInfo?.timeWoAssigned ??
                                                                                0) /
                                                                              60 /
                                                                              8
                                                                            )?.toFixed(
                                                                              1
                                                                            )}{" "}
                                                                            d (
                                                                            {(
                                                                              (foundPanelCodeInfo?.timeWoAssigned ??
                                                                                0) /
                                                                              60
                                                                            )?.toFixed(
                                                                              1
                                                                            )}{" "}
                                                                            h)
                                                                          </td>
                                                                          <td className="border border-dark">
                                                                            {(
                                                                              (foundPanelCodeInfo?.timeWoDone ??
                                                                                0) /
                                                                              60 /
                                                                              8
                                                                            )?.toFixed(
                                                                              1
                                                                            )}{" "}
                                                                            d (
                                                                            {(
                                                                              (foundPanelCodeInfo?.timeWoDone ??
                                                                                0) /
                                                                              60
                                                                            )?.toFixed(
                                                                              1
                                                                            )}{" "}
                                                                            h)
                                                                          </td>
                                                                        </tr>
                                                                      </>
                                                                    ) : (
                                                                      <></>
                                                                    );
                                                                  }
                                                                );
                                                              })()}
                                                            </>
                                                          </>
                                                        </table>
                                                      </div>
                                                    </>
                                                  </>
                                                );
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_DETAILS
                                              ):
                                                return (
                                                  <>
                                                    <div className="flex-fill">
                                                      <table
                                                        className="table table-sm"
                                                        style={{
                                                          borderCollapse:
                                                            "separate",
                                                        }}
                                                      >
                                                        <tr>
                                                          {[
                                                            "#",
                                                            "WO ID",
                                                            "PICs",
                                                            `Task (${
                                                              woJobs?.[
                                                                i
                                                              ]?.taskLists.map(
                                                                (tL) =>
                                                                  tL.taskList?.meetingTasks.map(
                                                                    (mt) =>
                                                                      mt
                                                                        .masterJavaBaseModel
                                                                        ?.id
                                                                  )
                                                              ).length
                                                            })`,
                                                            `Start (${(() => {
                                                              try {
                                                                return makeDateString(
                                                                  new Date(
                                                                    weeks?.[i]
                                                                      ?.from ??
                                                                      ""
                                                                  )
                                                                );
                                                              } catch (e) {
                                                                return "";
                                                              }
                                                            })()})`,
                                                            `End (${(() => {
                                                              try {
                                                                return makeDateString(
                                                                  new Date(
                                                                    weeks?.[i]
                                                                      ?.to ?? ""
                                                                  )
                                                                );
                                                              } catch (e) {
                                                                return "";
                                                              }
                                                            })()})`,
                                                            "Set/Sub/Module",
                                                          ].map((h) => (
                                                            <>
                                                              <th
                                                                className="bg-dark text-light"
                                                                style={{
                                                                  position:
                                                                    "sticky",
                                                                  top: 0,
                                                                }}
                                                              >
                                                                {h}
                                                              </th>
                                                            </>
                                                          ))}
                                                        </tr>
                                                        {woJobs?.[i]?.taskLists
                                                          .map((tL) =>
                                                            tL.taskList?.meetingTasks.map(
                                                              (mt) => ({
                                                                taskList: tL,
                                                                meetingTask: mt,
                                                              })
                                                            )
                                                          )
                                                          .flat()
                                                          .filter((mt) => {
                                                            // If single Date, filter date
                                                            return weeks?.[i]
                                                              ?.from &&
                                                              weeks?.[i]?.to &&
                                                              makeDateString(
                                                                weeks?.[i].from
                                                              ) ===
                                                                makeDateString(
                                                                  weeks?.[i].to
                                                                )
                                                              ? makeDateString(
                                                                  weeks?.[i]
                                                                    .from
                                                                ) <=
                                                                  (extractProtoMeetingTaskTargetDate(
                                                                    mt?.meetingTask
                                                                  )?.date ??
                                                                    "") &&
                                                                  makeDateString(
                                                                    weeks?.[i]
                                                                      .to
                                                                  ) >=
                                                                    (mt
                                                                      ?.meetingTask
                                                                      ?.start ??
                                                                      "")
                                                              : true;
                                                          })
                                                          .map((mt, i) => {
                                                            return (
                                                              <>
                                                                <tr>
                                                                  <td className="border border-dark">
                                                                    {i + 1}
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    <small>
                                                                      {
                                                                        mt
                                                                          ?.taskList
                                                                          .taskList
                                                                          ?.masterJavaBaseModel
                                                                          ?.id
                                                                      }
                                                                    </small>
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    <small>
                                                                      {[
                                                                        ...new Set(
                                                                          mt?.meetingTask.meetingTaskInCharges.map(
                                                                            (
                                                                              ic
                                                                            ) =>
                                                                              ic.extUserId
                                                                          )
                                                                        ),
                                                                      ]
                                                                        .map(
                                                                          (
                                                                            id
                                                                          ) =>
                                                                            `${
                                                                              ctx?.extUsers.find(
                                                                                (
                                                                                  u
                                                                                ) =>
                                                                                  `${u.id}` ===
                                                                                  `${id}`
                                                                              )
                                                                                ?.username
                                                                            }`
                                                                        )
                                                                        .join(
                                                                          ", "
                                                                        )}
                                                                    </small>
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    {
                                                                      mt
                                                                        ?.meetingTask
                                                                        .description
                                                                    }
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    {
                                                                      mt
                                                                        ?.meetingTask
                                                                        .start
                                                                    }
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    {
                                                                      extractProtoMeetingTaskTargetDate(
                                                                        mt?.meetingTask
                                                                      )?.date
                                                                    }
                                                                  </td>
                                                                </tr>
                                                              </>
                                                            );
                                                          })}
                                                      </table>
                                                    </div>
                                                  </>
                                                );

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_DEPT_TEMPLATE
                                              ):
                                                return (
                                                  <>
                                                    <div>
                                                      {(() => {
                                                        const job =
                                                          jobDepartmentTemplates.find(
                                                            (j) =>
                                                              `${j.id}` ===
                                                              `${deptWoFilter.jobId}`
                                                          );

                                                        return (
                                                          <>
                                                            {job?.panelCodes?.map(
                                                              (pc, j) => {
                                                                return (
                                                                  <>
                                                                    <div>
                                                                      <div>
                                                                        {j + 1}.{" "}
                                                                        {
                                                                          pc.type
                                                                        }
                                                                        :{" "}
                                                                        {
                                                                          pc.name
                                                                        }
                                                                        :{" "}
                                                                        {pc.qty}{" "}
                                                                        unit(s)
                                                                      </div>
                                                                      <div>
                                                                        {pc.panelCodeDepartmentTemplates?.map(
                                                                          (
                                                                            t
                                                                          ) => {
                                                                            const dt =
                                                                              departmentTemplates?.templates.find(
                                                                                (
                                                                                  tx
                                                                                ) =>
                                                                                  `${tx.masterJavaBaseModel?.id}` ===
                                                                                  `${t.departmentTemplate?.id}`
                                                                              );

                                                                            return (
                                                                              <>
                                                                                <div>
                                                                                  <div>
                                                                                    <strong>
                                                                                      {
                                                                                        t
                                                                                          .departmentTemplate
                                                                                          ?.name
                                                                                      }
                                                                                    </strong>
                                                                                  </div>
                                                                                  <div className="d-flex">
                                                                                    {dt?.items.map(
                                                                                      (
                                                                                        i
                                                                                      ) => {
                                                                                        const foundPCDTI =
                                                                                          pc.panelCodeDepartmentTemplateItems?.find(
                                                                                            (
                                                                                              ix
                                                                                            ) =>
                                                                                              `${ix.departmentTemplateItem?.id}` ===
                                                                                              `${i.masterJavaBaseModel?.id}`
                                                                                          );

                                                                                        return (
                                                                                          <>
                                                                                            <div
                                                                                              style={{
                                                                                                backgroundColor:
                                                                                                  foundPCDTI?.excluded
                                                                                                    ? `gray`
                                                                                                    : foundPCDTI?.completedDate
                                                                                                    ? `lightgreen`
                                                                                                    : `white`,
                                                                                              }}
                                                                                              className="border border-dark rounded rounded-md mx-1"
                                                                                            >
                                                                                              <div>
                                                                                                <small>
                                                                                                  <strong>
                                                                                                    {
                                                                                                      i.name
                                                                                                    }
                                                                                                  </strong>
                                                                                                </small>
                                                                                              </div>
                                                                                              <div>
                                                                                                <small>
                                                                                                  <strong>
                                                                                                    {(() => {
                                                                                                      try {
                                                                                                        return Intl.DateTimeFormat(
                                                                                                          "en-US",
                                                                                                          {
                                                                                                            dateStyle:
                                                                                                              "short",
                                                                                                          } as any
                                                                                                        ).format(
                                                                                                          new Date(
                                                                                                            foundPCDTI?.startDate?.split(
                                                                                                              "T"
                                                                                                            )?.[0] ??
                                                                                                              ""
                                                                                                          )
                                                                                                        );
                                                                                                      } catch (e) {
                                                                                                        return "";
                                                                                                      }
                                                                                                    })()}{" "}
                                                                                                    -{" "}
                                                                                                    {(() => {
                                                                                                      try {
                                                                                                        return Intl.DateTimeFormat(
                                                                                                          "en-US",
                                                                                                          {
                                                                                                            dateStyle:
                                                                                                              "short",
                                                                                                          } as any
                                                                                                        ).format(
                                                                                                          new Date(
                                                                                                            foundPCDTI?.endDate?.split(
                                                                                                              "T"
                                                                                                            )?.[0] ??
                                                                                                              ""
                                                                                                          )
                                                                                                        );
                                                                                                      } catch (e) {
                                                                                                        return "";
                                                                                                      }
                                                                                                    })()}
                                                                                                  </strong>
                                                                                                </small>
                                                                                              </div>
                                                                                            </div>
                                                                                          </>
                                                                                        );
                                                                                      }
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              </>
                                                                            );
                                                                          }
                                                                        )}
                                                                      </div>
                                                                      <div>
                                                                        <hr className="border border-dark" />
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                          </>
                                                        );
                                                      })()}
                                                    </div>
                                                  </>
                                                );

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_JOB_OVERVIEW
                                              ):
                                                return (
                                                  <>
                                                    <div className="flex-fill">
                                                      <table
                                                        className="table table-sm border border-dark"
                                                        style={{
                                                          borderCollapse:
                                                            "separate",
                                                          tableLayout: "fixed",
                                                        }}
                                                      >
                                                        <tr>
                                                          {[
                                                            "#",
                                                            "Cust",
                                                            "Job",
                                                            "Qty",
                                                            "D/L",
                                                            "Status",
                                                            "Prio",
                                                            "M.Days",
                                                            "Details",
                                                          ].map((h) => {
                                                            return (
                                                              <>
                                                                <th
                                                                  className="bg-dark text-light"
                                                                  style={{
                                                                    position:
                                                                      "sticky",
                                                                    top: 0,
                                                                  }}
                                                                >
                                                                  {h}
                                                                </th>
                                                              </>
                                                            );
                                                          })}
                                                        </tr>
                                                        {jobOverview.jobs.map(
                                                          (j, i) => {
                                                            return (
                                                              <>
                                                                <tr>
                                                                  <td className="border border-dark">
                                                                    {i + 1}
                                                                  </td>
                                                                  {/* <td className="border border-dark"></td> */}
                                                                  <td className="border border-dark">
                                                                    {
                                                                      customers.entities.find(
                                                                        (e) =>
                                                                          j.job
                                                                            ?.individualEntityId &&
                                                                          `${e.masterJavaBaseModel?.id}` ===
                                                                            `${j.job.individualEntityId}`
                                                                      )?.name
                                                                    }
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    {
                                                                      j.job
                                                                        ?.name
                                                                    }
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    <strong>
                                                                      {
                                                                        j.job?.panelCodes.filter(
                                                                          (c) =>
                                                                            !c.closed
                                                                        ).length
                                                                      }
                                                                    </strong>{" "}
                                                                    /{" "}
                                                                    {
                                                                      j.job?.panelCodes.filter(
                                                                        (c) =>
                                                                          c.closed
                                                                      ).length
                                                                    }{" "}
                                                                    /{" "}
                                                                    {
                                                                      j.job
                                                                        ?.panelCodes
                                                                        .length
                                                                    }
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    {
                                                                      j.job
                                                                        ?.deliveryDeadline
                                                                    }
                                                                  </td>
                                                                  <td className="border border-dark">
                                                                    {
                                                                      j.job
                                                                        ?.closingDate
                                                                    }
                                                                  </td>
                                                                  <td className="border border-dark"></td>
                                                                  <td className="border border-dark"></td>
                                                                </tr>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </table>
                                                    </div>{" "}
                                                  </>
                                                );
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_CALENDAR
                                              ):
                                                return (
                                                  <>
                                                    <div className="vw-100 vh-100 overflow-auto bg-danger">
                                                      <iframe
                                                        className="vw-100 vh-100 bg-info"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_FRONTEND_CALENDAR_URL
                                                        }/calendar_v2?dept_id=${
                                                          deptWoFilter.deptId ??
                                                          ""
                                                        }&uid=${
                                                          deptWoFilter.extUserId ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_JOURNEY_MAP
                                              ):
                                                return (
                                                  <>
                                                    <div className="w-100">
                                                      <div>
                                                        {/* <pre>
                                                          <small>
                                                            {JSON.stringify(
                                                              osrmData?.routes
                                                                ?.map(
                                                                  (r) => r.legs
                                                                )
                                                                .flat()
                                                                .map(
                                                                  (l) =>
                                                                    l?.steps
                                                                )
                                                                .flat()
                                                                .map(
                                                                  (s) =>
                                                                    s?.maneuver
                                                                )
                                                                .filter(
                                                                  (m) =>
                                                                    m?.location &&
                                                                    m
                                                                      .location?.[0] &&
                                                                    m
                                                                      .location?.[1]
                                                                )
                                                                .map((m) => [
                                                                  m
                                                                    ?.location?.[0] ??
                                                                    0,
                                                                  m
                                                                    ?.location?.[1] ??
                                                                    0,
                                                                ]) ?? []
                                                            )}
                                                          </small>
                                                        </pre> */}
                                                      </div>
                                                      <div>
                                                        <div className="d-flex flex-grow-1">
                                                          <div
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <MapContainer
                                                              center={
                                                                // osrmData || osrmDataActual
                                                                //   ? undefined
                                                                //   :
                                                                [
                                                                  -6.227026,
                                                                  106.816581,
                                                                ]
                                                              }
                                                              zoom={
                                                                // osrmData || osrmDataActual
                                                                //   ? undefined
                                                                //   :
                                                                10
                                                              }
                                                              scrollWheelZoom={
                                                                true
                                                              }
                                                              style={{
                                                                height: `${
                                                                  75 /
                                                                  (deptWoFilters.length ??
                                                                    1)
                                                                }vh`,
                                                              }}
                                                            >
                                                              <ProductMonitoringMap
                                                                osrmData={
                                                                  osrmData
                                                                }
                                                                osrmDataActual={
                                                                  osrmDataActual
                                                                }
                                                                woJobs={woJobs}
                                                                locationRecords={
                                                                  locationRecords
                                                                }
                                                                index={i}
                                                                showAllLocationUsers={
                                                                  showAllLocationUsers
                                                                }
                                                                deptWoFilter={
                                                                  deptWoFilter
                                                                }
                                                                users={
                                                                  ctx?.extUsers
                                                                }
                                                              />
                                                            </MapContainer>
                                                            <div
                                                              className="border border-dark"
                                                              style={{
                                                                height: `${
                                                                  20 /
                                                                  (deptWoFilters.length ??
                                                                    1)
                                                                }vh`,
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <div>
                                                                <div>
                                                                  <strong>
                                                                    Range from
                                                                    Employee
                                                                    Home
                                                                  </strong>
                                                                </div>
                                                                <div className="d-flex flex-wrap">
                                                                  {[
                                                                    ...new Set(
                                                                      woJobs
                                                                        .flatMap(
                                                                          (j) =>
                                                                            j?.taskLists
                                                                        )
                                                                        .flatMap(
                                                                          (
                                                                            tL
                                                                          ) =>
                                                                            tL
                                                                              ?.taskList
                                                                              ?.meetingTasks
                                                                        )
                                                                        .flatMap(
                                                                          (
                                                                            mt
                                                                          ) =>
                                                                            mt?.meetingTaskInCharges
                                                                        )
                                                                        .map(
                                                                          (
                                                                            mtic
                                                                          ) =>
                                                                            mtic?.extUserId as string
                                                                        )
                                                                        .filter(
                                                                          (
                                                                            id
                                                                          ) =>
                                                                            id
                                                                        )
                                                                    ),
                                                                  ].map(
                                                                    (uid) => {
                                                                      const fUser =
                                                                        ctx?.extUsers.find(
                                                                          (u) =>
                                                                            `${u.id}` ===
                                                                            `${uid}`
                                                                        );
                                                                      const foundAttendance =
                                                                        (() => {
                                                                          const recs =
                                                                            [
                                                                              ...locationRecords,
                                                                            ];

                                                                          recs.reverse();

                                                                          return recs;
                                                                        })()?.find(
                                                                          (l) =>
                                                                            `${l.extUserId}` ===
                                                                            `${uid}`
                                                                        );

                                                                      return (
                                                                        <>
                                                                          <div
                                                                            className={`border border-dark shadow shadow-md rounded rounded-md text-light ${
                                                                              fUser?.lat &&
                                                                              fUser.lon &&
                                                                              foundAttendance?.lon &&
                                                                              foundAttendance.lat &&
                                                                              Number(
                                                                                (
                                                                                  haversine(
                                                                                    fUser.lat,
                                                                                    fUser.lon,
                                                                                    foundAttendance?.lat ??
                                                                                      0,
                                                                                    foundAttendance?.lon ??
                                                                                      0
                                                                                  ) /
                                                                                  1000
                                                                                )?.toFixed(
                                                                                  2
                                                                                )
                                                                              ) >
                                                                                5
                                                                                ? `bg-success`
                                                                                : `bg-danger`
                                                                            }`}
                                                                          >
                                                                            <div>
                                                                              <small>
                                                                                <strong>
                                                                                  {
                                                                                    fUser?.username
                                                                                  }
                                                                                </strong>
                                                                              </small>
                                                                            </div>
                                                                            {fUser?.lat &&
                                                                            fUser.lon ? (
                                                                              <>
                                                                                {foundAttendance?.lon &&
                                                                                foundAttendance.lat ? (
                                                                                  <>
                                                                                    {" "}
                                                                                    <div>
                                                                                      <small>
                                                                                        {Intl.NumberFormat(
                                                                                          "en-US"
                                                                                        ).format(
                                                                                          haversine(
                                                                                            fUser.lat,
                                                                                            fUser.lon,
                                                                                            foundAttendance?.lat ??
                                                                                              0,
                                                                                            foundAttendance?.lon ??
                                                                                              0
                                                                                          ) /
                                                                                            1000
                                                                                        )}{" "}
                                                                                        km
                                                                                      </small>
                                                                                    </div>
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    <small>
                                                                                      <strong>
                                                                                        No
                                                                                        attendance
                                                                                        found
                                                                                      </strong>
                                                                                    </small>
                                                                                  </>
                                                                                )}
                                                                              </>
                                                                            ) : (
                                                                              <>
                                                                                <small>
                                                                                  {" "}
                                                                                  <strong>
                                                                                    No
                                                                                    home
                                                                                    coord
                                                                                  </strong>{" "}
                                                                                </small>
                                                                              </>
                                                                            )}
                                                                          </div>
                                                                        </>
                                                                      );
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div>
                                                            <div className="d-flex">
                                                              <button
                                                                className="btn btn-sm btn-primary"
                                                                onClick={() => {
                                                                  setShowInstallerWorkOrder(
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                WO Details
                                                              </button>
                                                              <div>
                                                                Show all users?
                                                                <input
                                                                  type="checkbox"
                                                                  checked={
                                                                    showAllLocationUsers
                                                                  }
                                                                  onClick={() => {
                                                                    setShowAllLocationUsers(
                                                                      !showAllLocationUsers
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="border border-dark overflow-auto"
                                                              style={{
                                                                width: "20vw",
                                                                height: "28vh",
                                                              }}
                                                            >
                                                              <div>
                                                                <strong>
                                                                  Out of range
                                                                </strong>
                                                              </div>
                                                              {woJobs
                                                                .map(
                                                                  (j) =>
                                                                    j?.taskLists
                                                                )
                                                                .flat()
                                                                .map(
                                                                  (tL) =>
                                                                    tL?.taskList
                                                                      ?.meetingTasks
                                                                )
                                                                .flat()
                                                                .map((mt) =>
                                                                  mt?.journeys.map(
                                                                    (j) => ({
                                                                      meetingTask:
                                                                        mt,
                                                                      journey:
                                                                        j,
                                                                    })
                                                                  )
                                                                )
                                                                .flat()
                                                                .map((j, i) => {
                                                                  return (
                                                                    <>
                                                                      <div>
                                                                        <div className="d-flex">
                                                                          <div>
                                                                            <small>
                                                                              <strong>
                                                                                {i +
                                                                                  1}

                                                                                .
                                                                              </strong>
                                                                            </small>
                                                                          </div>
                                                                          <div>
                                                                            <small>
                                                                              {
                                                                                j
                                                                                  ?.journey
                                                                                  ?.siteName
                                                                              }
                                                                            </small>
                                                                          </div>
                                                                        </div>
                                                                        <div className="d-flex flex-wrap">
                                                                          {[
                                                                            ...new Set(
                                                                              woJobs
                                                                                .map(
                                                                                  (
                                                                                    j
                                                                                  ) =>
                                                                                    j?.taskLists
                                                                                )
                                                                                .flat()
                                                                                .map(
                                                                                  (
                                                                                    tL
                                                                                  ) =>
                                                                                    tL
                                                                                      ?.taskList
                                                                                      ?.meetingTasks
                                                                                )
                                                                                .flat()
                                                                                .map(
                                                                                  (
                                                                                    mt
                                                                                  ) =>
                                                                                    mt?.meetingTaskInCharges
                                                                                )
                                                                                .flat()
                                                                                .map(
                                                                                  (
                                                                                    c
                                                                                  ) =>
                                                                                    c?.extUserId
                                                                                )
                                                                            ),
                                                                          ]
                                                                            .filter(
                                                                              (
                                                                                id
                                                                              ) =>
                                                                                !locationRecords.find(
                                                                                  (
                                                                                    r
                                                                                  ) =>
                                                                                    r.extUserId &&
                                                                                    `${r.extUserId}` ===
                                                                                      `${id}` &&
                                                                                    haversine(
                                                                                      r.lat ??
                                                                                        0,
                                                                                      r.lon ??
                                                                                        0,
                                                                                      j
                                                                                        ?.journey
                                                                                        .lat ??
                                                                                        0,
                                                                                      j
                                                                                        ?.journey
                                                                                        ?.lon ??
                                                                                        0
                                                                                    ) <=
                                                                                      300
                                                                                ) &&
                                                                                (showAllLocationUsers
                                                                                  ? true
                                                                                  : j?.meetingTask.meetingTaskInCharges.find(
                                                                                      (
                                                                                        c
                                                                                      ) =>
                                                                                        `${c.extUserId}` ===
                                                                                        `${id}`
                                                                                    ))
                                                                            )
                                                                            .map(
                                                                              (
                                                                                id
                                                                              ) => {
                                                                                const fUser =
                                                                                  ctx?.extUsers.find(
                                                                                    (
                                                                                      u
                                                                                    ) =>
                                                                                      `${u.id}` ===
                                                                                      `${id}`
                                                                                  );

                                                                                const foundAttendance =
                                                                                  (() => {
                                                                                    const recs =
                                                                                      [
                                                                                        ...locationRecords,
                                                                                      ];

                                                                                    recs.reverse();

                                                                                    return recs;
                                                                                  })()?.find(
                                                                                    (
                                                                                      l
                                                                                    ) =>
                                                                                      `${l.extUserId}` ===
                                                                                      `${id}`
                                                                                  );
                                                                                return (
                                                                                  <>
                                                                                    <div
                                                                                      className={`p-1 border border-dark rounded rounded-lg text-light ${
                                                                                        locationRecords.find(
                                                                                          (
                                                                                            r
                                                                                          ) =>
                                                                                            r.extUserId &&
                                                                                            `${r.extUserId}` ===
                                                                                              `${id}` &&
                                                                                            haversine(
                                                                                              r.lat ??
                                                                                                0,
                                                                                              r.lon ??
                                                                                                0,
                                                                                              j
                                                                                                ?.journey
                                                                                                ?.lat ??
                                                                                                0,
                                                                                              j
                                                                                                ?.journey
                                                                                                ?.lon ??
                                                                                                0
                                                                                            ) <=
                                                                                              300
                                                                                        )
                                                                                          ? `bg-success`
                                                                                          : `bg-danger`
                                                                                      }`}
                                                                                    >
                                                                                      <div>
                                                                                        <div>
                                                                                          <small>
                                                                                            <strong>
                                                                                              {
                                                                                                fUser?.username
                                                                                              }
                                                                                            </strong>
                                                                                          </small>
                                                                                        </div>
                                                                                        {foundAttendance &&
                                                                                        foundAttendance.lat &&
                                                                                        foundAttendance.lon ? (
                                                                                          <>
                                                                                            <Async
                                                                                              promiseFn={async () => {
                                                                                                try {
                                                                                                  const resp =
                                                                                                    await fetch(
                                                                                                      `${process.env.REACT_APP_NOMINATIM_URL}/reverse.php?lat=${foundAttendance?.lat}&lon=${foundAttendance?.lon}&format=jsonv2`
                                                                                                    );

                                                                                                  if (
                                                                                                    resp.status !==
                                                                                                    200
                                                                                                  ) {
                                                                                                    throw await resp.text();
                                                                                                  }

                                                                                                  return await resp.json();
                                                                                                } catch (e) {
                                                                                                  return e;
                                                                                                }
                                                                                              }}
                                                                                            >
                                                                                              <Async.Pending>
                                                                                                Loading...
                                                                                              </Async.Pending>
                                                                                              <Async.Rejected></Async.Rejected>
                                                                                              <Async.Fulfilled>
                                                                                                {(
                                                                                                  d: any
                                                                                                ) => {
                                                                                                  return (
                                                                                                    <div className="overflow-auto">
                                                                                                      <small
                                                                                                        style={
                                                                                                          {
                                                                                                            // whiteSpace:
                                                                                                            //   "nowrap",
                                                                                                          }
                                                                                                        }
                                                                                                      >
                                                                                                        <small>
                                                                                                          <strong>
                                                                                                            {parseNominatimAddress(
                                                                                                              d
                                                                                                            )}

                                                                                                            :
                                                                                                            {(() => {
                                                                                                              try {
                                                                                                                return (
                                                                                                                  <>
                                                                                                                    <div>
                                                                                                                      <div>{`${Intl.DateTimeFormat(
                                                                                                                        "en-US",
                                                                                                                        {
                                                                                                                          dateStyle:
                                                                                                                            "short",
                                                                                                                          // timeStyle:
                                                                                                                          //   "short",
                                                                                                                          hourCycle:
                                                                                                                            "h23",
                                                                                                                        } as any
                                                                                                                      ).format(
                                                                                                                        new Date(
                                                                                                                          foundAttendance.createdAt ??
                                                                                                                            ""
                                                                                                                        )
                                                                                                                      )};${new Date(
                                                                                                                        foundAttendance.createdAt ??
                                                                                                                          ""
                                                                                                                      ).getHours()}:${new Date(
                                                                                                                        foundAttendance.createdAt ??
                                                                                                                          ""
                                                                                                                      ).getMinutes()}`}</div>
                                                                                                                    </div>
                                                                                                                  </>
                                                                                                                );
                                                                                                              } catch (e) {
                                                                                                                return "";
                                                                                                              }
                                                                                                            })()}
                                                                                                          </strong>
                                                                                                        </small>
                                                                                                      </small>
                                                                                                    </div>
                                                                                                  );
                                                                                                }}
                                                                                              </Async.Fulfilled>
                                                                                            </Async>
                                                                                          </>
                                                                                        ) : (
                                                                                          <>

                                                                                          </>
                                                                                        )}
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                );
                                                                              }
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  );
                                                                })}
                                                              {/* <ol>
                                                          {woJobs
                                                            .map(
                                                              (j) => j?.taskLists
                                                            )
                                                            .flat()
                                                            .map((tL) => {
                                                              return (
                                                                <>
                                                                  <li>
                                                                    <div className="border border-dark">
                                                                      <div>
                                                                        WO #
                                                                        {
                                                                          tL
                                                                            ?.taskList
                                                                            ?.masterJavaBaseModel
                                                                            ?.id
                                                                        }
                                                                      </div>
                                                                      <ol>
                                                                        {tL?.taskList?.meetingTasks.map(
                                                                          (mt) => {
                                                                            return (
                                                                              <>
                                                                                <li>
                                                                                  <div className="border border-dark">
                                                                                    <small>
                                                                                      {
                                                                                        mt.description
                                                                                      }{" "}
                                                                                      (
                                                                                      {mt.meetingTaskInCharges
                                                                                        .map(
                                                                                          (
                                                                                            c
                                                                                          ) => {
                                                                                            return `${
                                                                                              ctx?.extUsers.find(
                                                                                                (
                                                                                                  u
                                                                                                ) =>
                                                                                                  `${u.id}` ===
                                                                                                  `${c.extUserId}`
                                                                                              )
                                                                                                ?.username
                                                                                            }`;
                                                                                          }
                                                                                        )
                                                                                        .join(
                                                                                          ","
                                                                                        )}
                                                                                      {mt.status ===
                                                                                      "COMPLETED" ? (
                                                                                        <CheckCircle
                                                                                          style={{
                                                                                            color:
                                                                                              "green",
                                                                                          }}
                                                                                        />
                                                                                      ) : (
                                                                                        <RemoveCircle
                                                                                          style={{
                                                                                            color:
                                                                                              "red",
                                                                                          }}
                                                                                        />
                                                                                      )}
    
                                                                                      )
                                                                                    </small>
                                                                                  </div>
                                                                                </li>
                                                                              </>
                                                                            );
                                                                          }
                                                                        )}
                                                                      </ol>
                                                                    </div>
                                                                  </li>
                                                                </>
                                                              );
                                                            })}
                                                        </ol> */}
                                                            </div>

                                                            <div
                                                              className="border border-dark overflow-auto"
                                                              style={{
                                                                width: "20vw",
                                                                height: "28vh",
                                                              }}
                                                            >
                                                              <small>
                                                                <strong>
                                                                  In Location
                                                                </strong>
                                                              </small>
                                                              <hr className="border border-dark" />
                                                              {woJobs
                                                                .map(
                                                                  (j) =>
                                                                    j?.taskLists
                                                                )
                                                                .flat()
                                                                .map(
                                                                  (tL) =>
                                                                    tL?.taskList
                                                                      ?.meetingTasks
                                                                )
                                                                .flat()
                                                                .map((mt) =>
                                                                  mt?.journeys.map(
                                                                    (j) => ({
                                                                      meetingTask:
                                                                        mt,
                                                                      journey:
                                                                        j,
                                                                    })
                                                                  )
                                                                )
                                                                .flat()
                                                                .map((j, i) => {
                                                                  return (
                                                                    <>
                                                                      <div>
                                                                        <div className="d-flex">
                                                                          <div>
                                                                            <small>
                                                                              <strong>
                                                                                {i +
                                                                                  1}

                                                                                .
                                                                              </strong>
                                                                            </small>
                                                                          </div>
                                                                          <div>
                                                                            <small>
                                                                              {
                                                                                j
                                                                                  ?.journey
                                                                                  ?.siteName
                                                                              }
                                                                            </small>
                                                                          </div>
                                                                        </div>
                                                                        <div className="d-flex flex-wrap">
                                                                          {[
                                                                            ...new Set(
                                                                              woJobs
                                                                                .map(
                                                                                  (
                                                                                    j
                                                                                  ) =>
                                                                                    j?.taskLists
                                                                                )
                                                                                .flat()
                                                                                .map(
                                                                                  (
                                                                                    tL
                                                                                  ) =>
                                                                                    tL
                                                                                      ?.taskList
                                                                                      ?.meetingTasks
                                                                                )
                                                                                .flat()
                                                                                .map(
                                                                                  (
                                                                                    mt
                                                                                  ) =>
                                                                                    mt?.meetingTaskInCharges
                                                                                )
                                                                                .flat()
                                                                                .map(
                                                                                  (
                                                                                    c
                                                                                  ) =>
                                                                                    c?.extUserId
                                                                                )
                                                                            ),
                                                                          ]
                                                                            .filter(
                                                                              (
                                                                                id
                                                                              ) =>
                                                                                locationRecords.find(
                                                                                  (
                                                                                    r
                                                                                  ) =>
                                                                                    r.extUserId &&
                                                                                    `${r.extUserId}` ===
                                                                                      `${id}` &&
                                                                                    haversine(
                                                                                      r.lat ??
                                                                                        0,
                                                                                      r.lon ??
                                                                                        0,
                                                                                      j
                                                                                        ?.journey
                                                                                        ?.lat ??
                                                                                        0,
                                                                                      j
                                                                                        ?.journey
                                                                                        ?.lon ??
                                                                                        0
                                                                                    ) <=
                                                                                      300
                                                                                ) &&
                                                                                (showAllLocationUsers
                                                                                  ? true
                                                                                  : j?.meetingTask.meetingTaskInCharges.find(
                                                                                      (
                                                                                        c
                                                                                      ) =>
                                                                                        `${c.extUserId}` ===
                                                                                        `${id}`
                                                                                    ))
                                                                            )
                                                                            .map(
                                                                              (
                                                                                id
                                                                              ) => {
                                                                                const fUser =
                                                                                  ctx?.extUsers.find(
                                                                                    (
                                                                                      u
                                                                                    ) =>
                                                                                      `${u.id}` ===
                                                                                      `${id}`
                                                                                  );
                                                                                const foundLoc =
                                                                                  locationRecords.find(
                                                                                    (
                                                                                      r
                                                                                    ) =>
                                                                                      r.extUserId &&
                                                                                      `${r.extUserId}` ===
                                                                                        `${id}` &&
                                                                                      haversine(
                                                                                        r.lat ??
                                                                                          0,
                                                                                        r.lon ??
                                                                                          0,
                                                                                        j
                                                                                          ?.journey
                                                                                          ?.lat ??
                                                                                          0,
                                                                                        j
                                                                                          ?.journey
                                                                                          ?.lon ??
                                                                                          0
                                                                                      ) <=
                                                                                        300
                                                                                  );
                                                                                return (
                                                                                  <>
                                                                                    <div
                                                                                      className={`p-1 border border-dark rounded rounded-lg text-light ${
                                                                                        foundLoc
                                                                                          ? `bg-success`
                                                                                          : `bg-danger`
                                                                                      }`}
                                                                                    >
                                                                                      <div>
                                                                                        <small>
                                                                                          <strong>
                                                                                            {
                                                                                              fUser?.username
                                                                                            }
                                                                                          </strong>
                                                                                        </small>
                                                                                      </div>
                                                                                      <div>
                                                                                        <small>
                                                                                          <small>
                                                                                            <strong>
                                                                                              {(() => {
                                                                                                try {
                                                                                                  return `${new Date(
                                                                                                    foundLoc?.createdAt ??
                                                                                                      ""
                                                                                                  ).getHours()}:${new Date(
                                                                                                    foundLoc?.createdAt ??
                                                                                                      ""
                                                                                                  ).getMinutes()}`;
                                                                                                } catch (e) {
                                                                                                  return "";
                                                                                                }
                                                                                              })()}
                                                                                            </strong>
                                                                                          </small>
                                                                                        </small>
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                );
                                                                              }
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  );
                                                                })}
                                                            </div>
                                                            <div
                                                              className="border border-dark overflow-auto"
                                                              style={{
                                                                width: "20vw",
                                                                height: "28vh",
                                                              }}
                                                            >
                                                              <div>
                                                                <div>
                                                                  <strong>
                                                                    Journeys
                                                                  </strong>
                                                                </div>

                                                                {(() => {
                                                                  const journeysMapped =
                                                                    woJobs
                                                                      .map(
                                                                        (j) =>
                                                                          j?.taskLists
                                                                      )
                                                                      .flat()
                                                                      .map(
                                                                        (tL) =>
                                                                          tL
                                                                            ?.taskList
                                                                            ?.meetingTasks
                                                                      )
                                                                      .flat()
                                                                      .map(
                                                                        (mt) =>
                                                                          mt?.journeys.map(
                                                                            (
                                                                              j
                                                                            ) => ({
                                                                              meetingTask:
                                                                                mt,
                                                                              journey:
                                                                                j,
                                                                            })
                                                                          )
                                                                      )
                                                                      .flat();
                                                                  const journeysMappedFromTo =
                                                                    journeysMapped
                                                                      .map(
                                                                        (
                                                                          j,
                                                                          i
                                                                        ) => ({
                                                                          from: journeysMapped[
                                                                            i -
                                                                              1
                                                                          ],
                                                                          to: journeysMapped[
                                                                            i
                                                                          ],
                                                                        })
                                                                      )
                                                                      .filter(
                                                                        (j) =>
                                                                          j.from
                                                                            ?.journey
                                                                            .lat &&
                                                                          j.from
                                                                            .journey
                                                                            .lon &&
                                                                          j.to
                                                                            ?.journey
                                                                            .lat &&
                                                                          j.to
                                                                            .journey
                                                                            .lon
                                                                      );

                                                                  console.log(
                                                                    "journeymapped fromto",
                                                                    journeysMappedFromTo
                                                                  );

                                                                  const totalKm =
                                                                    journeysMappedFromTo.reduce(
                                                                      (
                                                                        acc,
                                                                        j
                                                                      ) =>
                                                                        acc +
                                                                        haversine(
                                                                          j.from
                                                                            ?.journey
                                                                            .lat ??
                                                                            0,
                                                                          j.from
                                                                            ?.journey
                                                                            .lon ??
                                                                            0,
                                                                          j.to
                                                                            ?.journey
                                                                            .lat ??
                                                                            0,
                                                                          j.to
                                                                            ?.journey
                                                                            .lon ??
                                                                            0
                                                                        ) /
                                                                          1000,
                                                                      0
                                                                    );
                                                                  const foundCateg =
                                                                    totalKm >
                                                                    pertaliteFuelBillConfig.max
                                                                      ? null
                                                                      : pertaliteFuelBillConfig.bills.find(
                                                                          (b) =>
                                                                            b.from <
                                                                              totalKm &&
                                                                            b.to >=
                                                                              totalKm
                                                                        );

                                                                  return (
                                                                    <>
                                                                      <div>
                                                                        <table className="table table-sm">
                                                                          <tr className="p-0 m-0">
                                                                            {[
                                                                              "#",
                                                                              "From",
                                                                              "To",
                                                                              "Dist",
                                                                            ].map(
                                                                              (
                                                                                h
                                                                              ) => {
                                                                                return (
                                                                                  <>
                                                                                    <th className="p-0 m-0 bg-dark text-light">
                                                                                      {
                                                                                        h
                                                                                      }
                                                                                    </th>
                                                                                  </>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </tr>
                                                                          {(() => {
                                                                            return journeysMappedFromTo.map(
                                                                              (
                                                                                j,
                                                                                i
                                                                              ) => {
                                                                                return (
                                                                                  <>
                                                                                    <tr className="p-0 m-0">
                                                                                      <td className="p-0 m-0 border border-dark">
                                                                                        <small>
                                                                                          {i +
                                                                                            1}
                                                                                        </small>
                                                                                      </td>
                                                                                      <td className="p-0 m-0 border border-dark">
                                                                                        <small>
                                                                                          {j.from?.journey.siteName
                                                                                            ?.split(
                                                                                              " "
                                                                                            )
                                                                                            .map(
                                                                                              (
                                                                                                s
                                                                                              ) =>
                                                                                                s.substring(
                                                                                                  0,
                                                                                                  5
                                                                                                )
                                                                                            )
                                                                                            .join(
                                                                                              " "
                                                                                            )}
                                                                                        </small>
                                                                                      </td>
                                                                                      <td className="p-0 m-0 border border-dark">
                                                                                        <small>
                                                                                          {j.to?.journey.siteName
                                                                                            ?.split(
                                                                                              " "
                                                                                            )
                                                                                            .map(
                                                                                              (
                                                                                                s
                                                                                              ) =>
                                                                                                s.substring(
                                                                                                  0,
                                                                                                  5
                                                                                                )
                                                                                            )
                                                                                            .join(
                                                                                              " "
                                                                                            )}
                                                                                        </small>
                                                                                      </td>
                                                                                      <td className="p-0 m-0 border border-dark">
                                                                                        <small>
                                                                                          <strong>
                                                                                            {(
                                                                                              haversine(
                                                                                                j
                                                                                                  .from
                                                                                                  ?.journey
                                                                                                  .lat ??
                                                                                                  0,
                                                                                                j
                                                                                                  .from
                                                                                                  ?.journey
                                                                                                  .lon ??
                                                                                                  0,
                                                                                                j
                                                                                                  .to
                                                                                                  ?.journey
                                                                                                  .lat ??
                                                                                                  0,
                                                                                                j
                                                                                                  .to
                                                                                                  ?.journey
                                                                                                  .lon ??
                                                                                                  0
                                                                                              ) /
                                                                                              1000
                                                                                            )?.toFixed(
                                                                                              1
                                                                                            )}{" "}
                                                                                            km
                                                                                          </strong>
                                                                                        </small>
                                                                                      </td>
                                                                                    </tr>
                                                                                  </>
                                                                                );
                                                                              }
                                                                            );
                                                                          })()}
                                                                          <tr className="m-0 p-0">
                                                                            <td
                                                                              className="m-0 p-0 border border-dark"
                                                                              colSpan={
                                                                                3
                                                                              }
                                                                            >
                                                                              <div>
                                                                                <div>
                                                                                  <small>
                                                                                    <strong>
                                                                                      Total
                                                                                    </strong>
                                                                                  </small>
                                                                                </div>
                                                                                <div>
                                                                                  <div>
                                                                                    <small>
                                                                                      Est.
                                                                                      by
                                                                                      car
                                                                                    </small>
                                                                                  </div>
                                                                                  <div>
                                                                                    {foundCateg ? (
                                                                                      <>
                                                                                        {
                                                                                          foundCateg.from
                                                                                        }

                                                                                        -
                                                                                        {
                                                                                          foundCateg.to
                                                                                        }{" "}
                                                                                        km
                                                                                        ={" "}
                                                                                        <strong>
                                                                                          IDR{" "}
                                                                                          {Intl.NumberFormat(
                                                                                            navigator.language ??
                                                                                              "en-US"
                                                                                          ).format(
                                                                                            foundCateg.billIdr
                                                                                          )}
                                                                                        </strong>
                                                                                      </>
                                                                                    ) : (
                                                                                      <>
                                                                                        <strong className="text-danger">
                                                                                          {
                                                                                            ">"
                                                                                          }
                                                                                          150km,
                                                                                          on
                                                                                          bill
                                                                                        </strong>
                                                                                      </>
                                                                                    )}
                                                                                  </div>
                                                                                  <ol>
                                                                                    {/* <li>
                                                                                      <small>
                                                                                        Est.
                                                                                        Pertalite
                                                                                        x10.000:
                                                                                        <strong>
                                                                                          IDR
                                                                                          {Intl.NumberFormat(
                                                                                            navigator.language ??
                                                                                              "en-US"
                                                                                          ).format(
                                                                                            parseFloat(
                                                                                              (
                                                                                                totalKm /
                                                                                                10
                                                                                              ).toFixed(
                                                                                                1
                                                                                              )
                                                                                            ) *
                                                                                              10000
                                                                                          )}
                                                                                        </strong>
                                                                                      </small>
                                                                                    </li> */}
                                                                                    {/* <li>
                                                                                      <small>
                                                                                        Est.
                                                                                        Pertamax
                                                                                        x13.300:
                                                                                        <strong>
                                                                                          IDR
                                                                                          {Intl.NumberFormat(
                                                                                            navigator.language ??
                                                                                              "en-US"
                                                                                          ).format(
                                                                                            parseFloat(
                                                                                              (
                                                                                                totalKm /
                                                                                                10
                                                                                              ).toFixed(
                                                                                                1
                                                                                              )
                                                                                            ) *
                                                                                              13300
                                                                                          )}
                                                                                        </strong>
                                                                                      </small>
                                                                                    </li> */}
                                                                                  </ol>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                            <td className="m-0 p-0 border border-dark">
                                                                              <small>
                                                                                <strong>
                                                                                  {totalKm?.toFixed(
                                                                                    1
                                                                                  )}{" "}
                                                                                  km
                                                                                </strong>
                                                                              </small>
                                                                            </td>
                                                                          </tr>
                                                                        </table>
                                                                      </div>
                                                                    </>
                                                                  );
                                                                })()}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_MEETING
                                              ):
                                                return (
                                                  <>
                                                    <table
                                                      className="table table-sm border border-dark"
                                                      style={{
                                                        borderCollapse:
                                                          "separate",
                                                        tableLayout: "fixed",
                                                      }}
                                                    >
                                                      {[
                                                        "#",
                                                        "Meeting name",
                                                        "Date",
                                                        "Participants",
                                                      ].map((h) => (
                                                        <>
                                                          <th
                                                            className="bg-dark text-light"
                                                            style={{
                                                              position:
                                                                "sticky",
                                                              top: 0,
                                                            }}
                                                          >
                                                            {h}
                                                          </th>
                                                        </>
                                                      ))}
                                                    </table>
                                                  </>
                                                );
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_PLAN
                                              ): {
                                                const fUser =
                                                  ctx?.extUsers.find(
                                                    (u) =>
                                                      `${u.id}` ===
                                                      `${deptWoFilter.extUserId}`
                                                  );
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL
                                                        }/#/summary/sales/${
                                                          fUser?.hrId
                                                        }?startDate=${makeDateString(
                                                          new Date(
                                                            new Date().getTime() +
                                                              86400000 *
                                                                (deptWoFilter.skipDate ??
                                                                  0)
                                                          )
                                                        )}&endDate=${makeDateString(
                                                          new Date(
                                                            new Date().getTime() +
                                                              86400000 *
                                                                (deptWoFilter.skipDate ??
                                                                  0)
                                                          )
                                                        )}`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.COMPANY_SALES
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${process.env.REACT_APP_CRM_FRONTEND_URL}/#/company-sales`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.COMPANY_SALES_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${process.env.REACT_APP_CRM_FRONTEND_URL}/#/company-sales-2`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_FUNNEL_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/sales-funnel?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_INQUIRY_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>

                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/inquiry?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_QUOTATION_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/quotation?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }
                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_FOCUS_THIS_WEEK_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/focus-this-week?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_KPI_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/kpi?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_VISITATION_PLAN_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/visitation-plan?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_PURCHASE_ORDER_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/purchase-order?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_SALES_TARGET_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/sales-target?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_INTERNAL_SUPPORT_SUPPORT_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/internal-support?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }&typeData=Support`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_INTERNAL_SUPPORT_ISSUES_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/internal-support?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }&typeData=Issues`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_DAILY_REPORT_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/daily-report?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_OPPORTUNITY_PROGRESS_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/opportunity-progress?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_LOCATION_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/location?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_SALES_SUMMARY_PO_2
                                              ): {
                                                return (
                                                  <>
                                                    <div
                                                      style={{
                                                        height: "100vh",
                                                        width: "100vw",
                                                      }}
                                                    >
                                                      <div>
                                                        {
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }
                                                      </div>
                                                      <iframe
                                                        className="w-100 h-100"
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_CRM_FRONTEND_URL_2
                                                        }/#/dashboard/external/summary-purchase-order?type=${
                                                          deptWoFilter.crmDivisionType ??
                                                          ""
                                                        }&managerId=${
                                                          managerFilterId.current ??
                                                          ""
                                                        }&salesIds=${
                                                          deptWoFilter.crmUserIds ??
                                                          ""
                                                        }`}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }

                                              

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_READY_TO_ASSIGN
                                              ): {
                                                const arr =
                                                  ctx?.extUsers
                                                    .map((u) => {
                                                      return {
                                                        user: u,
                                                        workOrderHours:
                                                          wosFilteredByDate
                                                            .flatMap(
                                                              (tL) =>
                                                                tL.taskList
                                                                  ?.meetingTasks
                                                            )
                                                            .reduce(
                                                              (acc, mt) =>
                                                                acc +
                                                                (mt?.meetingTaskInCharges.find(
                                                                  (c) =>
                                                                    `${c.extUserId}` ===
                                                                    `${u.id}`
                                                                )
                                                                  ?.totalTimeHoursTask ??
                                                                  0),
                                                              0
                                                            ),
                                                      };
                                                    })
                                                    .filter(
                                                      (d) =>
                                                        (deptWoFilter.deptId
                                                          ? `${deptWoFilter.deptId}` ===
                                                            `${d.user.departmentId}`
                                                          : true &&
                                                            (!d.user
                                                              .authorizationLevel ||
                                                              authserverUser_AuthorizationLevelToNumber(
                                                                authserverUser_AuthorizationLevelFromJSON(
                                                                  d.user
                                                                    .authorizationLevel
                                                                )
                                                              ) > 1)) &&
                                                        !d.user.isSubcon &&
                                                        d.workOrderHours < 7.9
                                                    ) ?? [];

                                                arr?.sort(
                                                  (a, b) =>
                                                    a.workOrderHours -
                                                    b.workOrderHours
                                                );

                                                return (
                                                  <>
                                                    <div
                                                      className="w-100 border border-dark overflow-auto"
                                                      style={{
                                                        height: "85vh",
                                                        resize: "vertical",
                                                      }}
                                                    >
                                                      <table
                                                        className="table table-sm"
                                                        style={{
                                                          borderCollapse:
                                                            "separate",
                                                        }}
                                                      >
                                                        <tr>
                                                          {[
                                                            "#",
                                                            "Name",
                                                            "Username",
                                                            "Dept",
                                                            `Hours (${
                                                              arr?.filter(
                                                                (d) =>
                                                                  d.workOrderHours ===
                                                                  0
                                                              ).length
                                                            }/${
                                                              arr?.length ?? 0
                                                            })`,
                                                          ].map((h) => {
                                                            return (
                                                              <>
                                                                <th
                                                                  style={{
                                                                    position:
                                                                      "sticky",
                                                                    top: 0,
                                                                  }}
                                                                  className="bg-dark text-light p-0 m-0"
                                                                >
                                                                  {h}
                                                                </th>
                                                              </>
                                                            );
                                                          })}
                                                        </tr>
                                                        {arr.map((d, i) => {
                                                          return (
                                                            <>
                                                              <tr>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {i + 1}
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {d.user.name}
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {
                                                                    d.user
                                                                      .username
                                                                  }
                                                                </td>
                                                                <td className="border border-dark p-0 m-0">
                                                                  {
                                                                    ctx?.extDepartments.find(
                                                                      (dx) =>
                                                                        `${dx.id}` ===
                                                                        `${d.user?.departmentId}`
                                                                    )?.name
                                                                  }
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    backgroundColor:
                                                                      chroma
                                                                        .scale([
                                                                          "red",
                                                                          "yellow",
                                                                          "green",
                                                                        ])(
                                                                          d.workOrderHours /
                                                                            8
                                                                        )
                                                                        .alpha(
                                                                          0.6
                                                                        )
                                                                        .hex(),
                                                                  }}
                                                                  className={`border border-dark p-0 m-0`}
                                                                >
                                                                  <strong>
                                                                    {d.workOrderHours.toFixed(
                                                                      2
                                                                    )}
                                                                  </strong>
                                                                </td>
                                                              </tr>
                                                            </>
                                                          );
                                                        })}
                                                      </table>
                                                    </div>
                                                  </>
                                                );
                                              }

                                              case workOrderTypeToJSON(
                                                WorkOrderType.WO_TYPE_JDP
                                              ):
                                                const jdpPresetId =
                                                  deptWoFilter.jdpPresetId
                                                    ? deptWoFilter.jdpPresetId
                                                    : jobsProto?.jobs.find(
                                                        (j) =>
                                                          `${j.masterJavaBaseModel?.id}` ===
                                                          `${deptWoFilter.jobId}`
                                                      )?.extJdpPresetId;

                                                return (
                                                  <>
                                                    <div
                                                      className="w-100 border border-dark overflow-auto"
                                                      style={{
                                                        height: "85vh",
                                                        resize: "vertical",
                                                      }}
                                                    >
                                                      <table
                                                        className="table table-sm"
                                                        style={{
                                                          borderCollapse:
                                                            "separate",
                                                        }}
                                                      >
                                                        <tr className=" p-0 m-0">
                                                          {[
                                                            "#",
                                                            "Job",
                                                            "Product",
                                                          ].map((h) => (
                                                            <th
                                                              style={{
                                                                position:
                                                                  "sticky",
                                                                top: 0,
                                                              }}
                                                              className="bg-dark text-light p-0 m-0"
                                                            >
                                                              {h}
                                                            </th>
                                                          ))}{" "}
                                                          {filterDeptTemplate(
                                                            departmentTemplates
                                                          )
                                                            .filter((d) =>
                                                              deptTemplatePresets.presets
                                                                .find(
                                                                  (p) =>
                                                                    `${p.masterJavaBaseModel?.id}` ===
                                                                    `${jdpPresetId}`
                                                                )
                                                                ?.items.find(
                                                                  (i) =>
                                                                    `${i.departmentTemplateItemId}` ===
                                                                    `${d.departmentTemplateItem.masterJavaBaseModel?.id}`
                                                                )
                                                            )
                                                            .map((d) => {
                                                              // todo: check if in preset
                                                              return (
                                                                <>
                                                                  <th
                                                                    className=" p-0 m-0"
                                                                    style={{
                                                                      backgroundColor:
                                                                        new ColorHash(
                                                                          {
                                                                            lightness:
                                                                              [
                                                                                0.7,
                                                                              ],
                                                                          }
                                                                        ).hex(
                                                                          d
                                                                            .departmentTemplate
                                                                            ?.name ??
                                                                            ""
                                                                        ),
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      <div>
                                                                        <small>
                                                                          <strong>
                                                                            {d.departmentTemplate.name
                                                                              ?.split(
                                                                                " "
                                                                              )
                                                                              .map(
                                                                                (
                                                                                  s
                                                                                ) =>
                                                                                  s.substring(
                                                                                    0,
                                                                                    4
                                                                                  )
                                                                              )
                                                                              .join(
                                                                                " "
                                                                              )}
                                                                          </strong>
                                                                        </small>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          backgroundColor:
                                                                            new ColorHash(
                                                                              {
                                                                                lightness:
                                                                                  [
                                                                                    0.7,
                                                                                  ],
                                                                              }
                                                                            ).hex(
                                                                              d
                                                                                .departmentTemplateItem
                                                                                ?.name ??
                                                                                ""
                                                                            ),
                                                                        }}
                                                                      >
                                                                        <small>
                                                                          <small>
                                                                            <strong>
                                                                              {d.departmentTemplateItem.name
                                                                                ?.split(
                                                                                  " "
                                                                                )
                                                                                .map(
                                                                                  (
                                                                                    s
                                                                                  ) =>
                                                                                    s.substring(
                                                                                      0,
                                                                                      3
                                                                                    )
                                                                                )
                                                                                .join(
                                                                                  " "
                                                                                )}
                                                                            </strong>
                                                                          </small>
                                                                        </small>
                                                                      </div>
                                                                    </div>
                                                                  </th>
                                                                </>
                                                              );
                                                            })}
                                                        </tr>
                                                        {(() => {
                                                          const jobs =
                                                            jobsJdp.jobs;

                                                          jobsJdp.jobs.sort(
                                                            (a, b) =>
                                                              parseInt(
                                                                b
                                                                  .masterJavaBaseModel
                                                                  ?.id ?? "0"
                                                              ) -
                                                              parseInt(
                                                                a
                                                                  .masterJavaBaseModel
                                                                  ?.id ?? "0"
                                                              )
                                                          );

                                                          return jobs;
                                                        })()
                                                          .flatMap((j, i) =>
                                                            j.panelCodes.map(
                                                              (c) => ({
                                                                job: j,
                                                                panelCode: c,
                                                              })
                                                            )
                                                          )
                                                          .filter(
                                                            (d) =>
                                                              `${d.job.masterJavaBaseModel?.id}` ===
                                                              `${deptWoFilter.jobId}`
                                                          )
                                                          .map((d, i) => {
                                                            return (
                                                              <>
                                                                <tr className=" p-0 m-0">
                                                                  <td className="border border-dark p-0 m-0">
                                                                    <small>
                                                                      <strong>
                                                                        {i + 1}
                                                                      </strong>
                                                                    </small>
                                                                  </td>
                                                                  <td
                                                                    className="border border-dark p-0 m-0"
                                                                    style={{
                                                                      whiteSpace:
                                                                        "nowrap",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="overflow-auto"
                                                                      style={{
                                                                        width: 150,
                                                                      }}
                                                                    >
                                                                      <small>
                                                                        {d.job.name
                                                                          ?.split(
                                                                            " "
                                                                          )
                                                                          .map(
                                                                            (
                                                                              s
                                                                            ) =>
                                                                              s.substring(
                                                                                0,
                                                                                5
                                                                              )
                                                                          )
                                                                          .join(
                                                                            " "
                                                                          )}
                                                                      </small>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    className="border border-dark p-0 m-0"
                                                                    style={{
                                                                      whiteSpace:
                                                                        "nowrap",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="overflow-auto"
                                                                      style={{
                                                                        width: 150,
                                                                      }}
                                                                    >
                                                                      <small>
                                                                        {
                                                                          d
                                                                            .panelCode
                                                                            .type
                                                                        }
                                                                        :
                                                                        {
                                                                          d
                                                                            .panelCode
                                                                            .name
                                                                        }
                                                                        :
                                                                        {
                                                                          d
                                                                            .panelCode
                                                                            .qty
                                                                        }{" "}
                                                                        unit(s)
                                                                      </small>
                                                                    </div>
                                                                  </td>
                                                                  {filterDeptTemplate(
                                                                    departmentTemplates
                                                                  )
                                                                    .filter(
                                                                      (d) =>
                                                                        deptTemplatePresets.presets
                                                                          .find(
                                                                            (
                                                                              p
                                                                            ) =>
                                                                              `${p.masterJavaBaseModel?.id}` ===
                                                                              `${jdpPresetId}`
                                                                          )
                                                                          ?.items.find(
                                                                            (
                                                                              i
                                                                            ) =>
                                                                              `${i.departmentTemplateItemId}` ===
                                                                              `${d.departmentTemplateItem.masterJavaBaseModel?.id}`
                                                                          )
                                                                    )
                                                                    .map(
                                                                      (dx) => {
                                                                        const foundPCDTI =
                                                                          d.panelCode.panelCodeDepartmentTemplateItems.find(
                                                                            (
                                                                              pcdti
                                                                            ) =>
                                                                              `${pcdti.departmentTemplateItemId}` ===
                                                                              `${dx.departmentTemplateItem.masterJavaBaseModel?.id}`
                                                                          );
                                                                        return (
                                                                          <>
                                                                            <td
                                                                              className="m-0 p-0 border border-dark"
                                                                              style={{
                                                                                whiteSpace:
                                                                                  "nowrap",
                                                                                backgroundColor:
                                                                                  !foundPCDTI ||
                                                                                  foundPCDTI.excluded
                                                                                    ? `grey`
                                                                                    : foundPCDTI.completedDate
                                                                                    ? `lightgreen`
                                                                                    : compareDateStringUtcColor(
                                                                                        {
                                                                                          current:
                                                                                            makeDateString(
                                                                                              new Date()
                                                                                            ),
                                                                                          date: makeDateString(
                                                                                            new Date(
                                                                                              foundPCDTI.startDate ??
                                                                                                ""
                                                                                            )
                                                                                          ),
                                                                                        }
                                                                                      ),
                                                                              }}
                                                                            >
                                                                              <div>
                                                                                <small>
                                                                                  <strong>
                                                                                    {
                                                                                      foundPCDTI?.startDate?.split(
                                                                                        "T"
                                                                                      )?.[0]
                                                                                    }
                                                                                  </strong>
                                                                                </small>
                                                                                <hr className="border border-dark p-0 m-0" />
                                                                                <small>
                                                                                  <strong>
                                                                                    {
                                                                                      foundPCDTI?.completedDate?.split(
                                                                                        "T"
                                                                                      )?.[0]
                                                                                    }
                                                                                  </strong>
                                                                                </small>
                                                                              </div>
                                                                            </td>
                                                                          </>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tr>
                                                              </>
                                                            );
                                                          })}
                                                      </table>
                                                    </div>
                                                  </>
                                                );

                                              case workOrderTypeFromJSON(
                                                WorkOrderType.WO_TYPE_PROJECT_MASTER_TEMPLATE_LINK
                                              ):
                                                return (
                                                  <>
                                                    <div className="overflow-auto w-100 h-100">
                                                      <strong>
                                                        Template link, WIP
                                                      </strong>

                                                      <div>
                                                        <table
                                                          className="table table-sm"
                                                          style={{
                                                            borderCollapse:
                                                              "separate",
                                                          }}
                                                        >
                                                          <tr>
                                                            {[
                                                              "#",
                                                              "Project",
                                                              "Deadline",
                                                              "Products",
                                                              "Manager",
                                                              "WO Progress",
                                                              "Progress Last Week",
                                                              "Progress Cur Week",
                                                            ].map((h) => (
                                                              <>
                                                                <th className="bg-dark text-light p-0 m-0">
                                                                  {h}
                                                                </th>
                                                              </>
                                                            ))}
                                                          </tr>
                                                          {projectsSimple.current?.projects
                                                            .filter(
                                                              (p) =>
                                                                p.products.find(
                                                                  (p) =>
                                                                    p.extCrmPurchaseOrderItemId &&
                                                                    p.extCrmPurchaseOrderItemId !==
                                                                      "" &&
                                                                    p.extCrmPurchaseOrderItemId !==
                                                                      "0"
                                                                ) && !p.closing
                                                            )
                                                            .map((p, i) => {
                                                              const projectDeadline =
                                                                p.deadline?.split(
                                                                  "T"
                                                                )?.[0];

                                                              const d =
                                                                projectsSimple.current &&
                                                                templates.current &&
                                                                jobs.current
                                                                  ? calculateProjectProgress(
                                                                      {
                                                                        project:
                                                                          p,
                                                                        templates:
                                                                          templates.current,
                                                                        jobs: jobs.current,
                                                                        presets:
                                                                          presets.current,
                                                                      }
                                                                    )
                                                                  : null;

                                                              const projectTasksFiltered =
                                                                projectTasks.current.taskLists
                                                                  .filter(
                                                                    (t) =>
                                                                      `${t.taskList?.extPurchaseOrderId}` ===
                                                                      `${p.extPurchaseOrderId}`
                                                                  )
                                                                  .flatMap(
                                                                    (t) =>
                                                                      t.taskList
                                                                        ?.meetingTasks
                                                                  );
                                                              const projectTasksCompleted =
                                                                projectTasksFiltered.filter(
                                                                  (t) =>
                                                                    t?.status ===
                                                                    "COMPLETED"
                                                                );
                                                              const progressProjectWO =
                                                                projectTasksCompleted.length /
                                                                projectTasksFiltered.length;
                                                              return (
                                                                <>
                                                                  <tr>
                                                                    <td className="border border-dark p-0 m-0">
                                                                      {i + 1}
                                                                    </td>
                                                                    <td className="border border-dark p-0 m-0">
                                                                      {p.name}
                                                                    </td>
                                                                    <td
                                                                      className="border border-dark p-0 m-0"
                                                                      style={{
                                                                        backgroundColor:
                                                                          chroma
                                                                            .scale(
                                                                              [
                                                                                "lightsalmon",
                                                                                "yellow",
                                                                                "white",
                                                                              ]
                                                                            )(
                                                                              getProjectDeadlineChromaColorScale(
                                                                                {
                                                                                  date: makeDateString(
                                                                                    new Date()
                                                                                  ),
                                                                                  deadline:
                                                                                    projectDeadline,
                                                                                }
                                                                              )
                                                                            )
                                                                            .hex(),
                                                                      }}
                                                                    >
                                                                      {/* { getProjectDeadlineChromaColorScale(
                                                                                {
                                                                                  date: makeDateString(
                                                                                    new Date()
                                                                                  ),
                                                                                  deadline:
                                                                                    projectDeadline,
                                                                                }
                                                                              )} */}
                                                                      {(() => {
                                                                        try {
                                                                          return Intl.DateTimeFormat(
                                                                            navigator.language ??
                                                                              "en-US",
                                                                            {
                                                                              dateStyle:
                                                                                "long",
                                                                            } as any
                                                                          ).format(
                                                                            new Date(
                                                                              projectDeadline ??
                                                                                ""
                                                                            )
                                                                          );
                                                                        } catch (e) {
                                                                          return "";
                                                                        }
                                                                      })()}
                                                                    </td>
                                                                    <td className="border border-dark p-0 m-0">
                                                                      {
                                                                        p
                                                                          .products
                                                                          .length
                                                                      }
                                                                    </td>
                                                                    <td className="border border-dark p-0 m-0">
                                                                      {
                                                                        ctx?.extUsers.find(
                                                                          (u) =>
                                                                            `${u.id}` ===
                                                                            `${p.managerId}`
                                                                        )
                                                                          ?.username
                                                                      }
                                                                    </td>
                                                                    <td
                                                                      className="border border-dark p-0 m-0"
                                                                      onClick={() => {
                                                                        selectedProjectWoDialog.current =
                                                                          p;
                                                                        render();
                                                                      }}
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                        backgroundColor:
                                                                          chroma
                                                                            .scale(
                                                                              [
                                                                                "red",
                                                                                "yellow",
                                                                                "green",
                                                                              ]
                                                                            )(
                                                                              progressProjectWO
                                                                            )
                                                                            .alpha(
                                                                              0.5
                                                                            )
                                                                            .hex(),
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        {projectTasksFiltered.length >
                                                                        0 ? (
                                                                          <>
                                                                            {(
                                                                              progressProjectWO *
                                                                              100
                                                                            )?.toFixed(
                                                                              1
                                                                            )}
                                                                            % (
                                                                            {
                                                                              projectTasksCompleted.length
                                                                            }
                                                                            /
                                                                            {
                                                                              projectTasksFiltered.length
                                                                            }
                                                                            )
                                                                          </>
                                                                        ) : (
                                                                          "No tasks"
                                                                        )}
                                                                      </strong>
                                                                    </td>

                                                                    <td
                                                                      className="border border-dark p-0 m-0 text-center"
                                                                      style={{
                                                                        backgroundColor:
                                                                          chroma
                                                                            .scale(
                                                                              [
                                                                                "red",
                                                                                "yellow",
                                                                                "green",
                                                                              ]
                                                                            )(
                                                                              (d?.itemsFiltered ??
                                                                                0) /
                                                                                (d?.items ??
                                                                                  1)
                                                                            )
                                                                            .alpha(
                                                                              0.5
                                                                            )
                                                                            .hex(),
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        {(
                                                                          (d?.progress ??
                                                                            0) *
                                                                          100
                                                                        )?.toFixed(
                                                                          1
                                                                        )}
                                                                        % (
                                                                        {
                                                                          d?.itemsFiltered
                                                                        }
                                                                        /
                                                                        {
                                                                          d?.items
                                                                        }
                                                                        )
                                                                      </strong>
                                                                    </td>
                                                                    <td
                                                                      className="border border-dark p-0 m-0 text-center"
                                                                      style={{
                                                                        backgroundColor:
                                                                          chroma
                                                                            .scale(
                                                                              [
                                                                                "red",
                                                                                "yellow",
                                                                                "green",
                                                                              ]
                                                                            )(
                                                                              (d?.itemsFiltered ??
                                                                                0) /
                                                                                (d?.items ??
                                                                                  1)
                                                                            )
                                                                            .alpha(
                                                                              0.5
                                                                            )
                                                                            .hex(),
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        {(
                                                                          (d?.progress ??
                                                                            0) *
                                                                          100
                                                                        )?.toFixed(
                                                                          1
                                                                        )}
                                                                        % (
                                                                        {
                                                                          d?.itemsFiltered
                                                                        }
                                                                        /
                                                                        {
                                                                          d?.items
                                                                        }
                                                                        )
                                                                      </strong>
                                                                    </td>
                                                                  </tr>
                                                                </>
                                                              );
                                                            })}
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </>
                                                );

                                              default:
                                                return <></>;
                                            }
                                          })}
                                        </div>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <strong>Page not found.</strong>
                                      </>
                                    );
                                  }
                                })()}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                </>
              );
            case "QuadScreen":
              return (
                <>
                  <div>
                    <div
                      className="d-flex"
                      style={{ height: "40vh", resize: "vertical" }}
                    >
                      {/* Upper left */}
                      <div
                        className="border border-dark overflow-auto flex-grow-1"
                        style={{ width: "50%" }}
                      >
                        <QuadPanel
                          deptId={deptWoFilters?.[0]?.deptId}
                          deptWoFilterIndex={0}
                        />
                      </div>
                      {/* Upper right */}
                      <div
                        className="border border-dark overflow-auto flex-grow-1"
                        style={{ width: "50%" }}
                      >
                        {" "}
                        <QuadPanel
                          deptId={deptWoFilters?.[1]?.deptId}
                          deptWoFilterIndex={1}
                        />
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ height: "40vh", resize: "vertical" }}
                    >
                      {/* Bottom left */}
                      <div
                        className="border border-dark overflow-auto flex-grow-1"
                        style={{ width: "50%" }}
                      >
                        {" "}
                        <QuadPanel
                          deptId={deptWoFilters?.[2]?.deptId}
                          deptWoFilterIndex={2}
                        />
                      </div>
                      {/* Bottom right */}
                      <div
                        className="border border-dark overflow-auto flex-grow-1"
                        style={{ width: "50%" }}
                      >
                        {" "}
                        <QuadPanel
                          deptId={deptWoFilters?.[3]?.deptId}
                          deptWoFilterIndex={3}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            case "QuadScreenV2":
              return (
                <>
                  <div>
                    <div className="">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          [...Array(4)]
                            .map((_, i) => i)
                            .forEach((n) => {
                              fetchDeptV2Kpi({
                                deptId: deptWoFilters?.[n]?.deptId,
                              });
                            });
                        }}
                      >
                        <small>Refresh all</small>
                      </button>
                    </div>
                    <div
                      className="d-flex"
                      style={{ height: "40vh", resize: "vertical" }}
                    >
                      {/* Upper left */}
                      <div
                        className="border border-dark overflow-auto flex-grow-1"
                        style={{ width: "50%" }}
                      >
                        <QuadPanelV2
                          deptId={deptWoFilters?.[0]?.deptId}
                          deptWoFilterIndex={0}
                        />
                      </div>
                      {/* Upper right */}
                      <div
                        className="border border-dark overflow-auto flex-grow-1"
                        style={{ width: "50%" }}
                      >
                        <QuadPanelV2
                          deptId={deptWoFilters?.[1]?.deptId}
                          deptWoFilterIndex={1}
                        />
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ height: "40vh", resize: "vertical" }}
                    >
                      {/* Bottom left */}
                      <div
                        className="border border-dark overflow-auto flex-grow-1"
                        style={{ width: "50%" }}
                      >
                        <QuadPanelV2
                          deptId={deptWoFilters?.[2]?.deptId}
                          deptWoFilterIndex={2}
                        />
                      </div>
                      {/* Bottom right */}
                      <div
                        className="border border-dark overflow-auto flex-grow-1"
                        style={{ width: "50%" }}
                      >
                        <QuadPanelV2
                          deptId={deptWoFilters?.[3]?.deptId}
                          deptWoFilterIndex={3}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
          }
        })()}

        {/* <div>
          <pre>
            <small>
              <div>{JSON.stringify(deptWoFilters, null, 2)}</div>
            </small>
          </pre>
        </div> */}
      </div>
      <Modal
        size="xl"
        show={internalExternalIssueDetail.current ? true : false}
        onHide={() => {
          internalExternalIssueDetail.current = null;
          render();
        }}
      >
        <ModalHeader>
          Internal/External Issue Detail{" "}
          {internalExternalIssueDetail.current?.start}-
          {internalExternalIssueDetail.current?.end}
        </ModalHeader>
        <ModalBody>
          {(() => {
            // const tasks = dept

            return (
              <>
                <div>
                  <div
                    className="overflow-auto border border-dark"
                    style={{ height: "50vh" }}
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {[
                          "#",
                          "WO ID",
                          "Task",
                          "Dept",
                          "Type",
                          "Remark",
                          "Status",
                          "PIC",
                        ].map((h) => {
                          return (
                            <>
                              <th
                                className="bg-dark text-light"
                                style={{ position: "sticky", top: 0 }}
                              >
                                {h}
                              </th>
                            </>
                          );
                        })}
                      </tr>
                      {internalExternalIssueDetailWorkOrders.current.taskLists
                        .flatMap((tL) =>
                          tL.taskList?.meetingTasks.flatMap((mt) =>
                            mt.meetingTaskProblemCatalogs.map((c) => ({
                              meetingTask: mt,
                              problemCatalog: c,
                              taskList: tL,
                            }))
                          )
                        )
                        .map((d, i) => {
                          const foundProblemCatalog =
                            problemCatalogs.catalogs.find(
                              (c) =>
                                `${c.masterJavaBaseModel?.id}` ===
                                `${d?.problemCatalog.problemCatalogId}`
                            );
                          return (
                            <>
                              <tr>
                                <td className="border border-dark p-0 m-0">
                                  {i + 1}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {
                                    d?.taskList.taskList?.masterJavaBaseModel
                                      ?.id
                                  }
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {d?.meetingTask.description}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {
                                    ctx?.extDepartments.find(
                                      (d) =>
                                        `${d.id}` ===
                                        `${foundProblemCatalog?.extDepartmentId}`
                                    )?.name
                                  }
                                  {"->"}
                                  {
                                    ctx?.extDepartments.find(
                                      (d) =>
                                        `${d.id}` ===
                                        `${foundProblemCatalog?.extDepartmentVersusId}`
                                    )?.name
                                  }
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {meetingProblemTypeToJSON(
                                    meetingProblemTypeFromJSON(
                                      foundProblemCatalog?.problemType
                                    )
                                  )}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {d?.problemCatalog.remark}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {d?.problemCatalog.status}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {
                                    ctx?.extUsers.find(
                                      (u) =>
                                        `${u.id}` ===
                                        `${d?.problemCatalog.extUserId}`
                                    )?.username
                                  }
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </table>
                  </div>
                </div>
              </>
            );
          })()}
        </ModalBody>
      </Modal>
      <Modal
        size="xl"
        show={attendanceNoteDetailFilter.current ? true : false}
        onHide={() => {
          attendanceNoteDetailFilter.current = null;
          render();
        }}
      >
        <ModalHeader>
          <div>
            <div>
              <h4>
                Attendance Note Detail (
                {/* {attendanceNoteDetailFilter.current?.start}-
                {attendanceNoteDetailFilter.current?.end} */}
                {(() => {
                  try {
                    return Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    } as any).format(
                      new Date(attendanceNoteDetailFilter.current?.start ?? "")
                    );
                  } catch (e) {
                    return "";
                  }
                })()}{" "}
                to{" "}
                {(() => {
                  try {
                    return Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    } as any).format(
                      new Date(attendanceNoteDetailFilter.current?.end ?? "")
                    );
                  } catch (e) {
                    return "";
                  }
                })()}
                )
              </h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            {(() => {
              const foundNotes = attendanceNotes.filter(
                (n) =>
                  ctx?.extUsers
                    .filter(
                      (u) =>
                        `${u.departmentId}` ===
                        `${attendanceNoteDetailFilter.current?.deptId}`
                    )
                    .find((u) => `${u.id}` === `${n.extUserId}`) &&
                  new Date(
                    `${attendanceNoteDetailFilter.current?.start}T00:00:00`
                  ).getTime() <= new Date(n.date ?? "").getTime() &&
                  new Date(
                    `${attendanceNoteDetailFilter.current?.end}T23:59:59`
                  ).getTime() >= new Date(n.date ?? "").getTime()
              );

              return (
                <>
                  <table
                    className="table table-sm"
                    style={{ borderCollapse: "separate" }}
                  >
                    <tr>
                      {["#", "User", "Type", "Remark", "Date", "Created"].map(
                        (h) => (
                          <>
                            <th className="bg-dark text-light p-0 m-0">{h}</th>
                          </>
                        )
                      )}
                    </tr>
                    {foundNotes.map((n, i) => {
                      return (
                        <>
                          <tr>
                            <td className="border border-dark p-0 m-0">
                              {" "}
                              {i + 1}
                            </td>
                            <td className="border border-dark p-0 m-0">
                              {" "}
                              {
                                ctx?.extUsers.find(
                                  (u) => `${u.id}` === `${n.extUserId}`
                                )?.username
                              }
                            </td>
                            <td className="border border-dark p-0 m-0">
                              {" "}
                              {n.attendanceNoteType?.name}
                            </td>

                            <td className="border border-dark p-0 m-0">
                              {" "}
                              {n.note}
                            </td>
                            <td className="border border-dark p-0 m-0">
                              {" "}
                              {(() => {
                                try {
                                  return Intl.DateTimeFormat("en-US", {
                                    dateStyle: "medium",
                                    timeStyle: "short",
                                  } as any).format(new Date(n.date ?? ""));
                                } catch (e) {
                                  return "";
                                }
                              })()}
                            </td>
                            <td className="border border-dark p-0 m-0">
                              {" "}
                              {(() => {
                                try {
                                  return Intl.DateTimeFormat("en-US", {
                                    dateStyle: "medium",
                                    timeStyle: "short",
                                  } as any).format(new Date(n.createdAt ?? ""));
                                } catch (e) {
                                  return "";
                                }
                              })()}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </>
              );
            })()}
          </div>
        </ModalBody>
      </Modal>
      <Modal
        size="xl"
        show={deptModalLastPlanShowId !== null}
        onHide={() => {
          setDeptModalLastPlanShowId(null);
        }}
      >
        <ModalHeader>Last Plan</ModalHeader>
        <ModalBody>
          <div>
            <ol>
              {ctx?.extUsers
                .filter(
                  (u) => `${u.departmentId}` === `${deptModalLastPlanShowId}`
                )
                .map((u) => {
                  const foundLastPlan = (() => {
                    const data = [...deptModalLastPlanShowData.dates].filter(
                      (d) => d.date
                    );

                    data.sort(
                      (a, b) => a.date?.localeCompare(b.date ?? "") ?? 0
                    );

                    return data;
                  })()?.slice(-1)?.[0];

                  const userDate = deptModalLastPlanShowData.dates.find(
                    (d) => `${d.extUserId}` === `${u.id}`
                  );

                  return (
                    <>
                      <li>
                        <div>
                          <div
                            className={`${
                              foundLastPlan?.date === userDate?.date
                                ? `font-weight-bold`
                                : ``
                            }`}
                          >
                            {u.username}: {userDate?.date}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
            </ol>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        show={taskDetailsShow}
        onHide={() => {
          setTaskDetailsShow(false);
          setSelectedExtUser(null);
        }}
      >
        <ModalHeader>Task Details</ModalHeader>
        <ModalBody>
          {JSON.stringify(bypass)}
          {modalTasks.map((tL) => {
            return (
              <>
                <div>
                  {/* <div>
                    From{" "}
                    {makeDateString(selectedWeekRange?.from ?? new Date(0))} to{" "}
                    {makeDateString(selectedWeekRange?.to ?? new Date(0))}{" "}
                    {makeDateString(selectedWeekRange?.from ?? new Date(0)) ===
                    makeDateString(selectedWeekRange?.to ?? new Date(0)) ? (
                      <strong>Single date filter</strong>
                    ) : undefined}
                  </div> */}
                  <TaskListInfo
                    customers={ctx?.extCustomers}
                    crmCustomers={ctx?.extCrmCustomers}
                    tL={tL}
                    extUsers={ctx?.extUsers ?? []}
                    jobsProto={jobsProto ?? PpicJobs.fromPartial({})}
                    machines={machines}
                    processTypes={
                      processTypes ?? PpicSimplifiedProcessTypes.fromPartial({})
                    }
                    rcemDepartments={rcemDepartments}
                    integrationModules={integrationModules}
                    machinePrograms={machinePrograms}
                    departmentTemplates={departmentTemplates}
                    date={
                      selectedWeekRange?.from &&
                      selectedWeekRange.to &&
                      makeDateString(selectedWeekRange.from) ===
                        makeDateString(selectedWeekRange.to)
                        ? makeDateString(selectedWeekRange.from)
                        : undefined
                    }
                    exUserId={selectedExtUser?.id ?? null}
                    panelCodeDepartmentTemplateItems={
                      panelCodeDepartmentTemplateItemsProto
                    }
                    bypass={bypass}
                  />
                </div>
              </>
            );
          })}
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        show={showInstallerWorkOrder}
        onHide={() => {
          setShowInstallerWorkOrder(false);
        }}
      >
        <ModalHeader>WO Installer</ModalHeader>
        <ModalBody>
          <div className="overflow-auto" style={{ height: "75vh" }}>
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              {woJobs
                .map((tL) => tL?.taskLists)
                .flat()
                .map((tL) => {
                  return (
                    <TaskListInfo
                      customers={ctx?.extCustomers}
                      crmCustomers={ctx?.extCrmCustomers}
                      tL={tL}
                      extUsers={ctx?.extUsers ?? []}
                      jobsProto={jobsProto ?? PpicJobs.fromPartial({})}
                      machines={machines}
                      processTypes={
                        processTypes ??
                        PpicSimplifiedProcessTypes.fromPartial({})
                      }
                      rcemDepartments={rcemDepartments}
                      integrationModules={integrationModules}
                      machinePrograms={machinePrograms}
                      departmentTemplates={departmentTemplates}
                      // date={
                      //   selectedWeekRange?.from &&
                      //   selectedWeekRange.to &&
                      //   makeDateString(selectedWeekRange.from) ===
                      //     makeDateString(selectedWeekRange.to)
                      //     ? makeDateString(selectedWeekRange.from)
                      //     : undefined
                      // }
                      exUserId={selectedExtUser?.id ?? null}
                      panelCodeDepartmentTemplateItems={
                        panelCodeDepartmentTemplateItemsProto
                      }
                    />
                  );
                })}
            </table>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>

      <Modal
        size="xl"
        show={woDetailsPopup}
        onHide={() => {
          setWoDetailsPopup(false);
        }}
      >
        <ModalHeader>
          WO Details for dept:{" "}
          {
            ctx?.extDepartments.find((d) => `${d.id}` === `${woDetailsDeptId}`)
              ?.name
          }
          , range: {woDetailsRange}d,{" "}
          {customRangeDetail
            ? `from: ${customRangeDetail.startDate}, to: ${customRangeDetail.endDate}`
            : ``}
        </ModalHeader>
        <ModalBody>
          <div>
            {(() => {
              const filteredTaskLists = woDetailsPopupData.taskLists.filter(
                (tL) =>
                  tL.taskList?.meetingTasks.find((mt) =>
                    mt.meetingTaskInCharges.find((mtic) =>
                      ctx?.extUsers
                        .filter(
                          (u) => `${u.departmentId}` === `${woDetailsDeptId}`
                        )
                        .find((u) => `${u.id}` === `${mtic.extUserId}`)
                    )
                  )
              );

              const getMeetingTasksTime = (tL: MeetingTaskListView) =>
                tL.taskList?.meetingTasks?.reduce(
                  (acc, mt) =>
                    acc +
                    (ctx?.extUsers
                      .filter(
                        (u) => `${u.departmentId}` === `${woDetailsDeptId}`
                      )
                      .reduce(
                        (acc, u) =>
                          acc +
                          (mt.meetingTaskInCharges.find(
                            (mtic) => `${mtic.extUserId}` === `${u.id}`
                          )?.totalTimeHours ?? 0),
                        0
                      ) ?? 0),
                  0
                ) ?? 0;

              // kpiDeptData.find(d => d.deptId === 1)?.times.find(t => t.time === KpiDeptCacheCategoryTimeEnum.CAT_TIME_PAST)?.categories

              const foundV2RangeValue =
                woDetailsV2Range && woDetailsV2TimePastFuture
                  ? kpiDeptData
                      .find((d) => `${d.deptId}` === `${woDetailsDeptId}`)
                      ?.times.find((t) => t.time === woDetailsV2TimePastFuture)
                      ?.categories.find(
                        (c) => c.category === woDetailsV2Category
                      )
                      ?.dates.find((d) => d.range === woDetailsV2Range)
                  : null;

              const foundDept = ctx?.extDepartments.find(
                (d) => woDetailsDeptId && `${d.id}` === `${woDetailsDeptId}`
              );
              const foundDeptUsers = ctx?.extUsers.filter(
                (u) =>
                  foundDept?.id && `${u.departmentId}` === `${foundDept?.id}`
              );

              const foundNotes = attendanceNotes.filter(
                (a) =>
                  a.date?.split("T")?.[0] === makeDateString(new Date()) &&
                  foundDeptUsers?.find((u) => `${u.id}` === `${a.extUserId}`) &&
                  a.attendanceNoteType?.id &&
                  a.attendanceNoteType?.doesNotReduceAttendance !== true
              );

              console.log("found notes", foundNotes);

              const totalTime =
                ctx?.extUsers
                  .filter(
                    (u) =>
                      `${u.departmentId}` === `${woDetailsDeptId}` &&
                      // filter not present
                      !foundNotes.find((n) => `${n.extUserId}` === `${u.id}`)
                  )
                  .reduce((acc, u) => {
                    const userWoValue = foundV2RangeValue // Separate v1 and v2
                      ? foundV2RangeValue?.capacityDividedByDaysNumAndPersonnel ??
                        0
                      : kpiRangeData
                          .find((r) => r.range === woDetailsRange)
                          ?.kpi.users.find(
                            (ux) => `${ux.user?.gormModel?.id}` === `${u.id}`
                          )?.workOrder?.averageDailyWorkingHours ?? 0;

                    console.log(
                      `User wo value`,
                      u?.id,
                      foundV2RangeValue
                        ? foundV2RangeValue
                        : kpiRangeData
                            .find((r) => r.range === woDetailsRange)
                            ?.kpi.users.find(
                              (ux) => `${ux.user?.gormModel?.id}` === `${u.id}`
                            )?.workOrder?.averageDailyWorkingHours ?? 0
                    );

                    return acc + userWoValue;
                  }, 0) ?? 0;

              const totalTimeDone =
                ctx?.extUsers
                  .filter(
                    (u) =>
                      `${u.departmentId}` === `${woDetailsDeptId}` &&
                      // filter not present
                      !foundNotes.find((n) => `${n.extUserId}` === `${u.id}`)
                  )
                  .reduce(
                    (acc, u) =>
                      acc +
                      // Separate v1 and v2
                      (foundV2RangeValue
                        ? foundV2RangeValue?.capacityDividedByDaysNumAndPersonnelDone ??
                          0
                        : kpiRangeData
                            .find((r) => r.range === woDetailsRange)
                            ?.kpi.users.find(
                              (ux) => `${ux.user?.gormModel?.id}` === `${u.id}`
                            )?.workOrder?.averageDailyWorkingHoursDone ?? 0),
                    0
                  ) ?? 0;

              const foundAlias = aliases.find(
                (r) => r.range === woDetailsRange
              );
              const daysCount =
                // Separate v1 and v2
                foundV2RangeValue
                  ? foundV2RangeValue.numDays
                  : foundAlias?.daysCount
                  ? foundAlias.daysCount
                  : Math.abs(woDetailsRange ?? 1);

              const averageSpan = foundV2RangeValue
                ? Math.abs(daysCount ?? 0)
                : 1 + Math.abs(daysCount ?? 0);

              const totalTimeActual = totalTime * averageSpan;
              const totalTimeDoneActual = totalTimeDone * averageSpan;

              const deptUsersFiltered =
                (ctx?.extUsers.filter(
                  (u) => `${u.departmentId}` === `${woDetailsDeptId}`
                )?.length ?? 0) - (foundNotes?.length ?? 0);

              const usersCountedInWorkingHour =
                foundV2RangeValue?.usersCountedInWorkingHour ?? 1;

              return (
                <>
                  <div>
                    Total time average:{" "}
                    <strong>{totalTime?.toFixed(1)} hrs</strong> (average span{" "}
                    {averageSpan} d, actual{" "}
                    <strong>{(totalTime * averageSpan)?.toFixed(1)} hrs</strong>{" "}
                    , done{" "}
                    <strong>
                      {(totalTimeDone * averageSpan)?.toFixed(1)} hrs
                    </strong>
                    ) from {/* {filteredTaskLists.length ?? 0}  */}
                    {kpiRangeData
                      .find((r) => r.range === woDetailsRange)
                      ?.kpi.deptTotalWosArray.find(
                        (d) => `${d.deptId}` === `${woDetailsDeptId}`
                      )?.totalWorkOrders ?? 0}
                    WOs
                  </div>

                  <div>
                    Total time average plan /{" "}
                    {
                      ctx?.extDepartments.find(
                        (d) => `${d.id}` === `${woDetailsDeptId}`
                      )?.name
                    }{" "}
                    personnel(s) = {totalTime?.toFixed(1)}/
                    {usersCountedInWorkingHour} ={" "}
                    <strong>
                      {(totalTime / usersCountedInWorkingHour)?.toFixed(1)}
                    </strong>
                  </div>

                  <div>
                    Total time average actual /{" "}
                    {
                      ctx?.extDepartments.find(
                        (d) => `${d.id}` === `${woDetailsDeptId}`
                      )?.name
                    }{" "}
                    personnel(s) = {totalTimeDone?.toFixed(1)}/
                    {usersCountedInWorkingHour} ={" "}
                    <strong>
                      {(totalTimeDone / usersCountedInWorkingHour)?.toFixed(1)}
                    </strong>
                  </div>

                  <div>
                    Total time plan /{" "}
                    {
                      ctx?.extDepartments.find(
                        (d) => `${d.id}` === `${woDetailsDeptId}`
                      )?.name
                    }{" "}
                    personnel(s) = {totalTimeActual?.toFixed(1)}/
                    {usersCountedInWorkingHour} ={" "}
                    <strong>
                      {(totalTimeActual / usersCountedInWorkingHour)?.toFixed(
                        1
                      )}
                    </strong>
                  </div>

                  <div>
                    Total time actual /{" "}
                    {
                      ctx?.extDepartments.find(
                        (d) => `${d.id}` === `${woDetailsDeptId}`
                      )?.name
                    }{" "}
                    personnel(s) = {totalTimeDoneActual?.toFixed(1)}/
                    {usersCountedInWorkingHour} ={" "}
                    <strong>
                      {(
                        totalTimeDoneActual / usersCountedInWorkingHour
                      )?.toFixed(1)}
                    </strong>
                  </div>

                  <div>
                    <ol>
                      {ctx?.extUsers
                        .filter(
                          (u) => `${u.departmentId}` === `${woDetailsDeptId}`
                        )
                        .map((u) => {
                          // const data = kpiRangeData
                          //   .find((r) => r.range === woDetailsRange)
                          //   ?.kpi.users.find(
                          //     (ux) => `${ux.user?.gormModel?.id}` === `${u.id}`
                          //   );

                          // Separate v1 and v2
                          const data = foundV2RangeValue
                            ? filteredTaskLists.reduce(
                                (acc, tL) =>
                                  acc +
                                  (tL.taskList?.meetingTasks
                                    .flatMap((mt) => mt.meetingTaskInCharges)
                                    .find((c) => `${c.extUserId}` === `${u.id}`)
                                    ?.totalTimeHours ?? 0),
                                0
                              )
                            : kpiRangeData
                                .find((r) => r.range === woDetailsRange)
                                ?.kpi.users.find(
                                  (ux) =>
                                    `${ux.user?.gormModel?.id}` === `${u.id}`
                                )?.workOrder?.averageDailyWorkingHours;

                          const dataDone = foundV2RangeValue
                            ? filteredTaskLists
                                .flatMap((tL) => tL.taskList?.meetingTasks)
                                .filter(
                                  (mt) =>
                                    mt?.status === "COMPLETED" &&
                                    mt.meetingTaskInCharges.find(
                                      (ic) => `${ic.extUserId}` === `${u.id}`
                                    )
                                )
                                .reduce(
                                  (acc, mt) =>
                                    acc +
                                    (mt?.meetingTaskInCharges.find(
                                      (c) => `${c.extUserId}` === `${u.id}`
                                    )?.totalTimeHoursTask ?? 0),
                                  0
                                )
                            : kpiRangeData
                                .find((r) => r.range === woDetailsRange)
                                ?.kpi.users.find(
                                  (ux) =>
                                    `${ux.user?.gormModel?.id}` === `${u.id}`
                                )?.workOrder?.averageDailyWorkingHoursDone;

                          const foundNote = attendanceNotes.find((a) => {
                            return (
                              a.date &&
                              makeDateString(new Date(a.date)) ===
                                makeDateString(new Date()) &&
                              `${a.extUserId}` === `${u.id}`
                            );
                          });

                          return (
                            <>
                              <li>
                                <div
                                  className="p-0"
                                  style={{
                                    backgroundColor: chroma
                                      .scale([
                                        "lightsalmon",
                                        "yellow",
                                        "lightgreen",
                                        "lightblue",
                                      ])((data ?? 0) / 8)
                                      .hex(),
                                  }}
                                >
                                  <strong>
                                    <span
                                      className={`${
                                        foundNote
                                          ? `text-warning bg-dark`
                                          : checkIns
                                              .find(
                                                (c) =>
                                                  `${c.date}` ===
                                                  makeDateString(new Date())
                                              )
                                              ?.checkIn.find(
                                                (c) =>
                                                  `${c.user?.id}` === `${u.id}`
                                              )?.firstLocation
                                          ? ``
                                          : `text-danger`
                                      }`}
                                    >
                                      {u.username}{" "}
                                      {foundNote
                                        ? foundNote.attendanceNoteType
                                          ? `(${foundNote.attendanceNoteType.name})`
                                          : `(No type selected)`
                                        : ""}
                                    </span>
                                    : {data?.toFixed(1)} hrs (done:{" "}
                                    {dataDone?.toFixed(1)} hrs)
                                  </strong>
                                </div>
                              </li>
                            </>
                          );
                        })}
                    </ol>
                  </div>

                  <div>
                    <div className="d-flex">
                      Expand all:{" "}
                      <input
                        type="checkbox"
                        onClick={() => {
                          if (woDetailsPopupDataExpands.length === 0) {
                            setWoDetailsPopupDataExpands(
                              filteredTaskLists.map(
                                (tL) =>
                                  tL.taskList?.masterJavaBaseModel?.id ?? ""
                              )
                            );
                            return;
                          }

                          setWoDetailsPopupDataExpands([]);
                        }}
                      />
                    </div>
                    {/* <div className="overflow-auto">
                      <pre>
                        <small>
                          {JSON.stringify(woDetailsPopupDataExpands, null, 2)}
                        </small>
                      </pre>
                    </div> */}
                  </div>
                  <ol>
                    {filteredTaskLists.map((tL) => {
                      return (
                        <>
                          <div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  woDetailsPopupDataExpands.find(
                                    (id) =>
                                      `${id}` ===
                                      `${tL.taskList?.masterJavaBaseModel?.id}`
                                  )
                                ) {
                                  setWoDetailsPopupDataExpands(
                                    woDetailsPopupDataExpands.filter(
                                      (id) =>
                                        `${id}` !==
                                        `${tL.taskList?.masterJavaBaseModel?.id}`
                                    )
                                  );

                                  return;
                                }

                                setWoDetailsPopupDataExpands([
                                  ...woDetailsPopupDataExpands,
                                  tL.taskList?.masterJavaBaseModel?.id ?? "",
                                ]);
                              }}
                            >
                              <small>
                                <strong>
                                  #{tL.taskList?.masterJavaBaseModel?.id}
                                  {/* :{" "}
                                  {getMeetingTasksTime(tL)} hrs */}
                                </strong>
                              </small>
                            </div>

                            {woDetailsPopupDataExpands.find(
                              (id) =>
                                `${id}` ===
                                `${tL.taskList?.masterJavaBaseModel?.id}`
                            ) ? (
                              <>
                                <TaskListInfo
                                  customers={ctx?.extCustomers}
                                  crmCustomers={ctx?.extCrmCustomers}
                                  tL={tL}
                                  extUsers={ctx?.extUsers ?? []}
                                  jobsProto={
                                    jobsProto ?? PpicJobs.fromPartial({})
                                  }
                                  machines={machines}
                                  processTypes={
                                    processTypes ??
                                    PpicSimplifiedProcessTypes.fromPartial({})
                                  }
                                  rcemDepartments={rcemDepartments}
                                  integrationModules={integrationModules}
                                  machinePrograms={machinePrograms}
                                  departmentTemplates={departmentTemplates}
                                  // date={
                                  //   selectedWeekRange?.from &&
                                  //   selectedWeekRange.to &&
                                  //   makeDateString(selectedWeekRange.from) ===
                                  //     makeDateString(selectedWeekRange.to)
                                  //     ? makeDateString(selectedWeekRange.from)
                                  //     : undefined
                                  // }
                                  // exUserId={selectedExtUser?.id ?? null}
                                  panelCodeDepartmentTemplateItems={
                                    panelCodeDepartmentTemplateItemsProto
                                  }
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </ol>
                </>
              );
            })()}
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      {/* <div>
        <pre>
          <small>
            {JSON.stringify(
              kpiRangeData.map((d) => ({
                range: d.range,
                loading: d.loading,
                data: d.kpi.users.length,
              })),
              null,
              2
            )}
          </small>
        </pre>
      </div> */}
      {/* <div>
        <pre>
          <small>{JSON.stringify(extDepartmentLastPlans)}</small>
        </pre>
      </div> */}
    </>
  );
};

export default ProductMonitoringPage;
